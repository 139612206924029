import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ProcessService } from 'src/app/services/process.service';
import { ExamService } from 'src/app/services/exam.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-result-exam',
  templateUrl: './result-exam.component.html',
  styleUrls: ['./result-exam.component.scss']
})
export class ResultExamComponent implements OnInit {
  public idResult: string;
  public isLogged: boolean;
  public resultCandidate;
  public dataExam;
  public numQuestion = 0;
  public totalQuestions: number;
  public correctAnswer: boolean;
  public dataResult;
  public posResult;
  public idProcess;
  public examId;
  public process;
  public msg;
  public msgExam;
  public noExists = false;
  public noExistsExam = false;
  public showSpinner = false;

  constructor(
    private rutaActiva: ActivatedRoute,
    private router: Router,
    private processService: ProcessService,
    private examService: ExamService,
    private location: Location
  ) {}

  async ngOnInit() {
    this.rutaActiva.params.subscribe((params: Params) => {
      this.idProcess = params.idProcess;
      this.posResult = params.posCandidate;
      this.examId = params.examId;
    });

    this.getProcess();
  }

  getProcess() {
    this.showSpinner = true;
    this.processService.getProcess(this.idProcess).subscribe((data) => {
      this.msg = data;

      if (this.msg.msg !== 'No exist') {
        this.process = data;
        this.resultCandidate = this.process.results[this.posResult];
        this.getExam();
      } else {
        this.noExists = true;
        this.showSpinner = false;
      }
    });
  }

  getExam() {
    this.showSpinner = true;
    // console.log(examId)
    this.examService
      .getExam(this.examId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Error', error.error.msg);
          // this.msgTopic = error.error.msg;
          if (error.error instanceof ErrorEvent) {
            return throwError(
              'client/network error ocurred, please try again later'
            ); // client-side or network error
          } else {
            return throwError(error.error); // backend error
          }
        })
      )
      .subscribe((data) => {
        this.msgExam = data;

        if (this.msgExam.msg !== 'No exist') {
          this.dataExam = data;
          this.totalQuestions = this.dataExam.questions.length;
          this.showSpinner = false;
        } else {
          this.noExistsExam = true;
          this.showSpinner = false;
        }
      });
  }

  isCorrectAnswer(numQuestion) {
    let answerCandidate;
    let answerExamen;

    if (this.resultCandidate) {
      if (this.resultCandidate.answers) {
        answerCandidate = this.resultCandidate.answers[numQuestion];
        answerExamen = this.dataExam.questions[numQuestion].correctAnswer;
      } else {
        this.resultCandidate.exam.forEach((element) => {
          if (element.examId == this.examId) {
            answerCandidate = element.answers[numQuestion];
            answerExamen = this.dataExam.questions[numQuestion].correctAnswer;
            return answerCandidate == answerExamen ? answerCandidate : -1;
          }
        });
      }

      return answerCandidate == answerExamen ? answerCandidate : -1;
    }
  }

  isWrongAnswer(numQuestion) {
    let answerCandidate;
    let answerExamen;

    if (this.resultCandidate) {
      if (this.resultCandidate.answers) {
        answerCandidate = this.resultCandidate.answers[numQuestion];
        answerExamen = this.dataExam.questions[numQuestion].correctAnswer;
      } else {
        this.resultCandidate.exam.forEach((element) => {
          if (element.examId == this.examId) {
            answerCandidate = element.answers[numQuestion];
            answerExamen = this.dataExam.questions[numQuestion].correctAnswer;
          }
        });
      }

      return answerCandidate != answerExamen ? answerCandidate : -1;
    }
  }

  getCandidateResult(numQuestion) {
    if (this.resultCandidate?.answers) {
      return this.resultCandidate.answers[numQuestion];
    } else {
      return this.resultCandidate.exam.filter(
        (element) => element.examId == this.examId
      )[0].answers[numQuestion];
    }
  }

  goProcess() {
    this.router.navigate(['/admin/dashboard/process/details/', this.idProcess]);
  }

  backClicked() {
    this.location.back();
  }
}
