import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { ExamService } from 'src/app/services/exam.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TopicService } from 'src/app/services/topic.service';
import { UserSessionService } from 'src/app/services/user-session.service';

@Component({
  selector: 'app-edit-exam',
  templateUrl: './edit-exam.component.html',
  styleUrls: ['./edit-exam.component.scss']
})
export class EditExamComponent implements OnInit {
  isLogged = false;
  idExam: string;
  formExam: FormGroup;
  dataExam;
  allTopics: Object;
  userEmail: string;
  newTopic = '';
  examCreate = false;
  previosUrl: string;

  // Variables del formulario
  titleExam = '';
  topicSelected = '';
  questionsForm: any[] = [];

  arrayOptions;
  optionFill;

  public msg;
  public noExists = false;

  public showSpinner = false;
  public editing = false;
  public loadingTopic = true;
  public user;
  public createdBy;
  public allExam;
  public allTitleExam: string[] = [];
  public wrongTitle = false;
  public wrongAnswer = false;
  public wrongTime = true;
  public existsCorrectAnswer = [];
  public existsCorrectTime = [];
  public createTopicResponse;
  public superuser = false;

  constructor(
    private fb: FormBuilder,
    private rutaActiva: ActivatedRoute,
    private examService: ExamService,
    public dialog: MatDialog,
    private router: Router,
    private location: Location,
    private snackBar: MatSnackBar,
    private topicService: TopicService,
    private userSession: UserSessionService
  ) {}

  async ngOnInit() {
    this.rutaActiva.params.subscribe((params: Params) => {
      this.idExam = params.idExam;
      this.getExam(this.idExam);
    });

    this.examService.getNewExams().subscribe((data) => {
      this.allExam = data;

      this.allExam.forEach((element) => {
        this.allTitleExam.push(element.titleExam.toLowerCase());
      });
    });

    try {
      await this.userSession.getUserLogged().then((userLogged) => {
        if (userLogged) {
          this.isLogged = true;
          this.userEmail = userLogged.email;

          this.userSession.getUser().then((resp) => {
            this.user = resp;
            this.user.superuser
              ? (this.superuser = true)
              : (this.superuser = false);
            this.createdBy = `${this.user.name} ${this.user.surName}`;
          });
        }
      });
    } catch (error) {
      console.error('Error!', error);
    }

    this.getAllTopic();
  }

  getExam(idExam) {
    this.showSpinner = true;
    this.examService.getExam(idExam).subscribe((data) => {
      this.showSpinner = false;
      this.msg = data;

      if (this.msg) {
        if (this.msg.msg !== 'No exist') {
          this.dataExam = data;
          this.titleExam = this.dataExam.titleExam;
          this.topicSelected = this.dataExam.topic;
          this.questionsForm = this.dataExam.questions;

          this.createFormExam();

          // Añadir preguntas
          this.dataExam.questions.forEach((val, index) => {
            const newQuestion = this.fb.group({
              question: ['', Validators.required],
              // time: ['', Validators.required],
              min: [
                '',
                Validators.compose([
                  Validators.required,
                  Validators.pattern('[0-9]{1,2}')
                ])
              ],
              seg: [
                '',
                Validators.compose([
                  Validators.required,
                  Validators.pattern('[0-9]{1,2}')
                ])
              ],
              correctAnswer: ['', Validators.required],
              options: this.fb.array([])
            });

            this.questions.push(newQuestion);
            this.existsCorrectAnswer[index] = true;
            this.existsCorrectTime[index] = true;

            // Añade a esta pregunta las opciones
            this.dataExam.questions[index].options.forEach((element) => {
              this.addOption(index);
            });
          });
        } else {
          this.noExists = true;
        }
      } else {
        this.noExists = true;
      }
    });
  }

  titleExists() {
    const titleExam = this.formExam.get('titleExam').value.trim();

    const pos = this.allTitleExam.indexOf(
      this.dataExam.titleExam.toLowerCase()
    );

    if (pos >= 0) {
      this.allTitleExam.splice(pos, 1);
    }
    //console.log(this.wrongTitle)

    if (this.allTitleExam.indexOf(titleExam.toLowerCase()) >= 0) {
      this.wrongTitle = true;
      return true;
    } else {
      this.wrongTitle = false;
      return false;
    }
  }

  createFormExam() {
    this.formExam = this.fb.group({
      titleExam: [
        '',
        Validators.compose([Validators.required, Validators.pattern('^[^.]+$')])
      ],
      topicSelected: ['', Validators.required],
      questions: this.fb.array([])
    });
  }

  get dotNotValid() {
    return this.formExam.get('titleExam');
  }

  get questions(): FormArray {
    // console.log('questions', this.formExam.get('questions'))
    return this.formExam.get('questions') as FormArray;
  }

  addQuestion() {
    const newQuestion = this.fb.group({
      question: ['', Validators.required],
      // time: ['', Validators.required],
      min: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{1,2}')
        ])
      ],
      seg: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{1,2}')
        ])
      ],
      correctAnswer: ['', Validators.required],
      options: this.fb.array([])
    });

    this.questions.push(newQuestion);

    this.addOption(this.questions.length - 1);
    this.addOption(this.questions.length - 1);
  }

  deleteQuestion(index) {
    this.questions.removeAt(index);
    this.questionsForm.splice(index, 1);
  }

  addOption(index) {
    const newOption = this.fb.group({
      texto: ['', Validators.required]
    });

    const options = this.questions.controls[index].get('options') as FormArray;
    options.push(newOption);
  }

  deleteOption(question, option) {
    if (this.questionsForm[question]) {
      this.questionsForm[question].options.splice(option, 1);
    }
    const options = this.questions.controls[question].get(
      'options'
    ) as FormArray;
    options.removeAt(option);

    //console.log(option, this.questions.controls[question].get('correctAnswer').value)
    if (
      option == this.questions.controls[question].get('correctAnswer').value
    ) {
      this.questions.controls[question].get('correctAnswer').setValue('-1');
      this.existsCorrectAnswer[question] = false;
    }
  }

  onlyNumber(event, indexQuestion) {
    const seconds = parseInt(
      this.questions.controls[indexQuestion].get('seg').value
    );
    const min = parseInt(
      this.questions.controls[indexQuestion].get('min').value
    );

    if (seconds == 0 && min == 0) {
      this.wrongTime = true;
      this.existsCorrectTime[indexQuestion] = false;
    } else {
      this.wrongTime = false;
      this.existsCorrectTime[indexQuestion] = true;
    }
  }

  checkChar(event) {
    if (
      event.keyCode == 46 ||
      event.keyCode == 44 ||
      event.keyCode == 101 ||
      event.keyCode == 69
    ) {
      return false;
    }
  }

  checkCharQA(event, tipo, indexQuestion, indexOption) {
    if (event.keyCode == 9 && tipo === 'question') {
      event.preventDefault();
      const data = this.questions.value[indexQuestion].question + '\t';
      this.questions.at(indexQuestion).get('question').setValue(data);
    } else if (event.keyCode == 9 && tipo === 'answer') {
      event.preventDefault();
      const options = this.questions.controls[indexQuestion].get(
        'options'
      ) as FormArray;
      const data = options.value[indexOption].texto + '\t';
      options.at(indexOption).get('texto').setValue(data);
    }

    if (tipo === 'question') {
      const questionArea = document.getElementById(`question${indexQuestion}`);
      setTimeout(function () {
        questionArea.style.cssText = 'height:auto; padding:0';
        // for box-sizing other than "content-box" use:
        // questionAreael.style.cssText = '-moz-box-sizing:content-box';
        questionArea.style.cssText =
          'height:' + questionArea.scrollHeight + 'px';
      }, 0);
    } else if (tipo === 'answer') {
      const answerArea = document.getElementById(
        `q${indexQuestion}a${indexOption}`
      );
      setTimeout(function () {
        answerArea.style.cssText = 'height:auto; padding:0';
        answerArea.style.cssText = 'height:' + answerArea.scrollHeight + 'px';
      }, 0);
    }
  }

  loadArea(event) {
    console.log(event);
  }

  getAllTopic() {
    this.loadingTopic = true;
    this.topicService.getAllTopic().subscribe((data) => {
      this.allTopics = data;
      this.loadingTopic = false;
    });
  }

  checkedAnswer(question) {
    this.existsCorrectAnswer[question] = true;
  }

  enableButton() {
    const answerSelect =
      this.existsCorrectAnswer.indexOf(false) >= 0 ? false : true;
    const correctTime =
      this.existsCorrectTime.indexOf(false) >= 0 ? false : true;
    return !this.wrongTitle && answerSelect && correctTime;
  }

  disableButton() {
    const answerSelect =
      this.existsCorrectAnswer.indexOf(false) >= 0 ? true : false;
    const correctTime =
      this.existsCorrectTime.indexOf(false) >= 0 ? true : false;
    return this.wrongTitle || answerSelect || correctTime;
  }

  // Abre modal para agregar un nuevo topic
  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: 'createTopic'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.newTopic = result.toUpperCase();
        // console.log(`Dialog result: ${this.newTopic}`);

        this.topicService
          .createTopic(this.newTopic, this.userEmail)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              // console.error('pipe error', error.error.msg);
              // this.msgTopic = error.error.msg;

              if (error.error instanceof ErrorEvent) {
                return throwError(
                  'client/network error ocurred, please try again later'
                ); // client-side or network error
              } else {
                return throwError(error.error); // backend error
              }
            })
          )
          .subscribe((resp) => {
            // console.log('subscribe', data);
            // this.msgTopic = 'Topic has been added successfully';
            this.createTopicResponse = resp;
            if (this.createTopicResponse.msg !== 'OK!') {
              this.snackBar.open('¡Lo Siento! Esta Skill ya existe', null, {
                duration: 3000,
                panelClass: 'snackbar'
              });
            } else {
              this.snackBar.open('¡Skill creado con éxito!', null, {
                duration: 3000,
                panelClass: 'snackbar'
              });
            }
            this.getAllTopic();
          });
      }
    });
  }

  valueOption(value) {
    // console.log(value)
    if (value === 'crearTopic') {
      this.openDialog();
      this.formExam.get('topicSelected').setValue('');
    }
  }

  createExam() {
    this.editing = true;
    const titleExam = this.formExam.get('titleExam').value;
    const topic = this.formExam.get('topicSelected').value;
    const questions = this.formExam.get('questions').value;

    // console.log(titleExam, topic, questions);

    this.examService
      .updateExam(this.idExam, titleExam, topic, questions, this.createdBy)
      .then((data) => {
        this.examCreate = true;
        this.editing = false;
      });
  }

  backClicked() {
    this.location.back();
  }

  toggleMenu() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }

  checkAnswerFill(indexQuestion, indexOption) {
    this.arrayOptions = this.questions.controls[indexQuestion].get(
      'options'
    ) as FormArray;
    this.optionFill = this.arrayOptions.controls[indexOption]
      .get('texto')
      .hasError('required');
    return this.optionFill;
  }
}
