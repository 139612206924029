<div class="c-home">
  <div class="c-home__content-background">
    <img class="c-home__logo" src="assets/img/UST-logo_dark-blue.svg" alt="" />
  </div>

  <div class="c-home__container-welcome">
    <h1 class="c-home__title">
      {{ "welcome" | translate }}
      <br>
      {{ "to_ust" | translate }}
    </h1>
  </div>
  <div class="c-home__content-soport">
    <candidate-language-selector></candidate-language-selector>
  </div>
  <div class="c-home__content-form">
    <div class="c-home__container">
      <div class="c-home__container-subtitle">
        <h3 class="c-home__subtitle">
          {{ "welcome_subtitle_admin" | translate }}
        </h3>
      </div>

      <form *ngIf="!creating" (ngSubmit)="registerAdmin()" class="c-home__form">
        <div class="block-login">
          <app-text-input [value]="nameInputValue" [params]="nameInputParams" (valueChange)="onNameValueChange($event)"
            class="custom-input"></app-text-input>
        </div>
        <div class="block-login">
          <app-text-input [value]="surnameInputValue" [params]="surnameInputParams"
            (valueChange)="onSurnameValueChange($event)" class="custom-input"></app-text-input>
        </div>
        <div class="block-login">
          <app-email-input [value]="emailInputValue" [params]="emailInputParams"
            (valueChange)="onEmailValueChange($event)" class="custom-input"></app-email-input>
        </div>
        <div class="block-login">
          <app-password-input [value]="passwordInputValue" [params]="passwordInputParams"
          (valueChange)="onPasswordValueChange($event)" class="password-input"></app-password-input>
        </div>
        <div class="block-login">
          <app-password-input [value]="password2InputValue" [params]="password2InputParams" [match]="true" [matchingPassword]="passwordInputValue"
          (valueChange)="onPassword2ValueChange($event)" class="password-input"></app-password-input>
        </div>
        <p *ngIf="msgRegister" class="c-home__error">{{ msgRegister | translate }}</p>

          <div class="submit-button-wrapper">
            <app-button [placeHolder]="'sign_in' | translate" [icon]="'../../../../assets/icons/right-arrow.svg'"
              class="c-home__submit-btn" (click)="registerAdmin()" [disabled]="!isFormValid()">
            </app-button>
          </div>
        <p class="c-home__info">
          {{ "Do_you_have_an_account?" | translate }}
          <a class="c-home__link" [routerLink]="['/admin/login']">{{ "login" | translate }}</a>
        </p>
      </form>

      <div *ngIf="creating" class="c-home__content-spinner">
        <mat-spinner [diameter]="30"></mat-spinner>
        <p class="c-home__p-spinner">
          {{ "loging_in" | translate }}
        </p>
      </div>
    </div>

  </div>
</div>
<app-footer class="c-home__footer"></app-footer>