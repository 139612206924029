import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ExamService } from 'src/app/services/exam.service';
import { ResultService } from 'src/app/services/result.service';
@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss'],
})
export class ThanksComponent implements OnInit {
  public idProcess;
  moreExams = false;
  numberString = [
    'Primer',
    'Segundo',
    'Tercero',
    'Cuarto',
    'Quinto',
    'Sexto',
    'Séptimo',
    'Octavo',
    'Noveno',
    'Décimo',
  ];
  numExam;
  existsExam;
  sending = false;

  constructor(
    private resultService: ResultService,
    private examService: ExamService,
    private router: Router,
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.idProcess = this.resultService.getProcessId();
    //console.log("currentNumber: ",this.examService.currentExamNumber);
    //console.log("totalNumber: ",this.examService.totalExamNumber);
    this.moreExams =
      this.examService.currentExamNumber < this.examService.totalExamNumber;
    this.numExam = this.numberString[this.examService.currentExamNumber - 1];
    this.translate.instant(this.numExam);
  }

  next() {
    //Pasamos al siguiente examen sin tener en cuenta a partir de aquí la versión anterior
    this.examService
      .getLastVersionExam(
        this.examService.allExamIds[this.examService.currentExamNumber]
      )
      .subscribe((resp2: any) => {
        const exam = resp2;
        //console.log("exam.msg: ",exam.msg);
        //console.log("exam: ",exam)
        if (exam.msg != 'Exam not found') {
          this.existsExam = true;
          this.sending = false;
          this.router.navigateByUrl(`candidate/exam/${exam[0].examid}`);
        } else {
          this.existsExam = false;
          this.sending = false;
        }
      });
  }
}
