import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TextInputParameters } from '../text-input/text-input.component';

let REQUIRED_MESSAGE = 'text_input.validation.required_field';
@Component({
  selector: 'app-text-area-input',
  templateUrl: './text-area-input.component.html',
  styleUrls: ['./text-area-input.component.scss'],
})
export class TextAreaInputComponent {
  @Input() params: TextInputParameters = new TextInputParameters();
  @Input() value: string = '';

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    if (this.params.isRequired) {
    }
  }

  onValueChange(val) {
    this.checkIsRequired();
    this.valueChange.emit(this.value);
  }

  private checkIsRequired() {
    if (this.params.isRequired) {
      if (!this.value?.length && !this.params?.errorMessage) {
        this.params.errorMessage = REQUIRED_MESSAGE;
      } else if (this.value?.length && this.params?.errorMessage === REQUIRED_MESSAGE) {
        this.params.errorMessage = null;
      }
    }
  }
}
