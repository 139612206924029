<div class="c-dialog">
  <div *ngIf="data === 'createTopic'">
    <div class="c-dialog__actions" mat-dialog-actions>
      <button
        class="c-dialog__button-close"
        mat-button
        mat-dialog-close
        (click)="manualClose()"
      >
        {{ "cross" | translate }}
      </button>
    </div>
    <h1 class="c-dialog__title" mat-dialog-title>
      {{ "create_new_skill" | translate }}
    </h1>
    <div mat-dialog-content>
      <input
        [(ngModel)]="topic"
        class="c-dialog__input-text"
        type="text"
        placeholder="Nombre del skill"
      />
    </div>
    <div class="c-dialog__actions" mat-dialog-actions>
      <app-button
        [placeHolder]="'create' | translate"
        [ngClass]="
          !topic ? 'c-dialog__button-disable' : 'c-dialog__button-able'
        "
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        [disabled]="!topic"
        class="c-home__submit-btn"
        (click)="manualClose(topic)"
      ></app-button>
    </div>
  </div>

  <div *ngIf="data === 'clonar'">
    <div class="c-dialog__actions" mat-dialog-actions>
      <button
        class="c-dialog__button-close"
        mat-button
        mat-dialog-close
        (click)="manualClose()"
      >
        {{ "cross" | translate }}
      </button>
    </div>
    <h1 class="c-dialog__title" mat-dialog-title>
      {{ "name_of_the_exam" | translate }}
    </h1>
    <div mat-dialog-content>
      <input
        matInput
        class="c-dialog__input-text"
        [(ngModel)]="titleExam"
        type="text"
      />
    </div>
    <div class="c-dialog__actions" mat-dialog-actions>
      <app-button
        [disabled]="!titleExam"
        [placeHolder]="'create_new_exam' | translate"
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        (click)="manualClose(titleExam)"
      ></app-button>
    </div>
  </div>

  <div *ngIf="data === 'bizneo'">
    <div class="c-dialog__actions" mat-dialog-actions>
      <button
        class="c-dialog__button-close"
        mat-button
        mat-dialog-close
        (click)="manualClose()"
      >
        {{ "cross" | translate }}
      </button>
    </div>
    <h1 class="c-dialog__title" mat-dialog-title>
      {{ "bizneo_link" | translate }}
    </h1>
    <p class="c-dialog__p">
      {{
        "with_this_code_you_can_access_the_bizneo_account_of_this_candidate"
          | translate
      }}
    </p>
    <div mat-dialog-content>
      <input
        class="c-dialog__input-text"
        matInput
        [(ngModel)]="linkBizneo"
        type="text"
        placeholder="www.link.com"
      />
    </div>
    <div class="c-dialog__actions" mat-dialog-actions>
      <app-button
        [disabled]="!linkBizneo"
        [placeHolder]="'save' | translate"
        [ngClass]="
          !linkBizneo ? 'c-dialog__button-disable' : 'c-dialog__button-able'
        "
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        class="c-home__submit-btn"
        (click)="manualClose(linkBizneo)"
      ></app-button>
    </div>
  </div>

  <div *ngIf="data === 'createCode'">
    <div class="c-dialog__actions" mat-dialog-actions>
      <button
        class="c-dialog__button-close"
        mat-button
        mat-dialog-close
        (click)="manualClose()"
      >
        {{ "cross" | translate }}
      </button>
    </div>
    <h1 class="c-dialog__title" mat-dialog-title>
      {{ "generate_more_codes" | translate }}
    </h1>
    <label class="c-dialog__label">{{
      "select_the_quantity_of_desired_code_to_generate" | translate
    }}</label>

    <div mat-dialog-content>
      <input
        class="c-dialog__input-number"
        matInput
        [(ngModel)]="numCode"
        type="number"
        min="1"
      />
    </div>
    <div class="c-dialog__actions" mat-dialog-actions>
      <app-button
        [placeHolder]="'generate' | translate"
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        (click)="manualClose(numCode)"
      ></app-button>
    </div>
  </div>

  <div *ngIf="data === 'closeProcess'">
    <h1 class="c-dialog__title" mat-dialog-title>
      {{ "closed_processes" | translate }}
    </h1>
    <label class="c-dialog__label"
      >{{ "are_you_sure_that_you_want_to_close_this_process" | translate }}
    </label>

    <div class="c-dialog__actions c-dialog__action-process" mat-dialog-actions>
      <app-button
        [placeHolder]="'cancel' | translate"
        (click)="manualClose()"
      ></app-button>
      <app-button
        [placeHolder]="'close_process' | translate"
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        (click)="manualClose('si')"
      ></app-button>
    </div>
  </div>

  <div *ngIf="data === 'deleteProcess'">
    <h1 class="c-dialog__title" mat-dialog-title>
      {{ "delete_process" | translate }}
    </h1>
    <label class="c-dialog__label"
      >{{ "are_you_sure_that_you_want_to_delete_this_process" | translate }}
    </label>

    <div class="c-dialog__actions c-dialog__action-process" mat-dialog-actions>
      <app-button
        [placeHolder]="'cancel' | translate"
        (click)="manualClose()"
      ></app-button>
      <app-button
        [placeHolder]="'delete_process' | translate"
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        (click)="manualClose('si')"
      ></app-button>
    </div>
  </div>
  <div *ngIf="data === 'deleteExam'">
    <h1 class="c-dialog__title" mat-dialog-title>
      {{ "delete_exam" | translate }}
    </h1>
    <label class="c-dialog__label"
      >{{ "are_you_sure_that_you_want_to_delete_this_exam" | translate }}
    </label>

    <div class="c-dialog__actions c-dialog__action-process" mat-dialog-actions>
      <app-button
        [placeHolder]="'cancel' | translate"
        (click)="manualClose()"
      ></app-button>
      <app-button
        [placeHolder]="'delete_exam' | translate"
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        (click)="manualClose('si')"
      ></app-button>
    </div>
  </div>
  <div *ngIf="data === 'deleteSkill'">
    <h1 class="c-dialog__title" mat-dialog-title>
      {{ "delete_skill" | translate }}
    </h1>
    <label class="c-dialog__label">
      {{ "are_you_sure_that_you_want_to_delete_this_skill" | translate }}
    </label>

    <div class="c-dialog__actions c-dialog__action-process" mat-dialog-actions>
      <app-button
        [placeHolder]="'cancel' | translate"
        (click)="manualClose()"
      ></app-button>
      <app-button
        [placeHolder]="'delete_skill' | translate"
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        (click)="manualClose('si')"
      ></app-button>
    </div>
  </div>
</div>
