import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterService } from 'src/app/services/router.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { TopicService } from 'src/app/services/topic.service';
import { ExamService } from 'src/app/services/exam.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { DataColumn } from 'src/app/shared/components/table/models/table.model';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss'],
})
export class TopicComponent implements OnInit {
  public allTopic;
  public allExam;
  public isLogged = false;
  public currentUser;
  public msgLogout: string;
  public totalColumns: string[] = ['Topic', 'Examenes', ' '];
  public dataProcess: [] = [];
  public dataSource = null;
  public actionMenu = '';
  public examInTopic: any[] = [];
  public openMenu: boolean[] = [];
  public infoTopic;
  public showSpinner = false;
  public createResponse;
  public superuser = false;
  public userEmail: string;
  public user;
  public orderSkill = 'static';
  public orderExam = 'static';
  public totalTopic: number;
  titleSkill: string = 'all_skills';
  columns: DataColumn[] = [
    {
      name: 'Skill',
      column: 'topic',
      isOrdenable: true,
    },
    { name: 'Exámenes asignados', column: 'numExam', isOrdenable: true },
  ];
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private routerService: RouterService,
    private snackBar: MatSnackBar,
    private topicService: TopicService,
    private examService: ExamService,
    private userSession: UserSessionService
  ) {
    this.routerService.getPreviousUrl();
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  async ngOnInit() {
    localStorage.clear();

    this.examService.getNewExams().subscribe((data) => {
      this.allExam = data;

      this.allExam.forEach((value) => {
        // mapeamos el array examInTopic, para obtener el topic, y con el indexof encontramos la posicion del topic
        if (!value.hide) {
          const pos = this.examInTopic
            .map((el) => el.topic)
            .indexOf(value.topic);

          if (pos < 0) {
            // No existe -> se agrega
            this.examInTopic.push({ topic: value.topic, total: 1 });
          } else {
            this.examInTopic[pos].total++;
          }
        }
      });
    });

    this.topicService.getTopics().subscribe((data) => {
      this.showSpinner = true;
      this.allTopic = data;
      this.totalTopic = this.allTopic.length;
      // console.log(this.totalTopic)

      this.allTopic.forEach((element) => {
        this.openMenu.push(false);
      });

      this.createTable();
    });

    try {
      await this.userSession.getUserLogged().then((userLogged) => {
        if (userLogged) {
          this.isLogged = true;
          this.userEmail = userLogged.email;

          this.userSession.getUser().then((resp) => {
            this.user = resp;
            this.user.superuser
              ? (this.superuser = true)
              : (this.superuser = false);
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  createTable() {
    this.allTopic.forEach((element, index) =>
      Object.defineProperty(element, 'numExam', {
        value: this.getNumExam(this.allTopic[index].topic),
        writable: true,
        enumerable: true,
        configurable: true,
      })
    );

    // console.log(this.allTopic)

    this.dataSource = new MatTableDataSource(this.allTopic);

    if (this.dataSource) {
      this.showSpinner = false;
    }
  }

  getNumExam(topic) {
    const pos = this.examInTopic.map((el) => el.topic).indexOf(topic);
    if (pos < 0) {
      return 0;
    } else {
      return this.examInTopic[pos].total;
    }
  }

  // Abre modal para agregar un nuevo topic
  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: 'createTopic',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.showSpinner = true;

        this.topicService
          .createTopic(result.toUpperCase(), this.userEmail)
          .subscribe((resp) => {
            this.createResponse = resp;
            if (this.createResponse.msg !== 'OK!') {
              this.snackBar.open('¡Lo Siento! Este Skill ya existe', null, {
                duration: 3000,
                panelClass: 'snackbar',
              });
              this.showSpinner = false;
            } else {
              this.snackBar.open('¡Skill creado con éxito!', null, {
                duration: 3000,
                panelClass: 'snackbar',
              });
            }
          });
      }
    });
  }

  // Menu navbar - responsive
  toggleMenu() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }
}
