import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { RouterService } from 'src/app/services/router.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { Location } from '@angular/common';
import { TopicService } from 'src/app/services/topic.service';
import { ExamService } from 'src/app/services/exam.service';
import { UserSessionService } from 'src/app/services/user-session.service';
@Component({
  selector: 'app-create-exam',
  templateUrl: './create-exam.component.html',
  styleUrls: ['./create-exam.component.scss'],
})
export class CreateExamComponent implements OnInit {
  formExam: FormGroup;
  isLogged = false;
  msgExam: string;
  userEmail: string;
  allTopics: Object;
  newTopic = '';
  msgTopic = '';
  myOptions;
  totalOptions = 0;
  examCreate = false;
  previosUrl: string;
  time: string;
  arrayOptions;
  optionFill;
  public showSpinner = false;
  public creating = false;
  public user;
  public createdBy;
  public allExam;
  public allTitleExam: string[] = [];
  public wrongTitle = false;
  public wrongAnswer = false;
  public wrongTime = true;
  public existsCorrectAnswer = [];
  public existsCorrectTime = [];
  public createTopicResponse;
  public superuser = false;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private routerService: RouterService,
    private snackBar: MatSnackBar,
    private location: Location,
    private topicService: TopicService,
    private examService: ExamService,
    private userSession: UserSessionService
  ) {
    // console.log(this.routerService.getPreviousUrl());
    this.previosUrl = this.routerService.getPreviousUrl();
  }

  async ngOnInit() {
    this.examService.getNewExams().subscribe((data) => {
      this.allExam = data;

      this.allExam.forEach((element) => {
        this.allTitleExam.push(element.titleExam.toLowerCase());
      });
    });

    this.createFormExam();

    try {
      await this.userSession.getUserLogged().then((userLogged) => {
        if (userLogged) {
          this.isLogged = true;
          this.userEmail = userLogged.email;

          this.userSession.getUser().then((resp) => {
            this.user = resp;
            this.user.superuser
              ? (this.superuser = true)
              : (this.superuser = false);
            this.createdBy = `${this.user.name} ${this.user.surName}`;
          });
        }
      });
    } catch (error) {
      console.error(error);
    }

    this.getAllTopic();
  }

  get dotNotValid() {
    return this.formExam.get('titleExam');
  }

  createFormExam() {
    this.formExam = this.fb.group({
      titleExam: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[^.]+$'),
        ]),
      ],
      topicSelected: ['', Validators.required],
      questions: this.fb.array([]),
    });

    // Para añadir la primera pregunta y dos opciones
    this.addQuestion();
  }

  titleExists() {
    const titleExam = this.formExam.get('titleExam').value.trim();

    if (this.allTitleExam.indexOf(titleExam.toLowerCase()) >= 0) {
      this.wrongTitle = true;
      return true;
    } else {
      this.wrongTitle = false;
      return false;
    }
  }

  get questions(): FormArray {
    // console.log('questions', this.formExam.get('questions'))
    return this.formExam.get('questions') as FormArray;
  }

  addQuestion() {
    const newQuestion = this.fb.group({
      question: ['', Validators.required],
      // time: ['', Validators.required],
      min: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{1,2}'),
        ]),
      ],
      seg: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{1,2}'),
        ]),
      ],
      correctAnswer: ['', Validators.required],
      options: this.fb.array([]),
    });

    this.questions.push(newQuestion);

    this.addOption(this.questions.length - 1);
    this.addOption(this.questions.length - 1);
  }

  deleteQuestion(index) {
    this.questions.removeAt(index);
  }

  addOption(index) {
    const newOption = this.fb.group({
      texto: ['', Validators.required],
    });

    const options = this.questions.controls[index].get('options') as FormArray;
    options.push(newOption);
    this.totalOptions++;
  }

  deleteOption(question, option) {
    const options = this.questions.controls[question].get(
      'options'
    ) as FormArray;
    options.removeAt(option);

    if (
      option == this.questions.controls[question].get('correctAnswer').value
    ) {
      this.questions.controls[question].get('correctAnswer').setValue('-1');
      this.existsCorrectAnswer[question] = false;
    }
  }

  onlyNumber(event, indexQuestion) {
    const seconds = parseInt(
      this.questions.controls[indexQuestion].get('seg').value
    );
    const min = parseInt(
      this.questions.controls[indexQuestion].get('min').value
    );

    if (seconds == 0 && min == 0) {
      this.wrongTime = true;
      this.existsCorrectTime[indexQuestion] = false;
    } else {
      this.wrongTime = false;
      this.existsCorrectTime[indexQuestion] = true;
    }
  }

  checkChar(event) {
    if (
      event.keyCode == 46 ||
      event.keyCode == 44 ||
      event.keyCode == 101 ||
      event.keyCode == 69
    ) {
      return false;
    }
  }

  checkedAnswer(question) {
    this.existsCorrectAnswer[question] = true;
  }

  checkCharQA(event, tipo, indexQuestion, indexOption) {
    if (event.keyCode == 9 && tipo === 'question') {
      event.preventDefault();
      const data = this.questions.value[indexQuestion].question + '\t';
      this.questions.at(indexQuestion).get('question').setValue(data);
      // console.log(this.questions.value[indexQuestion].question)
    } else if (event.keyCode == 9 && tipo === 'answer') {
      event.preventDefault();
      const options = this.questions.controls[indexQuestion].get(
        'options'
      ) as FormArray;
      const data = options.value[indexOption].texto + '\t';
      options.at(indexOption).get('texto').setValue(data);
    }

    if (tipo === 'question') {
      const questionArea = document.getElementById(`question${indexQuestion}`);
      setTimeout(function () {
        questionArea.style.cssText = 'height:auto; padding:0';
        // for box-sizing other than "content-box" use:
        // questionAreael.style.cssText = '-moz-box-sizing:content-box';
        questionArea.style.cssText =
          'height:' + questionArea.scrollHeight + 'px';
      }, 0);
    } else if (tipo === 'answer') {
      const answerArea = document.getElementById(
        `q${indexQuestion}a${indexOption}`
      );
      setTimeout(function () {
        answerArea.style.cssText = 'height:auto; padding:0';
        answerArea.style.cssText = 'height:' + answerArea.scrollHeight + 'px';
      }, 0);
    }
  }

  enableButton() {
    const answerSelect =
      this.existsCorrectAnswer.indexOf(false) >= 0 ? false : true;
    const correctTime =
      this.existsCorrectTime.indexOf(false) >= 0 ? false : true;
    return !this.wrongTitle && answerSelect && correctTime;
  }

  disableButton() {
    const answerSelect =
      this.existsCorrectAnswer.indexOf(false) >= 0 ? true : false;
    const correctTime =
      this.existsCorrectTime.indexOf(false) >= 0 ? true : false;
    return this.wrongTitle || answerSelect || correctTime;
  }

  getAllTopic() {
    this.showSpinner = true;
    this.topicService.getAllTopic().subscribe((data) => {
      this.showSpinner = false;
      this.allTopics = data;
    });
  }

  createExam() {
    this.creating = true;
    const titleExam = this.formExam.get('titleExam').value.trim();
    const topic = this.formExam.get('topicSelected').value;
    const questions = this.formExam.get('questions').value;

    this.examService
      .createExam(titleExam, topic, questions, this.userEmail, this.createdBy)
      .then(() => {
        // console.log('subscribe', data);
        // this.msgExam = 'Exam has been created successfully';
        this.creating = false;
        this.createFormExam();
        this.examCreate = true;
        if (this.previosUrl === '/admin/dashboard/process/create') {
          setTimeout(() => {
            this.router.navigate(['/admin/dashboard/process/create']);
          }, 3000);
        }
      });
  }

  // Abre modal para agregar un nuevo topic
  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: 'createTopic',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.newTopic = result.toUpperCase();
        this.showSpinner = true;

        this.topicService
          .createTopic(this.newTopic, this.userEmail)
          .subscribe((resp) => {
            // console.log('subscribe', data);
            this.createTopicResponse = resp;
            if (this.createTopicResponse.msg !== 'OK!') {
              this.snackBar.open('¡Lo Siento! Esta Skill ya existe', null, {
                duration: 3000,
                panelClass: 'snackbar',
              });
            } else {
              this.snackBar.open('¡Skill creado con éxito!', null, {
                duration: 3000,
                panelClass: 'snackbar',
              });
            }
            this.getAllTopic();
            this.showSpinner = false;
          });
      }
    });
  }

  valueOption(value) {
    if (value === 'crearTopic') {
      this.openDialog();
      this.formExam.get('topicSelected').setValue('');
    }
  }

  backClicked() {
    this.location.back();
  }

  toggleMenu() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }

  checkAnswerFill(indexQuestion, indexOption) {
    this.arrayOptions = this.questions.controls[indexQuestion].get(
      'options'
    ) as FormArray;
    this.optionFill = this.arrayOptions.controls[indexOption]
      .get('texto')
      .hasError('required');
    return this.optionFill;
  }
}
