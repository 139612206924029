<div class="c-nav">
  <div class="topnav" id="myTopnav">
    <a class="content-logo active" [routerLink]="['/admin/dashboard/process']"
      ><img class="logo" src="assets/img/UST-logo_dark-blue.svg" alt="UST"
    /></a>
    <div class="nav-items" id="navItems">
      <a
        class="link"
        [routerLink]="['/admin/dashboard/process']"
        [routerLinkActive]="['active']"
      >
        {{ "processes" | translate }}
      </a>
      <a
        class="link"
        [routerLink]="['/admin/dashboard/exam']"
        [routerLinkActive]="['active']"
      >
        {{ "exams" | translate }}
      </a>
      <a
        class="link"
        [routerLink]="['/admin/dashboard/topic']"
        [routerLinkActive]="['active']"
      >
        {{ "skills" | translate }}
      </a>
      <a
        class="link"
        *ngIf="superUser"
        [routerLink]="['/admin/dashboard/stats']"
        [routerLinkActive]="['active']"
      >
        {{ "stats" | translate }}
      </a>
      <candidate-language-selector
        class="link lang-selector"
        [style]="{ margin: 0 }"
      ></candidate-language-selector>
      <a class="link logout" *ngIf="isLogged" (click)="logout()">
        {{ "close_session" | translate }}
      </a>
    </div>
    <a class="icon" (click)="toggleMenu()"
      ><img class="nav-icon" src="/assets/img/menu-dark.svg" alt="Menu"
    /></a>
  </div>
</div>
