import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserSessionService } from 'src/app/services/user-session.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() superUser: boolean = false;
  @Input() isLogged: boolean = false;
  public msgLogout: string;

  constructor(public router: Router, public authService: AuthService, private userSession: UserSessionService) {}

  ngOnInit(): void {}

  routing(page) {
    this.router.navigate(['/admin/dashboard/' + page]);
  }

  async logout() {
    try {
      await this.authService.logout().then((result) => {
        if (result) {
          this.msgLogout = 'An error has occurred, please try again';
        } else {
          this.userSession.clearUserData();
          this.router.navigate(['/admin/login']);
        }
      });
    } catch (error) {
      console.error('error en login', error);
    }
  }

  toggleMenu() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }
}
