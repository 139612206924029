<div class="generic-button-container">
  <button
    type="button"
    matSuffix
    [disabled]="disabled"
    (click)="onClick()"
    class="generic-button"
  >
    <span class="generic-button-text">{{ placeHolder | translate }}</span>
    <span *ngIf="icon" class="generic-button-icon">
      <img class="icon" src="{{ icon }}" />
    </span>
  </button>
</div>
