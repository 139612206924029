// Angular modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// Components
import { UploadCVComponent } from './components/upload-cv/upload-cv.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FooterComponent } from './components/footer/footer.component';
import { TextInputComponent } from './components/inputs/text-input/text-input.component';
import { TextAreaInputComponent } from './components/inputs/text-area-input/text-area-input.component';
import { PasswordInputComponent } from './components/inputs/password-input/password-input.component';
import { EmailInputComponent } from './components/inputs/email-input/email-input.component';
import { ButtonComponent } from './components/button/button.component';
import { MenuComponent } from './components/menu/menu.component';
import { SearchInputComponent } from './components/inputs/search-input/search-input.component';
// Directives
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { HttpClient } from '@angular/common/http';
import { TimerBarComponent } from './components/timer-bar/timer-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { TableComponent } from './components/table/table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const _modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MatProgressBarModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatPaginatorModule,
  MatMenuModule,
  MatDividerModule,
];

const _declarations = [
  UploadCVComponent,
  DialogComponent,
  NotFoundComponent,
  DragAndDropDirective,
  FooterComponent,
  ButtonComponent,
  TextInputComponent,
  TextAreaInputComponent,
  PasswordInputComponent,
  EmailInputComponent,
  LanguageSelectorComponent,
  TimerBarComponent,
  MenuComponent,
  SearchInputComponent,
  TableComponent,
];

@NgModule({
  declarations: _declarations,
  imports: [
    ..._modules,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
  ],
  exports: [..._declarations, ..._modules],
})
export class SharedModule {}
