<div class="input-wrapper">
  <div class="label-wrapper">
    <div class="input-label" [class.label-lite]="value?.length">
      {{ params.textLabel | translate
      }}{{ !value?.length && params.isRequired ? "*" : "" }}
    </div>
  </div>
  <div class="input-row" [class.error]="params.errorMessage">
    <input
      class="text-input"
      type="text"
      [(ngModel)]="value"
      (ngModelChange)="onValueChange($event)"
      (focusout)="onValueChange($event)"
    />
    <div class="error-message">
      {{ params.errorMessage | translate }}
    </div>
  </div>
</div>
