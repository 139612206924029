import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './dashboard/login/login.component';
import { RegisterComponent } from './dashboard/register/register.component';
import { TopicComponent } from './dashboard/topic/topic.component';
import { ExamComponent } from './dashboard/exam/exam.component';
import { ExamCandidateComponent } from './main/exam-candidate/exam-candidate.component';
import { CandidateHomeComponent } from './main/candidate-home/candidate-home.component';
import { ThanksComponent } from './main/thanks/thanks.component';
import { ProcessComponent } from './dashboard/process/process.component';
import { CreateProcessComponent } from './dashboard/process/create-process/create-process.component';
import { DetailsProcessComponent } from './dashboard/process/details-process/details-process.component';
import { CreateExamComponent } from './dashboard/exam/create-exam/create-exam.component';
import { DetailsExamComponent } from './dashboard/exam/details-exam/details-exam.component';
import { EditExamComponent } from './dashboard/exam/edit-exam/edit-exam.component';
import { ResultExamComponent } from './dashboard/process/result-exam/result-exam.component';
import { SoportComponent } from './main/soport/soport.component';
import { StatsComponent } from './dashboard/stats/stats.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClient, HttpClientModule } from '@angular/common/http';

import { environment } from 'src/environments/environment';

// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

// Services
import { AuthService } from './services/auth.service';
import { ExamService } from './services/exam.service';
import { RouterService } from './services/router.service';

// Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChartsModule } from 'ng2-charts';
import { AlertComponent } from './shared/components/alert/alert.component';
import { SharedModule } from './shared/shared.module';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AlertComponent,
    AppComponent,
    LoginComponent,
    RegisterComponent,
    TopicComponent,
    ExamComponent,
    ExamCandidateComponent,
    CandidateHomeComponent,
    ThanksComponent,
    ProcessComponent,
    CreateProcessComponent,
    DetailsProcessComponent,
    CreateExamComponent,
    DetailsExamComponent,
    EditExamComponent,
    ResultExamComponent,
    SoportComponent,
    StatsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    ChartsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
  ],
  providers: [AuthService, ExamService, RouterService],
  bootstrap: [AppComponent],
})
export class AppModule {}
