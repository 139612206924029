<div class="alert-container">
  <div *ngFor="let alert of alerts" [@inOutAnimation]>
    <div
      [ngClass]="alert.type"
      class="alert alert-dismissible fade show"
      role="alert"
    >
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
      <div class="alert-icon row col-12">
        <i class="far fa-fw fa-bell fa-2x"></i>
        <div class="col-6 alert-message">
          {{ alert.message }}
        </div>
      </div>
    </div>
  </div>
</div>
