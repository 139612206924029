<div class="c-soport">
  <div class="c-soport__back">
    <img
      class="c-soport__arrow-back"
      src="../../../assets/icons/left-arrow.svg"
      alt="Volver"
    />
    <a
      class="c-soport__link"
      [routerLink]="['/candidate/home']"
      routerLinkActive="router-link-active"
      >{{ "back" | translate }}</a
    >
  </div>

  <div class="c-soport__content-form">
    <div class="c-soport__form-header">
      <div class="c-soport__content-logo">
        <img
          class="c-soport__logo"
          src="assets/img/UST-logo_dark-blue.svg"
          alt="UST"
        />
      </div>

      <h1 class="c-soport__title">
        {{ "support_title" | translate }}
      </h1>
    </div>

    <form *ngIf="!sending && !sent" class="c-soport__form">
      <app-text-input
        [value]="nameInputValue"
        [params]="nameInputParams"
        (valueChange)="onNameValueChange($event)"
        class="c-soport__input"
      ></app-text-input>

      <app-text-input
        [value]="codeInputValue"
        [params]="codeInputParams"
        (valueChange)="onCodeValueChange($event)"
        class="c-soport__input"
      ></app-text-input>

      <app-text-input
        [value]="emailInputValue"
        [params]="emailInputParams"
        (valueChange)="onEmailValueChange($event)"
        class="c-soport__input"
      ></app-text-input>

      <app-text-area-input
        [value]="messageInputValue"
        [params]="messageInputParams"
        (valueChange)="onMessageValueChange($event)"
        class="c-soport__input"
      ></app-text-area-input>

      <div class="button-row">
        <app-button
          [placeHolder]="'send'"
          [icon]="'../../../../assets/icons/right-arrow.svg'"
          (click)="sendData()"
          [disabled]="
            !(nameIsValid && codeIsValid && emailIsValid && messageIsValid)
          "
          class="c-soport__submit-btn"
        ></app-button>
      </div>
    </form>

    <div *ngIf="sending" class="c-soport__content-spinner">
      <mat-spinner [diameter]="30"></mat-spinner>
      <p class="c-soport__p-spinner">
        {{ "sending_messagge" | translate }}
      </p>
    </div>

    <app-footer class="c-soport__footer"></app-footer>
  </div>
</div>
