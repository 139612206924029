import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  @Input() icon?: string = '';
  @Input() placeholder?: string = '';
  @Input() style;
  @Input() searcherId;

  @Output() clickSearch = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    this.clickSearch.emit();
  }
}
