import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValueChange } from '../value-change';
import { TextInputParameters } from '../text-input/text-input.component';

let REQUIRED_MESSAGE = 'text_input.validation.required_field';
let FORMAT_MESSAGE = 'text_input.validation.invalid_email';
@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss'],
})
export class EmailInputComponent {
  @Input() validationPattern: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  @Input() params: TextInputParameters = new TextInputParameters();
  @Input() value: string = '';

  @Output() valueChange: EventEmitter<ValueChange> =
    new EventEmitter<ValueChange>();

  isClicked: boolean;

  ngOnInit(): void {}
  clickInput() {
    this.isClicked = !this.isClicked;
  }
  onValueChange() {
    this.checkPattern();
    this.checkIsRequired();
    this.valueChange.emit({
      value: this.value,
      invalid: this.params.errorMessage?.length > 0,
    });
  }

  checkPattern() {
    const isValid: boolean = this.validationPattern.test(this.value);
    if (isValid) {
      this.params.errorMessage = this.params?.errorMessage
        .replace(FORMAT_MESSAGE, '')
        .replace('|', '')
        .trim();
      return;
    }
    if (!isValid && this.params?.errorMessage.indexOf(FORMAT_MESSAGE) < 0) {
      this.params.errorMessage = FORMAT_MESSAGE;
    }
  }

  private checkIsRequired() {
    if (this.params?.isRequired) {
      if (
        !this.value?.length &&
        this.params?.errorMessage.indexOf(REQUIRED_MESSAGE) < 0
      ) {
        this.params.errorMessage = REQUIRED_MESSAGE;
      } else if (
        this.value?.length &&
        this.params?.errorMessage.indexOf(REQUIRED_MESSAGE) >= 0
      ) {
        this.params.errorMessage = '';
      }
    }
  }
}
