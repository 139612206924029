import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() placeHolder!: string;
  @Input() icon!: String[];
  @Input() disabled = false;

  @Output() clicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    if (!this.disabled) {
      this.clicked.emit();
    }
  }
}
