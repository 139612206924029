import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatsService {
  constructor(private afs: AngularFirestore) {}

  /* createStats(candidates, skills, totalProcess,openProcess,closedProcess){ ///HACER
    this.afs.collection('stats').add({candidates, skills, totalProcess,openProcess,closedProcess});
  }
*/
  getStats() {
    this.afs
      .collection('stats')
      .doc('stats')
      .get()
      .pipe(map((resp) => resp.data()));
  }

  getStatsChanges() {
    return this.afs.collection('stats').doc('stats').valueChanges();
  }
}
