<div class="input-wrapper">
  <div class="label-wrapper">
    <div
      class="input-label"
      [class.label-lite]="value?.length || isClicked === true"
    >
      {{ params.textLabel | translate
      }}{{ !value?.length && params.isRequired ? "*" : "" }}
    </div>
  </div>
  <div class="input-row" [class.error]="params.errorMessage">
    <input
      class="text-input"
      type="text"
      [(ngModel)]="value"
      (change)="onValueChange()"
      (focusout)="onValueChange()"
      (click)="clickInput()"
      (blur)="clickInput()"
    />
    <div class="error-message">
      {{ params.errorMessage | translate }}
    </div>
  </div>
</div>
