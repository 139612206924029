import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessService } from 'src/app/services/process.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ChartType, Chart } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { StatsService } from 'src/app/services/stats.service';
import { UserSessionService } from 'src/app/services/user-session.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  public allProcess;
  public isLogged = false;
  public totalColumns: string[] = ['Nombre', 'Fecha de inicio'];
  public msgLogout: string;
  public dataSource = null;
  public results: any[] = [];
  public showSpinnerSkills = true;
  public showSpinnerCandidate = true;
  public showSpinnerProcess = true;
  public showSpinnerExam = true;
  public dataStats;
  public candidates;
  public numTopic;
  public numProcess;
  public numExam;
  public openProcess;
  public closeProcess;

  // Doughnut
  public doughnutChartLabels: Label[] = ['Abiertos', 'Cerrados'];
  public doughnutChartData: MultiDataSet = [[0, 0]];
  public doughnutChartType: ChartType = 'doughnut';
  public donutColors = [
    {
      backgroundColor: ['rgba(79, 175, 128, 1)', 'rgba(235, 115, 96, 1)']
    }
  ];

  constructor(
    private router: Router,
    private processService: ProcessService,
    private statsService: StatsService,
    private userSession: UserSessionService
  ) {}

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  async ngOnInit() {
    Chart.defaults.global.legend.display = false;

    try {
      await this.userSession.getUserLogged().then((userLogged) => {
        if (userLogged) {
          this.isLogged = true;

          this.processService.getProcessChanges().subscribe((data) => {
            this.allProcess = data;
            this.numProcess = this.allProcess.length;
            this.createTable();
          });

          this.statsService.getStatsChanges().subscribe((data) => {
            this.dataStats = data;
            this.candidates = this.dataStats.candidateCount;
            this.numTopic = this.dataStats.topicCount;
            this.numProcess = this.dataStats.processCount;
            this.numExam = this.dataStats.examCount;
            this.openProcess = this.dataStats.openProcessCount;
            this.closeProcess = this.dataStats.closedProcessCount;

            this.doughnutChartData = [[this.openProcess, this.closeProcess]];

            this.showSpinnerCandidate = false;
            this.showSpinnerProcess = false;
            this.showSpinnerSkills = false;
            this.showSpinnerExam = false;
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  createTable() {
    let process = this.allProcess;
    // Ordenamos de forma descendente por fecha de inicio
    process.sort(function (a, b) {
      if (a.creationDate < b.creationDate) {
        return 1;
      }
      if (a.creationDate > b.creationDate) {
        return -1;
      }
      return 0;
    });

    process = process.slice(0, 4);
    this.dataSource = new MatTableDataSource(process);
    this.showSpinnerProcess = false;
  }

  toggleMenu() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }
}
