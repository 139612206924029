<div>
  <mat-select
    [ngStyle]="style"
    [(value)]="value"
    (selectionChange)="change($event)"
    class="select"
  >
    <mat-select-trigger>
      <img src="{{ value?.icon }}" />
      <span>{{ value?.short }}</span>
    </mat-select-trigger>
    <mat-option *ngFor="let lang of languages" [value]="lang">
      <label>
        <img src="{{ lang.icon }}" />

        {{ lang.name }}
      </label>
    </mat-option>
  </mat-select>
</div>
