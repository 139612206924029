<div class="c-result">
  <div *ngIf="dataExam" class="c-result__content-logo">
    <img class="c-result__logo" src="assets/img/UST-logo_white.svg" alt="" />
  </div>

  <div *ngIf="showSpinner" class="c-result__content-spinner">
    <mat-spinner [diameter]="30"></mat-spinner>
    <p class="c-result__p-spinner">
      {{ "loading_results" | translate }}
    </p>
  </div>

  <div class="c-result__content" *ngIf="dataExam && resultCandidate">
    <div class="c-result__back">
      <img
        class="c-result__arrow-back"
        src="/assets/img/arrow.svg"
        alt="volver"
      />
      <a class="c-result__link-back" (click)="backClicked()">
        {{ "back" | translate }}
      </a>
    </div>

    <div class="c-result__all">
      <div class="c-result__question">
        <div class="c-result__content-title">
          <p class="c-result__num-question">
            {{ numQuestion + 1 }}/{{ totalQuestions }}
          </p>
          <textarea rows="3" readonly class="c-result__text-area">{{
            dataExam.questions[numQuestion].question
          }}</textarea>
        </div>
      </div>

      <div
        class="c-result__content-answer"
        *ngFor="
          let answer of dataExam.questions[numQuestion].options;
          let index = index
        "
      >
        <label
          [ngClass]="
            dataExam.questions[numQuestion].correctAnswer == index
              ? 'c-result__answer c-result__answer--right'
              : isCorrectAnswer(numQuestion) == index
              ? 'c-result__answer c-result__answer--right'
              : isWrongAnswer(numQuestion) == index
              ? 'c-result__answer c-result__answer--wrong'
              : 'c-result__answer'
          "
          >{{ answer.texto }}</label
        >
      </div>

      <p
        *ngIf="getCandidateResult(numQuestion) == 100"
        style="color: brown; font-weight: bold; text-align: center"
      >
        {{ "no_answer" | translate }}
      </p>

      <button
        *ngIf="numQuestion + 1 !== totalQuestions"
        class="c-result__button"
        (click)="numQuestion = numQuestion + 1"
      >
        {{ "next" | translate }}
      </button>

      <button
        *ngIf="numQuestion + 1 == totalQuestions"
        class="c-result__button"
        (click)="goProcess()"
      >
        {{ "finish" | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="noExists" class="c-result__content-result">
    <p class="c-result__result-msg">
      {{ "sorry_process_dont_exist" | translate }}
    </p>
    <a
      class="c-result__button c-result__result-button"
      [routerLink]="['/admin/dashboard/process']"
    >
      {{ "see_processes" | translate }}
    </a>
  </div>

  <div *ngIf="noExistsExam" class="c-result__content-result">
    <p class="c-result__result-msg">
      {{ "sorry_exam_dont_exist" | translate }}
    </p>
    <a
      class="c-result__button c-result__result-button"
      [routerLink]="['/admin/dashboard/process/details/', idProcess]"
    >
      {{ "return" | translate }}
    </a>
  </div>

  <app-footer [isDarkFooter]="false"></app-footer>
</div>
