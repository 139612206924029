<section class="page_404">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="col-sm-12 col-sm-offset-1 text-center">
          <div class="four_zero_four_bg">
            <h1 class="text-center">{{ "404" | translate }}</h1>
          </div>

          <div class="contant_box_404">
            <h3 class="h2">{{ "looks_like_you_are_lost" | translate }}</h3>
            <p>
              {{ "the_page_you_are_looking_for_is_not_correct" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
