<div class="c-process">
  <app-menu [superUser]="superuser" [isLogged]="isLogged"></app-menu>

  <div class="c-process__container" *ngIf="!processCreate && !creating">
    <div class="c-process__back">
      <img
        class="c-process__arrow-back"
        src="/assets/img/arrow.svg"
        alt="volver"
      />
      <a class="c-process__link" (click)="backClicked()">
        {{ "back" | translate }}
      </a>
    </div>

    <form [formGroup]="formProcess" class="c-process__form">
      <input
        formControlName="name"
        [(ngModel)]="nameProcess"
        class="c-process__input"
        type="text"
        placeholder="{{ 'name_of_the_process' | translate }}"
      />
      <div
        *ngIf="
          formProcess.get('name').hasError('required') &&
          (formProcess.get('name').touched ||
            formProcess.get('description').dirty ||
            formProcess.get('examSelected').dirty ||
            formProcess.get('startDate').dirty ||
            formProcess.get('totalCode').dirty)
        "
        class="c-process__error"
      >
        {{ "complete_the_name_of_the_process" | translate }}
      </div>

      <textarea
        formControlName="description"
        [(ngModel)]="descriptionProcess"
        class="c-process__text-area"
        cols="30"
        rows="10"
        placeholder="{{ 'description_of_the_process' | translate }}"
      ></textarea>
      <div
        *ngIf="
          formProcess.get('description').hasError('required') &&
          (formProcess.get('name').dirty ||
            formProcess.get('description').touched ||
            formProcess.get('examSelected').dirty ||
            formProcess.get('startDate').dirty ||
            formProcess.get('totalCode').dirty)
        "
        class="c-process__error"
      >
        {{ "complete_the_description_of_the_process" | translate }}
      </div>

      <!-- Array de examenes -->
      <div formArrayName="examSelected">
        <div
          class="c-process__content-exam"
          [formGroupName]="index"
          *ngFor="let item of arrExam.controls; let index = index"
        >
          <div class="c-process__content-select">
            <select
              formControlName="idExam"
              (change)="valueOption($event.target.value)"
              class="c-process__select"
            >
              <option class="c-process__options" value="" disabled>
                {{ "asign_exam" | translate }}
              </option>
              <option
                class="c-process__options"
                [value]="item.id"
                *ngFor="let item of allExams"
              >
                {{ item.titleExam }}
              </option>
              <option value="crearExam">
                {{ "create_a_new_exam" | translate }}
              </option>
            </select>

            <div *ngIf="showSpinner" class="c-process__content-spinner">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>

            <button
              *ngIf="index > 0"
              (click)="deleteExam(index)"
              class="c-process__delete-exam"
            >
              X
            </button>
          </div>
          <div
            *ngIf="
              arrExam.controls[index].get('idExam').hasError('required') &&
              (formProcess.get('name').dirty ||
                formProcess.get('description').dirty ||
                formProcess.get('examSelected').touched ||
                formProcess.get('startDate').dirty ||
                formProcess.get('totalCode').dirty)
            "
            class="c-process__error"
          >
            {{ "select_an_exam" | translate }}
          </div>
        </div>
      </div>
      <!-- Agregar nuevo examen -->
      <div class="c-process__add-exam">
        <button
          class="c-process__button-add c-exam__button-add--question"
          (click)="addExam()"
        >
          {{ "add_exam" | translate }}
        </button>
        <div class="c-process__add-exam-plus">
          <img src="../../../../assets/icons/plus.svg.svg" alt="" />
        </div>
      </div>

      <label class="c-process__label">
        {{ "initial_date" | translate }}
      </label>
      <input
        class="c-process__input"
        [(ngModel)]="startDateProcess"
        type="datetime-local"
        formControlName="startDate"
      />
      <div
        *ngIf="
          formProcess.get('startDate').hasError('required') &&
          (formProcess.get('name').dirty ||
            formProcess.get('description').dirty ||
            formProcess.get('examSelected').dirty ||
            formProcess.get('startDate').touched ||
            formProcess.get('totalCode').dirty)
        "
        class="c-process__error"
      >
        {{ "select_an_initial_date_of_the_process" | translate }}
      </div>

      <!-- <label class="c-process__label">Fecha fin</label>
            <input class="c-process__input" [(ngModel)]="endingDateProcess" type="datetime-local" formControlName="endingDate">
            <div *ngIf="formProcess.hasError('wrongDate') && formProcess.get('startDate').touched && formProcess.get('endingDate').touched" class="c-process__error">La fecha de fin debe ser mayor a la fecha de inicio</div> -->

      <label class="c-process__label">
        {{ "select_the_quantity_of_desired_code_to_generate" | translate }}
      </label>
      <input
        class="c-process__input-number"
        [(ngModel)]="codeProces"
        type="number"
        formControlName="totalCode"
        min="1"
      />
      <div
        *ngIf="
          formProcess.get('totalCode').hasError('required') &&
          (formProcess.get('name').dirty ||
            formProcess.get('description').dirty ||
            formProcess.get('examSelected').dirty ||
            formProcess.get('startDate').dirty ||
            formProcess.get('totalCode').touched)
        "
        class="c-process__error"
      >
        {{ "complete_the_quantity_of_generated_code" | translate }}
      </div>
      <!-- <p>{{msgDate}}</p> -->

      <div class="c-process__content-button">
        <app-button
          [placeHolder]="'save_process' | translate"
          [icon]="'../../../../assets/icons/right-arrow.svg'"
          class="c-exam__submit-btn"
          (click)="createProcess()"
          [disabled]="!formProcess.valid"
        ></app-button>
      </div>
    </form>
  </div>

  <div
    *ngIf="creating"
    class="c-process__content-spinner c-process__content-spinner--white"
  >
    <mat-spinner [diameter]="30"></mat-spinner>
    <p class="c-process__p-spinner">
      {{ "creating_new_proccess" | translate }}
    </p>
  </div>

  <div *ngIf="processCreate" class="c-process__content-result">
    <h1 class="c-process__result-title">
      {{ "process_created_successfully" | translate }}
    </h1>
    <p class="c-process__result-msg">
      {{ "back" | translate }}
    </p>
    <a
      class="c-process__button c-process__result-button"
      [routerLink]="['/admin/dashboard/process/details/', newIdProcess]"
    >
      {{ "see_process" | translate }}
    </a>
  </div>

  <app-footer [isDarkFooter]="false"></app-footer>
</div>
