<div class="c-thanks">
  <div class="c-thanks__content-background" *ngIf="!moreExams">
    <img
      class="c-thanks__background"
      src="assets/img/team-smiling-and-clapping-in-circle-people 1.png"
      alt=""
    />
  </div>
  <div class="c-thanks__content-background" *ngIf="moreExams">
    <img
      class="c-thanks__background"
      src="assets/img/colleagues-giving-each-other-a-high-five 1.png"
      alt=""
    />
  </div>
  <div class="c-thanks__content-form">
    <div class="c-thanks__container">
      <div class="c-thanks__header">
        <div class="c-thanks__content-logo">
          <img
            class="c-thanks__logo"
            src="assets/img/UST-logo_dark-blue.svg"
            alt=""
          />
        </div>

        <div class="c-thanks__content-soport">
          <candidate-language-selector></candidate-language-selector>

          <a [routerLink]="['/candidate/support/', idProcess]"
            ><img
              class="c-thanks__icon-soport"
              src="/assets/img/soport.svg"
              alt="Soporte"
          /></a>
        </div>
      </div>

      <div *ngIf="moreExams">
        <h1 class="c-thanks__title2">
          {{ "exam_ready" | translate: { numExam: numExam } }}
        </h1>
        <p class="c-thanks__subtitle">
          {{ "exam_first_finish" | translate }}
        </p>
        <div class="more-exams-button">
          <app-button
            [placeHolder]="'start'"
            [icon]="'../../../../assets/icons/right-arrow.svg'"
            (clicked)="next()"
          >
          </app-button>
        </div>
      </div>

      <div *ngIf="!moreExams">
        <h1 class="c-thanks__title">{{ "thanks" | translate }}</h1>
        <p class="c-thanks__subtitle">
          {{ "exam_all_finish" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
<app-footer [isDarkFooter]="false"></app-footer>
