import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { ResultService } from 'src/app/services/result.service';
import { TextInputParameters } from 'src/app/shared/components/inputs/text-input/text-input.component';

let EMAIL_REGEX: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
@Component({
  selector: 'app-soport',
  templateUrl: './soport.component.html',
  styleUrls: ['./soport.component.scss'],
})
export class SoportComponent implements OnInit {
  public sending = false;
  public sent = false;
  public idProcess;
  public to = 'roberto.alvarez@ust.com';
  public msg;
  public process;

  nameInputValue: string = '';
  nameInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'text_input.placeholder.full_name',
    isRequired: true,
  });
  nameIsValid: boolean = false;

  codeInputValue: string = '';
  codeInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'text_input.placeholder.access_code',
    isRequired: true,
  });
  codeIsValid: boolean = false;

  emailInputValue: string = '';
  emailInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'text_input.placeholder.email',
    isRequired: true,
  });
  emailIsValid: boolean = false;

  messageInputValue: string = '';
  messageInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'text_input.placeholder.message',
    isRequired: true,
  });
  messageIsValid: boolean = false;

  constructor(
    private apiService: ApiService,
    private location: Location,
    private rutaActiva: ActivatedRoute,
    private resultService: ResultService
  ) {}

  ngOnInit(): void {
    this.rutaActiva.params.subscribe((params: Params) => {
      this.idProcess = params.idProcess;
    });
    this.to = 'roberto.alvarez@ust.com';

    if (this.idProcess) {
      this.resultService.getResults(this.idProcess).subscribe((data) => {
        this.msg = data;

        if (this.msg) {
          if (this.msg.msg !== 'No exist') {
            this.process = data;
            this.to = this.process.user;
          }
        }
      });
    }
  }

  onNameValueChange(val: string) {
    this.nameInputValue = val;
    this.nameIsValid = this.nameInputValue?.trim().length > 0;
  }

  onCodeValueChange(val: string) {
    this.codeInputValue = val;
    this.codeIsValid = this.codeInputValue?.trim().length > 0;
  }

  onEmailValueChange(val: string) {
    this.emailInputValue = val?.trim();
    if (this.emailInputValue?.length) {
      if (EMAIL_REGEX.test(this.emailInputValue)) {
        this.emailInputParams.errorMessage = null;
        this.emailIsValid = true;
        return;
      } else {
        this.emailInputParams.errorMessage = 'error_invalid_email';
      }
    }
    this.emailIsValid = false;
  }

  onMessageValueChange(val: string) {
    this.messageInputValue = val;
    this.messageIsValid = this.messageInputValue?.trim().length > 0;
  }

  sendData() {
    this.sent = false;
    this.sending = true;
    const name = this.nameInputValue;
    const code = this.codeInputValue;
    const email = this.emailInputValue;
    const message = this.messageInputValue;

    this.apiService
      .sendMail(name, code, email, message, this.to)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            return throwError(
              'client/network error ocurred, please try again later'
            ); // client-side or network error
          } else {
            return throwError(error.error); // backend error
          }
        })
      )
      .subscribe(() => {
        this.sent = true;
        this.sending = false;
      });
  }

  backClicked() {
    this.location.back();
  }
}
