<div class="c-details">
  <app-menu [superUser]="superuser" [isLogged]="isLogged"></app-menu>

  <div *ngIf="process" class="c-details__container-head">
    <div class="c-details__back">
      <img
        class="c-details__arrow-back"
        src="/assets/img/arrow.svg"
        alt="volver"
      />
      <a class="c-details__link" (click)="backClicked()">
        {{ "back" | translate }}
      </a>
    </div>

    <div *ngIf="showSpinner" class="c-details__content-spinner">
      <mat-spinner [diameter]="30"></mat-spinner>
      <p class="c-details__p-spinner">
        {{ "loading_process" | translate }}
      </p>
    </div>

    <div class="c-details__container">
      <div class="c-details__content-description">
        <h2 class="c-details__title-description">{{ process.name }}</h2>
        <p class="c-details__description">{{ process.description }}</p>
      </div>

      <div class="c-details__content-exam">
        <div class="c-details__content-left">
          <label class="c-details__label">
            {{ "exam_in_progress" | translate }}
          </label>
          <div *ngIf="!dataExam" class="c-details__content-spinner">
            <mat-spinner [diameter]="20"></mat-spinner>
          </div>
          <div *ngFor="let item of dataExam">
            <p class="c-details__p" *ngIf="item">
              <a
                class="c-details__exam-title-access"
                matTooltip="{{ 'see_exam' | translate }}"
                [routerLink]="['/admin/dashboard/exam/details/', item.id2]"
              >
                {{ item.titleExam }}
              </a>
            </p>
          </div>
        </div>

        <div>
          <label class="c-details__label">
            {{ "questions" | translate }}
          </label>
          <div *ngIf="!dataExam" class="c-details__content-spinner">
            <mat-spinner [diameter]="20"></mat-spinner>
          </div>
          <div *ngFor="let item of dataExam">
            <p class="c-details__p" *ngIf="item">{{ item.questions.length }}</p>
          </div>
        </div>
      </div>

      <!-- Tabla con lo resultados -->
      <h3 class="c-details__label">
        {{ "answered_exams_ranking" | translate }}
      </h3>

      <div *ngIf="loadUpdates" class="c-details__content-spinner">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>

      <div class="c-details__content-process">
        <candidates-table
          [dataSource]="dataTable"
          [title]="titleDetails"
          [columns]="columns"
        ></candidates-table>
      </div>

      <div class="c-details__content-code">
        <label class="c-details__label">
          {{ "generated_codes" | translate }}
        </label>
        <div class="c-details__download" (click)="downloadCode()">
          <button class="c-details__download-access">
            {{ "download_all_the_codes" | translate }}
          </button>
        </div>

        <!-- Mostrando códigos dividos cuando hay más de 5 códigos -->
        <div
          class="c-details__divideCode"
          *ngIf="process.codeAccess.length > 5"
        >
          <div class="c-deatils__parts">
            <div
              class="c-details__container-code"
              *ngFor="let item of codePart1; let i = index"
            >
              <div *ngIf="process.usedCodes; else labelsimple">
                <label
                  [ngClass]="
                    process.usedCodes.indexOf(item) >= 0
                      ? 'c-details__code c-details__code--used'
                      : 'c-details__code'
                  "
                  id="code{{ i }}"
                  class=""
                >
                  {{ item }}
                </label>
              </div>
              <ng-template #labelsimple
                ><label class="c-details__code" id="code{{ i }}">
                  {{ item }}
                </label>
              </ng-template>
              <img
                matTooltip="Copiar código"
                (click)="copy(item)"
                class="c-details__code-img"
                src="/assets/img/copy.svg"
                alt="Copiar"
              />
            </div>
          </div>
          <div class="c-deatils__parts">
            <div
              class="c-details__container-code"
              *ngFor="let item of codePart2; let i = index"
            >
              <div *ngIf="process.usedCodes; else labelsimple">
                <label
                  [ngClass]="
                    process.usedCodes.indexOf(item) >= 0
                      ? 'c-details__code c-details__code--used'
                      : 'c-details__code'
                  "
                  id="code{{ i }}"
                  class=""
                >
                  {{ item }}
                </label>
              </div>
              <ng-template #labelsimple
                ><label class="c-details__code" id="code{{ i }}">
                  {{ item }}
                </label>
              </ng-template>
              <img
                matTooltip="Copiar código"
                (click)="copy(item)"
                class="c-details__code-img"
                src="/assets/img/copy.svg"
                alt="copy code"
              />
            </div>
          </div>

          <div class="c-deatils__parts">
            <div
              class="c-details__container-code"
              *ngFor="let item of codePart3; let i = index"
            >
              <div *ngIf="process.usedCodes; else labelsimple">
                <label
                  [ngClass]="
                    process.usedCodes.indexOf(item) >= 0
                      ? 'c-details__code c-details__code--used'
                      : 'c-details__code'
                  "
                  id="code{{ i }}"
                  class=""
                >
                  {{ item }}
                </label>
              </div>
              <ng-template #labelsimple
                ><label class="c-details__code" id="code{{ i }}">
                  {{ item }}
                </label>
              </ng-template>
              <img
                matTooltip="Copiar código"
                (click)="copy(item)"
                class="c-details__code-img"
                src="/assets/img/copy.svg"
                alt="copy code"
              />
            </div>
          </div>
        </div>

        <!-- Mostrando codigos cuando son <= 5 -->
        <div *ngIf="process.codeAccess.length <= 5">
          <div
            class="c-details__container-code"
            *ngFor="let item of process.codeAccess; let i = index"
          >
            <div *ngIf="process.usedCodes; else labelsimple">
              <label
                [ngClass]="
                  process.usedCodes.indexOf(item) >= 0
                    ? 'c-details__code c-details__code--used'
                    : 'c-details__code'
                "
                id="code{{ i }}"
                class=""
              >
                {{ item }}
              </label>
            </div>
            <ng-template #labelsimple
              ><label class="c-details__code" id="code{{ i }}">
                {{ item }}
              </label>
            </ng-template>
            <img
              matTooltip="Copiar código"
              (click)="copy(item)"
              class="c-details__code-img"
              src="/assets/img/copy.svg"
              alt="copy code"
            />
          </div>
        </div>

        <div *ngIf="loadNewCodes" class="c-details__content-spinner">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>

        <!-- Generar nuevos códigos -->
        <div
          *ngIf="openProcess"
          class="c-details__add-code"
          (click)="openDialog('code')"
        >
          <button class="c-details__button-code">
            {{ "generate_new_code" | translate
            }}<span class="c-details__green-plus"> +</span>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="openProcess" class="c-details__content-button">
      <app-button
        class="c-details__close-button"
        *ngIf="openProcess"
        [placeHolder]="'close_process' | translate"
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        (click)="openDialog('closeProcess')"
      ></app-button>
    </div>

    <div *ngIf="!openProcess" class="c-details__content-button">
      <app-button
        class="c-details__close-button"
        [disabled]="true"
        [placeHolder]="'close_process' | translate"
      ></app-button>
    </div>
  </div>

  <div *ngIf="!process && noExists" class="c-details__content-result">
    <p class="c-details__result-msg">
      {{ "sorry_process_dont_exist" | translate }}
    </p>
    <a
      class="c-details__result-button"
      [routerLink]="['/admin/dashboard/process']"
    >
      {{ "see_processes" | translate }}
    </a>
  </div>

  <app-footer [isDarkFooter]="false"></app-footer>
</div>
