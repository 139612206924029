import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TextInputParameters } from 'src/app/shared/components/inputs/text-input/text-input.component';
import { ValueChange } from 'src/app/shared/components/inputs/value-change';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  msgRegister: string;

  error: boolean;
  forbidden: boolean;
  success: boolean;
  incomplete: boolean;
  differentPassword = false;
  msgFrom: string;

  public creating = false;

  nameInputValue: string = '';
  nameInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'name',
    isRequired: true,
  });
  nameIsValid: boolean = false;

  surnameInputValue: string = '';
  surnameInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'surname',
    isRequired: true,
  });
  surnameValid: boolean = false;

  emailInputValue: string = '';
  emailInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'text_input.placeholder.email',
    isRequired: true,
    errorMessage: '',
  });
  emailIsValid: boolean = false;

  passwordInputValue: string = '';
  passwordInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'text_input.placeholder.password',
    isRequired: true,
    errorMessage: '',
  });
  passwordIsValid: boolean = false;

  password2InputValue: string = '';
  password2InputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'repeat_the_password',
    isRequired: true,
    errorMessage: '',
  });
  password2IsValid: boolean = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private authService: AuthService,
    private apiService: ApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
  }

  onNameValueChange(value: string) {
    this.nameInputValue = value;
    this.nameIsValid = this.nameInputValue?.trim().length > 0;;
  }

  onSurnameValueChange(value: string) {
    this.surnameInputValue = value;
    this.surnameValid = this.surnameInputValue?.trim().length > 0;;
  }

  onEmailValueChange(event: ValueChange) {
    this.emailInputValue = event.value;
    this.emailIsValid = !event.invalid;
  }

  onPasswordValueChange(event: ValueChange){
    this.passwordInputValue = event.value;
    this.passwordIsValid = !event.invalid;
  }

  onPassword2ValueChange(event: ValueChange){
    this.password2InputValue = event.value;
    this.password2IsValid = !event.invalid;
  }

  isFormValid(){
    return this.nameIsValid &&
          this.surnameValid &&
          this.emailIsValid &&
          this.passwordIsValid &&
          this.password2IsValid;
  }

  
  formClear() {
    this.nameInputValue = '';
    this.surnameInputValue = '';
    this.emailInputValue = '';
    this.passwordInputValue = '';
    this.password2InputValue = '';
  }

  async registerAdmin() {
    if (this.isFormValid()) {
      this.creating = true;
      const name = this.nameInputValue;
      const surName = this.surnameInputValue;
      let email = this.emailInputValue.toLowerCase();
      const password = this.passwordInputValue;

      try {
        await this.authService.register(email, password).then((result) => {

          if (result.code) {
            // Ocurrió algún error
            switch (result.code) {
              case 'auth/weak-password':
                this.msgRegister =
                  'password_must_contain_a_minimun_of_6_characters';
                  this.creating = false;
                break;
              case 'auth/invalid-email':
                this.msgRegister = 'invalid_email';
                this.creating = false;
                break;
              case 'auth/email-already-in-use':
                this.msgRegister = 'email_already_exists';
                this.creating = false;
                break;
              default:
                this.msgRegister = 'error_happened_try_again';
                this.creating = false;
                break;
            }
          } else {
            // Ha registrado con éxito el usuario -> Registramos en el Cloud FireStore
            this.apiService
              .registerAfs(email, name, surName)
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  if (error.error instanceof ErrorEvent) {
                    // client-side or network error
                    this.error = true;
                    return throwError(
                      'client/network error ocurred, please try again later'
                    );
                  } else {
                    // backend error
                    if (error.status == 403) this.forbidden = true;
                    return throwError(error.error);
                  }
                })
              )
              .subscribe((data) => {
                this.formClear();
                this.creating = false;
                this.router.navigate(['/admin/login']);
              });
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
}
