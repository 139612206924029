<div class="c-home">
  <app-menu [superUser]="superuser" [isLogged]="isLogged"></app-menu>

  <div class="c-home__main">
    <div class="c-home__content-title">
      <h1 class="c-home__title">
        {{ "hello!" | translate }}
      </h1>
      <h3 class="c-home__subtitle">
        {{ "welcome_to_the_gestor_of_processes" | translate }}
      </h3>
    </div>
    <div class="c-home__content-buttons">
      <div class="divleft">
        <app-search-input
          (clickSearch)="findProcess()"
          [placeholder]="'enter_code' | translate"
          [icon]="'../../../../assets/icons/search.svg'"
          [searcherId]="'codeinput'"
          class="c-home__search-input"
        ></app-search-input>
      </div>
      <div class="divright">
        <app-button
          [placeHolder]="'create_new_proccess' | translate"
          [icon]="'../../../../assets/icons/right-arrow.svg'"
          class="c-home__submit-btn"
          (click)="routing('process/create')"
        ></app-button>
      </div>
    </div>

    <candidates-table
      [dataSource]="myProcess"
      [title]="titleTable"
      [columns]="cols"
    ></candidates-table>
  </div>
  <app-footer [isDarkFooter]="false"></app-footer>
</div>
