<div class="c-exam">
  <div class="c-exam__header">
    <div class="c-exam__content-logo">
      <img
        class="c-exam__logo"
        src="assets/img/UST-logo_dark-blue.svg"
        alt=""
      />
    </div>

    <div *ngIf="!begin" class="c-exam__content-soport">
      <candidate-language-selector
        class="c-exam__language-selector"
      ></candidate-language-selector>

      <div *ngIf="msgSoport" class="c-exam__msg-soport">
        {{ "support" | translate }}
      </div>
      <a
        [routerLink]="['/candidate/support/', idProcess]"
        class="c-exam__soport"
        ><img
          class="c-exam__icon-soport"
          src="/assets/img/soport.svg"
          alt="Soporte"
      /></a>
    </div>
  </div>

  <!-- <div *ngIf="loading" class="c-exam__content-spinner">
    <mat-spinner [diameter]="60"></mat-spinner>
    <p class="c-exam__p-spinner">Cargando exámen</p>
  </div> -->

  <div *ngIf="!begin && !codeUsed" class="c-exam__content-result">
    <h1 class="c-exam__result-title">
      {{ "exam_welcome_title" | translate: { name: name } }}
    </h1>
    <div class="c-exam__result-msg">
      <div>
        {{
          "exam_message"
            | translate: { numQuestions: numQuestions, minTotal: minTotal }
        }}
      </div>
      <div>{{ "exam_message_doubts" | translate }}</div>
      <br />
      <div>{{ "good_luck" | translate }}</div>
    </div>
    <div class="c-exam__button-begin">
      <app-button
        [placeHolder]="'start' | translate"
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        class="c-home__submit-btn"
        (click)="comenzar()"
      ></app-button>
    </div>
  </div>

  <div *ngIf="!codeUsed && begin" class="exam-content-wrapper">
    <div *ngIf="exam && !creating" class="c-exam__content">
      <form
        autocomplete="off"
        [formGroup]="examForm"
        (ngSubmit)="sendResults()"
      >
        <div *ngFor="let question of exam.questions; let i = index">
          <div class="c-exam__question" *ngIf="i == n">
            <div class="c-exam__timer">
              <app-timer-bar
                [barProgress]="barProgress"
                [minutes]="minutes"
                [seconds]="seconds"
              ></app-timer-bar>
            </div>

            <!-- <progress max="{{exam.questions.length}}" value="{{i}}"></progress> -->
            <div formArrayName="examAnswers">
              <div class="c-exam__content-title">
                <!-- <p class="c-exam__title">{{question.question}}</p> -->
                <div class="c-exam__text-area">{{ question.question }}</div>
                <div class="c-exam__num-question">
                  {{ i + 1 }}/{{ exam.questions.length }}
                </div>
              </div>
              <div
                class="c-exam__content-answer"
                *ngFor="let option of question.options; let j = index"
              >
                <!-- <input type="radio" name="{{question.question}}" (change)="onAnswer(i,j, $event.target.checked)"> -->
                <div class="answer-letter">{{ letters[j] }})</div>
                <div
                  class="exam-answer"
                  [class.selected]="answerSelected == j"
                  (click)="onAnswer(i, j, $event.target.value)"
                >
                  <pre>{{ option.texto }}</pre>
                </div>
                <!-- <label [ngClass]="answerSelected == j ? 'c-exam__answer c-exam__answer--select' : 'c-exam__answer'" (click)="onAnswer(i,j, $event.target.value)">{{option.texto}}</label> -->
              </div>
            </div>
          </div>
        </div>

        <div [class.show-error]="!enableButton" class="c-exam__error">
          <p>
            {{ "exam_obligatory_field" | translate }}
          </p>
        </div>
        <div class="button-row">
          <div class="button-wrapper" *ngIf="!(n == exam.questions.length - 1)">
            <app-button
              [disabled]="!enableButton"
              [placeHolder]="'next' | translate"
              [icon]="'../../../../assets/icons/right-arrow.svg'"
              class="next-button"
              (click)="next()"
            ></app-button>
          </div>
          <div class="button-wrapper" *ngIf="n == exam.questions.length - 1">
            <app-button
              [disabled]="!enableButton"
              [placeHolder]="'send_answer' | translate"
              [icon]="'../../../../assets/icons/right-arrow.svg'"
              class="submit-button"
              (click)="sendResults()"
            ></app-button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="codeUsed && !loading" class="c-exam__back">
    <img class="c-exam__arrow-back" src="/assets/img/arrow.svg" alt="volver" />
    <a
      class="c-exam__link-back"
      [routerLink]="['/candidate/home']"
      routerLinkActive="router-link-active"
    >
      {{ "back" | translate }}
    </a>
  </div>

  <div *ngIf="codeUsed && !loading" class="c-exam__code-wrong">
    <p>
      {{ "error_code_wrong" | translate }}
    </p>
  </div>

  <div *ngIf="creating" class="c-exam__content-spinner">
    <mat-spinner [diameter]="30"></mat-spinner>
    <p class="c-exam__p-spinner">
      {{ "saving_results" | translate }}
    </p>
  </div>

  <app-footer [isDarkFooter]="false"></app-footer>
</div>
