import { Component, EventEmitter, Input, Output } from '@angular/core';
export class TextInputParameters {
  isRequired?: boolean = false;
  isDisabled?: boolean = false;
  textLabel: string = 'Test Label';
  errorMessage?: string | null = null;
  isDark?: boolean = false;

  constructor(base?: TextInputParameters) {
    this.isRequired = base?.isRequired ?? false;
    this.isDisabled = base?.isDisabled ?? false;
    this.textLabel = base?.textLabel ?? '';
    this.errorMessage = base?.errorMessage ?? null;
    this.isDark = base?.isDark ?? false;
  }
}

let REQUIRED_MESSAGE = 'text_input.validation.required_field';
@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent {
  @Input() params: TextInputParameters = new TextInputParameters();
  @Input() value: string = '';

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    if (this.params.isRequired) {
    }
  }

  onValueChange(val) {
    this.checkIsRequired();
    this.valueChange.emit(this.value);
  }

  private checkIsRequired() {
    if (this.params.isRequired) {
      if (
        !this.value?.length &&
        this.params?.errorMessage != REQUIRED_MESSAGE
      ) {
        this.params.errorMessage = REQUIRED_MESSAGE;
      } else if (
        this.value?.length &&
        this.params?.errorMessage === REQUIRED_MESSAGE
      ) {
        this.params.errorMessage = null;
      }
    }
  }
}
