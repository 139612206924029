import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ExamService {
  public currentExamNumber;
  public totalExamNumber;
  public allExamIds;

  constructor(private http: HttpClient, private afs: AngularFirestore) {}

  getExams() {
    return this.afs
      .collection('exam')
      .get()
      .pipe(
        map((snap) =>
          snap.docs.map((data) => ({ id: data.id, ...(data.data() as object) }))
        )
      );
  }

  getNewExams() {
    return this.afs.collection('exam').valueChanges({ idField: 'id' });
  }

  getExamById(id) {
    return this.afs.collection('exam').doc(id).valueChanges({ idField: 'id' });
  }

  getExam(id: string) {
    return this.afs
      .collection('exam')
      .doc(id)
      .get()
      .pipe(map((data) => data.data()));
  }

  getLastVersionExam(id: string) {
    return this.afs
      .collection('exam', (ref) =>
        ref.where('id2', '==', id).where('lastVersion', '==', true)
      )
      .get()
      .pipe(
        take(1),
        map((snap) => {
          if (snap.empty) return { msg: 'Exam not found' };
          return snap.docs.map((doc: any) => ({ examid: doc.id }));
        })
      );
  }

  createExam(titleExam, topic, questions, user, createdBy) {
    const creationDate = new Date();
    const newId = this.afs.createId();
    return this.afs.collection('exam').doc(newId).set({
      titleExam,
      topic,
      questions,
      lastVersion: true,
      version: 1,
      id2: newId,
      user: user,
      hide: false,
      creationDate: creationDate.getTime(),
      createdBy: createdBy
    });
  }

  async updateExam(id, titleExam, topic, questions, createdBy) {
    try {
      const exam = await this.afs.collection('exam').doc(id).get().toPromise();
      const uses = await this.afs
        .collection('process', (ref) => ref.where('examSelected', '==', id))
        .get()
        .toPromise();

      const examData: any = exam.data();
      const auxTitle = examData.titleExam.split('V.');
      const newVersion = examData.version + 1;

      const newTitle =
        titleExam ?? auxTitle[0].concat(' V.', newVersion.toString());
      const newCreatedBy = createdBy ?? examData.createdBy;
      const topicAux = topic ?? examData.topic;

      if (uses.empty) {
        // has no uses, edit
        await this.afs.collection('exam').doc(id).update({
          questions: questions,
          titleExam: newTitle,
          version: newVersion,
          topic: topicAux,
          creationDate: new Date().getTime(),
          createdBy: newCreatedBy
        });
      } else {
        // has uses, duplicate
        await this.afs
          .collection('exam')
          .doc(id)
          .update({ lastVersion: false });
        await this.afs.collection('exam').add({
          questions: questions,
          lastVersion: true,
          titleExam: newTitle,
          version: newVersion,
          id2: examData.id2,
          topic: topicAux,
          hide: false,
          user: examData.user,
          creationDate: new Date().getTime(),
          createdBy: newCreatedBy
        });
      }
    } catch (err) {
      console.log('updateExam ERROR!', err);
    }
  }

  /*getProcessByCode(code:string) {
    return this.afs.collection('process', ref => ref.where('codeAccess', 'array-contains', code)).get().pipe(map(snap => {
      if (snap.empty) return { msg: 'Process with code: ' + code + ' does not exist' };
      return snap.docs.map((doc: any) => ({ id: doc.id, data: doc.data() }))
    }));
  }*/

  deleteExam(id, id2, lastVersion) {
    return this.afs
      .collection('process', (ref) => ref.where('examSelected', '==', id2))
      .get()
      .pipe(
        take(1),
        map((snap) => {
          if (!snap.empty) {
            if (lastVersion) {
              return { msg: 'Cannot delete lastVersion in process' };
            }
            this.afs
              .collection('exam')
              .doc(id)
              .set({ hide: true }, { merge: true });
            return { msg: 'Ok!' };
          }
          this.afs.collection('exam').doc(id).delete();

          return { msg: 'OK!' };
        })
      );
  }
}
