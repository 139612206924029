<div class="c-exam">
  <app-menu [superUser]="superuser" [isLogged]="isLogged"></app-menu>

  <div *ngIf="!examCreate && !creating" class="c-exam__container">
    <div class="c-exam__back">
      <img
        class="c-exam__arrow-back"
        src="/assets/icons/left-arrow.svg"
        alt="Volver"
      />
      <a class="c-exam__link" (click)="backClicked()">
        {{ "back" | translate }}
      </a>
    </div>

    <form [formGroup]="formExam" class="c-exam__form">
      <!-- Title Exam -->
      <input
        formControlName="titleExam"
        type="text"
        class="c-exam__input"
        placeholder="{{ 'name_exam' | translate }}"
      />
      <div
        *ngIf="
          formExam.get('titleExam').hasError('required') &&
          (formExam.get('titleExam').touched ||
            formExam.get('topicSelected').touched)
        "
        class="c-exam__error"
      >
        {{ "complete_the_name_of_the_exam" | translate }}
      </div>
      <div *ngIf="dotNotValid.errors">
        <div *ngIf="dotNotValid.errors.pattern" class="c-exam__error">
          {{ "the_name_can_not_contain_dots" | translate }}
        </div>
      </div>
      <div
        class="c-exam__error"
        *ngIf="formExam.get('titleExam').dirty ? titleExists() : false"
      >
        {{
          "the_name_was_already_asigned_to_another_exam_try_another_one"
            | translate
        }}
      </div>

      <!-- Select Topic-->
      <div class="c-exam__content-topic">
        <select
          formControlName="topicSelected"
          (change)="valueOption($event.target.value)"
          class="c-exam__select"
        >
          <option value="" disabled>
            {{ "asign_a_skill" | translate }}
          </option>
          <option [value]="item.topic" *ngFor="let item of allTopics">
            {{ item.topic }}
          </option>
          <option value="crearTopic">
            {{ "create_a_new_topic" | translate }}
          </option>
        </select>

        <div
          *ngIf="showSpinner"
          class="c-exam__content-spinner c-exam__content-spinner--blue"
        >
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
      <div
        *ngIf="
          formExam.get('topicSelected').hasError('required') &&
          (formExam.get('titleExam').touched ||
            formExam.get('topicSelected').touched)
        "
        class="c-exam__error"
      >
        {{ "select_a_topic" | translate }}
      </div>

      <!-- Array de questions -->
      <div formArrayName="questions">
        <div
          [formGroupName]="indexQuestion"
          *ngFor="let item of questions.controls; let indexQuestion = index"
        >
          <!-- Time -->
          <div class="c-exam__content-right">
            <input
              type="number"
              class="c-exam__time"
              formControlName="min"
              placeholder="mm"
              max="60"
              (keypress)="checkChar($event)"
              (keyup)="onlyNumber($event, indexQuestion)"
            />
            <span class="c-exam__icon-point">:</span>
            <input
              type="number"
              class="c-exam__time"
              formControlName="seg"
              placeholder="ss"
              max="60"
              (keypress)="checkChar($event)"
              (keyup)="onlyNumber($event, indexQuestion)"
            />
            <img
              class="c-exam__icon-time"
              src="/assets/img/time.svg"
              alt="tiempo"
            />
          </div>
          <div
            *ngIf="
              (questions.controls[indexQuestion]
                .get('min')
                .hasError('required') ||
                questions.controls[indexQuestion]
                  .get('seg')
                  .hasError('required')) &&
              (questions.controls[indexQuestion].get('min').touched ||
                questions.controls[indexQuestion].get('seg').touched ||
                formExam.get('titleExam').touched ||
                formExam.get('topicSelected').touched)
            "
            class="c-exam__error c-exam__error--time"
          >
            {{ "complete_the_time_of_the_question" | translate }}
          </div>
          <div
            *ngIf="
              questions.controls[indexQuestion]
                .get('min')
                .hasError('pattern') ||
              questions.controls[indexQuestion].get('seg').hasError('pattern')
            "
            class="c-exam__error c-exam__error--time"
          >
            {{ "only_two_characters_allowed" | translate }}
          </div>
          <div
            class="c-exam__error c-exam__error--time"
            *ngIf="
              (questions.controls[indexQuestion].get('min').touched ||
                questions.controls[indexQuestion].get('seg').touched) &&
              !existsCorrectTime[indexQuestion]
            "
          >
            {{ "the_time_must_begreater_than_zero" | translate }}
          </div>

          <div class="c-exam__separator-question"></div>

          <!-- Questions -->
          <div class="c-exam__content-question">
            <textarea
              id="question{{ indexQuestion }}"
              (keydown)="checkCharQA($event, 'question', indexQuestion, -1)"
              formControlName="question"
              class="c-exam__text-area"
              placeholder="{{ indexQuestion + 1 }}. {{
                'question' | translate
              }}"
            ></textarea>
            <!-- <input type="text" class="c-exam__input" formControlName="question" placeholder="{{indexQuestion + 1}}. Pregunta"> -->
            <button
              *ngIf="indexQuestion > 0"
              (click)="deleteQuestion(indexQuestion)"
              class="c-exam__delete-question"
            >
              {{ "cross" | translate }}
            </button>
          </div>
          <div
            *ngIf="
              questions.controls[indexQuestion]
                .get('question')
                .hasError('required') &&
              (questions.controls[indexQuestion].get('question').touched ||
                formExam.get('titleExam').touched ||
                formExam.get('topicSelected').touched)
            "
            class="c-exam__error"
          >
            {{ "complete_the_statement_of_the_question" | translate }}
          </div>

          <!-- Options / Answers -->
          <div class="c-exam__content-options">
            <!-- Answers -->
            <div class="c-exam__content-answer">
              <div
                class="c-exam__answer_options"
                *ngFor="
                  let options of item.controls.options.controls;
                  let indexOption = index
                "
              >
                <div class="c-exam__select-correct">
                  <input
                    type="radio"
                    class="c-exam__radio"
                    (click)="checkedAnswer(indexQuestion)"
                    formControlName="correctAnswer"
                    value="{{ indexOption }}"
                  />
                </div>
                <div class="c-exam__answer" formArrayName="options">
                  <!-- <input class="c-exam__input c-exam__input--answer" formControlName="texto" type="text" placeholder="Respuesta {{indexOption + 1}}"> -->
                  <div class="answer_row" [formGroupName]="indexOption">
                    <textarea
                      id="q{{ indexQuestion }}a{{ indexOption }}"
                      (keydown)="
                        checkCharQA(
                          $event,
                          'answer',
                          indexQuestion,
                          indexOption
                        )
                      "
                      class="c-exam__text-area c-exam__input--answer"
                      formControlName="texto"
                      type="text"
                      placeholder="{{ 'answer' | translate }} {{
                        indexOption + 1
                      }}"
                    ></textarea>
                    <button
                      *ngIf="indexOption > 1"
                      (click)="deleteOption(indexQuestion, indexOption)"
                      class="c-exam__delete-answer"
                    >
                      {{ "cross" | translate }}
                    </button>
                  </div>
                  <div
                    *ngIf="
                      checkAnswerFill(indexQuestion, indexOption) &&
                      (questions.controls[indexQuestion]
                        .get('options')
                        .controls[indexOption].get('texto').touched ||
                        formExam.get('titleExam').touched ||
                        formExam.get('topicSelected').touched)
                    "
                    class="c-exam__error-answer"
                  >
                    {{ "complete_the_answer" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              questions.controls[indexQuestion]
                .get('correctAnswer')
                .hasError('required') &&
                (formExam.get('titleExam').touched ||
                  formExam.get('topicSelected').touched);
              else selectCorrect
            "
            class="c-exam__error"
          >
            {{ "select_which_one_is_the_correct_answer" | translate }}
          </div>
          <ng-template #selectCorrect
            ><div
              *ngIf="
                (formExam.get('titleExam').touched ||
                  formExam.get('topicSelected').touched) &&
                !existsCorrectAnswer[indexQuestion]
              "
              class="c-exam__error"
            >
              {{ "select_which_one_is_the_correct_answer" | translate }}
            </div></ng-template
          >

          <div class="c-exam__content-right">
            <button
              (click)="addOption(indexQuestion)"
              class="c-exam__button-add c-exam__button-add--option"
            >
              {{ "add_answer" | translate }}
            </button>
          </div>
        </div>
      </div>

      <!-- Add Question -->
      <div class="c-exam__add-question">
        <button
          class="c-exam__button-add c-exam__button-add--question"
          (click)="addQuestion()"
        >
          {{ "add_new_question" | translate }}
        </button>
        <div class="c-exam__add-question-plus">
          <img src="../../../../assets/icons/plus.svg.svg" alt="" />
        </div>
      </div>

      <!-- Enviar -->
      <div class="c-exam__content-button" *ngIf="enableButton()">
        <app-button
          [placeHolder]="'save_exam' | translate"
          [icon]="'../../../../assets/icons/right-arrow.svg'"
          class="c-exam__submit-btn"
          (click)="createExam()"
          [disabled]="!formExam.valid"
        >
        </app-button>
      </div>
    </form>
  </div>

  <div
    *ngIf="creating"
    class="c-exam__content-spinner c-exam__content-spinner--white"
  >
    <mat-spinner [diameter]="30"></mat-spinner>
    <p class="c-exam__p-spinner">{{ "creating_new_exam" | translate }}</p>
  </div>

  <div *ngIf="examCreate" class="c-exam__content-result">
    <div *ngIf="previosUrl === '/admin/dashboard/process/create'">
      <h1 class="c-exam__result-title">
        {{ "exam_was_successfully_created" | translate }}
      </h1>
      <p class="c-exam__result-msg">
        {{
          "your_exam_was_successfully_created_you_will_be_redirected_to_the_creation_process_page"
            | translate
        }}
      </p>
    </div>

    <div
      *ngIf="previosUrl === '/admin/dashboard/exam' || !previosUrl"
      class="c-exam__content-result"
    >
      <h1 class="c-exam__result-title">
        {{ "exam_was_successfully_created" | translate }}
      </h1>
      <p class="c-exam__result-msg">
        {{
          "your_exam_was_successfully_created_you_can_always_see_the_details_of_the_process_in_the_home_page"
            | translate
        }}
      </p>
      <div class="c-exam__result-btn">
        <app-button
          [placeHolder]="'go_home' | translate"
          [icon]="'../../../../assets/icons/right-arrow.svg'"
          class="c-exam__submit-btn"
          [routerLink]="['/admin/dashboard/exam']"
        >
        </app-button>
      </div>
    </div>
  </div>

  <app-footer [isDarkFooter]="false"></app-footer>
</div>
