import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  accessCode = localStorage.getItem('cpc') || 'XXXXX';
  name = '';
  process = '';
  allExamResult = [];

  //public data;

  constructor(private afs: AngularFirestore) {}

  async createResult(processId, accessCode, name) {
    try {
      const process = await this.afs
        .collection('process')
        .doc(processId)
        .get()
        .toPromise();

      let allResults: any[] = [];
      let usedCodes: any[] = [];
      const processData: any = process.data();
      if (processData.results) {
        allResults = processData.results;
      }
      if (processData.usedCodes) {
        usedCodes = processData.usedCodes;
      }
      let auxResult;

      if (processData.appVersion && processData.appVersion > 1) {
        //console.log("Creando nuevo resultado con versión nueva");
        let totalTime = 0;
        let totalScore = 0;
        this.allExamResult.forEach((exam) => {
          totalTime = totalTime + exam.minutes * 60 + exam.seconds;
          totalScore = totalScore + exam.score;
        });
        const totalMinutes = Math.trunc(totalTime / 60);
        const totalSeconds = totalTime - totalMinutes * 60;
        //console.log("totalScore: ",totalScore);
        const newScore = totalScore / this.allExamResult.length;
        auxResult = {
          accessCode: accessCode,
          name: name,
          link: '',
          status: 'En proceso',
          exam: this.allExamResult,
          score: newScore,
          minutes: totalMinutes,
          seconds: totalSeconds,
          nExam: this.allExamResult.length
        };
      } else {
        auxResult = {
          accessCode: accessCode,
          name: name,
          link: '',
          status: 'En proceso',
          answers: this.allExamResult[0].answers,
          examId: this.allExamResult[0].examId,
          score: this.allExamResult[0].score,
          minutes: this.allExamResult[0].minutes,
          seconds: this.allExamResult[0].seconds,
          nExam: 1
        };
      }

      allResults.push(auxResult);

      usedCodes.push(accessCode);
      this.allExamResult = [];
      return this.afs
        .collection('process')
        .doc(processId)
        .set({ results: allResults, usedCodes: usedCodes }, { merge: true });
    } catch (err) {
      console.log('createResult ERROR!', err);
    }
  }

  getResults(idProcess) {
    return this.afs.collection('process').doc(idProcess).valueChanges();
  }

  updateStatusResult(processId, accessCode, status) {
    return this.updateResult(processId, accessCode, status, '');
  }

  updateBizneoResult(processId, accessCode, link) {
    return this.updateResult(processId, accessCode, '', link);
  }

  private async updateResult(processId, accessCode, status, link) {
    const process = await this.afs
      .collection('process')
      .doc(processId)
      .get()
      .toPromise();
    const processData: any = process.data();
    const allResults = processData.results;
    let i = 0;
    while (allResults[i].accessCode.match(accessCode) === null) {
      i++;
    }
    let auxResult;
    //console.log("appVersion: ",processData.appVersion );
    // console.log(processId, accessCode, status, link, i)
    if (processData.appVersion > 1) {
      auxResult = {
        accessCode: allResults[i].accessCode,
        name: allResults[i].name,
        link: allResults[i].link,
        status: allResults[i].status,
        score: allResults[i].score,
        exam: allResults[i].exam,
        minutes: allResults[i].minutes,
        seconds: allResults[i].seconds
      };
    } else {
      auxResult = {
        accessCode: accessCode,
        answers: allResults[i].answers,
        examId: allResults[i].examId,
        name: allResults[i].name,
        score: allResults[i].score,
        status: allResults[i].status,
        link: allResults[i].link,
        minutes: allResults[i].minutes,
        seconds: allResults[i].seconds
      };
    }
    if (status) {
      auxResult.status = status;
    }
    if (link) {
      auxResult.link = link;
    }
    allResults[i] = auxResult;

    this.afs
      .collection('process')
      .doc(processId)
      .set({ results: allResults }, { merge: true });

    return { msg: 'OK!' };
  }

  // Is this data being used??
  /*public saveData(posResult, processId, examId){
    this.data = {posResult, processId, examId};
  }

  public getData(){
    return this.data;
  }*/

  setProcessId(process: string) {
    this.process = process;
    localStorage.setItem('cpr', process);
  }
  getProcessId() {
    return this.process || localStorage.getItem('cpr');
  }
  setName(name: string) {
    this.name = name;
    localStorage.setItem('cpn', name);
  }
  getName() {
    return this.name || localStorage.getItem('cpn');
  }
  setCode(code: string) {
    this.accessCode = code;
    localStorage.setItem('cpc', code);
  }
  getCode() {
    return this.accessCode || localStorage.getItem('cpc');
  }
}
