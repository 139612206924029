import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface Language {
  name: string;
  short: string;
  value: string;
  icon: string;
}

const LANGUAGES: Language[] = [
  {
    name: 'Español',
    short: 'ESP',
    value: 'es',
    icon: '../../../../assets/img/Spain.png',
  },
  {
    name: 'English',
    short: 'ENG',
    value: 'en',
    icon: '../../../../assets/img/USA.png',
  },
];
@Component({
  selector: 'candidate-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @ViewChild(MatSelect) readonly matSelect: MatSelect;
  @Input() style;
  languages = LANGUAGES;
  value: Language;
  constructor(private readonly translate: TranslateService) {}
  ngOnInit(): void {
    this.value = this.languages.find(
      (language) => language.value === this.translate.currentLang
    );
  }

  change(event: any) {
    this.value = event.value;
    this.translate.use(event.value.value);
  }
}
