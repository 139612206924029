<div class="input-wrapper">
  <div class="label-wrapper">
    <div class="input-label">
      {{ params.textLabel | translate }}{{ params.isRequired ? "*" : "" }}
    </div>
  </div>
  <div class="input-row" [class.error]="params.errorMessage">
    <textarea
      class="text-area-input"
      [(ngModel)]="value"
      (ngModelChange)="onValueChange($event)"
    ></textarea>
    <div class="error-message">
      {{ params.errorMessage | translate }}
    </div>
  </div>

  <!-- <div fxLayout="row wrap" fxLayoutGap="16px">
    <div fxLayoutGap="16px" *ngIf="help && control?.touched">
      <label [matTooltip]="help" matTooltipPosition="above" class="inputHelp">{{
        placeholder
      }}</label>
    </div>
    <div *ngIf="inputType == 'text'">
      <mat-form-field
        class="full-width"
        appearance="outline"
        [ngClass]="{
          'mat-form-field-invalid': control?.invalid && control?.touched
        }"
      >
        <input
          type="text"
          matInput
          class="text-input"
          (focusout)="onTouched()"
          (input)="onChange($event.target.value)"
          placeholder="{{ placeholder }}"
        />
        <mat-hint
          class="invalid-field"
          *ngIf="control?.errors?.required && control?.touched"
          >{{ "text_input.validation.required_field" | translate }}</mat-hint
        >
      </mat-form-field>
    </div>
  </div> -->

  <!-- <div fxFlex="48" *ngIf="inputType == 'textArea'">
    <mat-form-field
      class="full-width"
      appearance="outline"
      [ngClass]="{
        'mat-form-field-invalid': control.invalid && control.touched
      }"
    >
      <textarea
        matInput
        class="textArea-input"
        (focusout)="onTouched()"
        (input)="onChange($event.target.value)"
        >{{ value }}</textarea
      >
      <mat-hint
        class="invalid-field"
        *ngIf="control?.errors?.required && control?.touched"
        >{{ "text_input.validation.required_field" | translate }}</mat-hint
      >
    </mat-form-field>
  </div> -->
</div>
