import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ExamService } from 'src/app/services/exam.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PdfMakeWrapper } from 'pdfmake-wrapper';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Location } from '@angular/common';
import { ProcessService } from 'src/app/services/process.service';
import { ResultService } from 'src/app/services/result.service';
import { DataColumn } from 'src/app/shared/components/table/models/table.model';
import { UserSessionService } from 'src/app/services/user-session.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-details-process',
  templateUrl: './details-process.component.html',
  styleUrls: ['./details-process.component.scss'],
})
export class DetailsProcessComponent implements OnInit {
  idProcess: string;
  process;
  newCode;
  dataExam: any[] = [];
  public existsExam = false;
  isLogged = false;
  generatedCode: string[] = [];
  resultsCandidate;
  public totalColumns: string[] = [
    'Ranking',
    'Candidato',
    'Codigo',
    'Puntuacion',
    'Tiempo',
    'Status',
    'Bizneo',
  ];
  public dataSource = null;
  codePart1;
  codePart2;
  codePart3;
  results = '';
  public actionMenu = '';
  public codeAccesCandidate = '';
  public openBizneo: boolean[] = [];
  public msg;
  public noExists = false;
  public showSpinner = false;
  public loadNewCodes = false;
  public loadUpdates = false;
  public indexSelected = -1;
  public modalBizneo = false;
  public open = false;
  public actionSelected = 'll';
  public multiplyExams = false;
  public totalExams = 0;
  public superuser = false;
  public userEmail: string;
  public user;
  public openProcess = true;
  public examVersion = 'version';
  public orderCandidate = 'static';
  public orderPuntuacion = 'static';
  public orderExam1 = 'static';
  public orderExam2 = 'static';
  public orderExam3 = 'static';
  public orderTime = 'static';
  public orderStatus = 'static';
  public orderRanking = 'static';

  dataTable;
  titleDetails = 'ranking';
  columns: DataColumn[] = [
    {
      name: 'Ranking',
      column: 'position',
      isOrdenable: true,
    },
    {
      name: 'candidate',
      column: 'name',
      isOrdenable: true,
    },
    {
      name: 'code',
      column: 'accessCode',
      isOrdenable: true,
    },
    {
      name: 'score',
      column: 'score',
      isOrdenable: true,
      navigation: '/admin/dashboard/result/',
    },
    {
      name: 'time',
      column: 'totalTime',
      isOrdenable: true,
    },
    {
      name: 'status',
      column: 'status',
      isOrdenable: true,
    },
  ];
  public examColumnOrders: string[];

  constructor(
    private rutaActiva: ActivatedRoute,
    private examService: ExamService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private location: Location,
    private processService: ProcessService,
    private resultService: ResultService,
    private userSession: UserSessionService,
    private readonly translate: TranslateService
  ) {}

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  async ngOnInit() {
    this.rutaActiva.params.subscribe((params: Params) => {
      this.idProcess = params.idProcess;
    });
    this.showSpinner = true;

    try {
      await this.userSession.getUserLogged().then((userLogged) => {
        if (userLogged) {
          this.isLogged = true;
          this.userEmail = userLogged.email;

          this.userSession.getUser().then((resp) => {
            this.user = resp;
            this.user.superuser
              ? (this.superuser = true)
              : (this.superuser = false);
          });
        }
      });
    } catch (error) {
      console.error(error);
    }

    this.resultService.getResults(this.idProcess).subscribe((data) => {
      this.showSpinner = false;
      this.loadNewCodes = false;

      this.msg = data;

      if (this.msg) {
        if (this.msg.msg !== 'No exist') {
          this.process = data;
          this.results = this.process.results;
          this.openProcess = this.process.open;

          const result = this.process.results;
          if (result) {
            result.forEach((element) => {
              this.openBizneo.push(false);
            });
          }

          this.getDataExam();

          this.createTable();

          if (this.process.codeAccess) {
            if (this.process.codeAccess.length > 5) {
              this.showCode();
            }
          }
        } else {
          this.noExists = true;
        }
      } else {
        this.noExists = true;
      }
    });
  }

  getDataExam() {
    // console.log("this.process.examSlected: ",this.process.examSelected);
    if (typeof this.process.examSelected != 'string') {
      // Próximas versiones -> permite guardar un array de examenes
      this.process.examSelected.forEach((element, index) => {
        this.examService.getExam(element.idExam).subscribe((data) => {
          this.dataExam[index] = data;

          this.totalExams = this.dataExam.length;

          this.examColumnOrders = Array(this.totalExams).fill('static');

          this.examVersion = 'new';
          this.multiplyExams = this.totalExams > 1;

          if (this.multiplyExams) {
            this.columns = [
              {
                name: 'Ranking',
                column: 'position',
                isOrdenable: true,
              },
              {
                name: 'candidate',
                column: 'name',
                isOrdenable: true,
              },
              {
                name: 'code',
                column: 'accessCode',
                isOrdenable: true,
              },
              {
                name: 'score',
                column: 'score',
                isOrdenable: true,
                navigation: '/admin/dashboard/result/',
              },
              {
                name: 'time',
                column: 'totalTime',
                isOrdenable: true,
              },
              {
                name: 'status',
                column: 'status',
                isOrdenable: true,
              },
            ];

            for (let i = this.totalExams; i > 0; --i) {
              this.columns.splice(3, 0, {
                name: this.translate.instant('exam') + i,
                column: 'exam',
                navigation: '/admin/dashboard/result/',
                isOrdenable: true,
                position: i - 1,
              });
            }
            // console.log(this.totalColumns);
          } else {
            this.columns = [
              {
                name: 'Ranking',
                column: 'position',
                isOrdenable: true,
              },
              {
                name: 'candidate',
                column: 'name',
                isOrdenable: true,
              },
              {
                name: 'code',
                column: 'accessCode',
                isOrdenable: true,
              },
              {
                name: 'score',
                column: 'score',
                isOrdenable: true,
                navigation: '/admin/dashboard/result/',
              },
              {
                name: 'time',
                column: 'totalTime',
                isOrdenable: true,
              },
              {
                name: 'status',
                column: 'status',
                isOrdenable: true,
              },
            ];
          }
        });
      });
    } else {
      // Primera versión -> Solo acepta un examen Y lo guarda como string
      this.examService
        .getExamById(this.process.examSelected)
        .subscribe((data) => {
          this.dataExam[0] = data;
          this.totalColumns = [
            'Ranking',
            'Candidato',
            'Codigo',
            'Puntuacion',
            'Tiempo',
            'Status',
            'Bizneo',
          ];
          // console.log(this.dataExam)
          this.examVersion = 'old';
          this.multiplyExams = false;
        });
    }
  }

  showCode() {
    const divider = Math.floor(this.process.codeAccess.length / 3);

    this.codePart1 = this.process.codeAccess.slice(0, divider);
    this.codePart2 = this.process.codeAccess.slice(divider, divider * 2);
    this.codePart3 = this.process.codeAccess.slice(divider * 2);
  }

  createTable() {
    if (this.process.results) {
      // Agregando nueva propiedad al objeto
      this.process.results.forEach((element, index) =>
        Object.defineProperty(element, 'realPosition', {
          value: index,
          writable: true,
          enumerable: true,
          configurable: true,
        })
      );

      this.process.results.forEach((element, index) =>
        Object.defineProperty(element, 'totalTime', {
          value:
            this.process.results[index].minutes * 60 +
            this.process.results[index].seconds,
          writable: true,
          enumerable: true,
          configurable: true,
        })
      );
      // console.log(this.process.results)

      const result = this.process.results;

      result.sort(function (a, b) {
        // Ordenamos de forma descendente por el score del examen 1
        if (a.score < b.score) {
          return 1;
        }
        if (a.score > b.score) {
          return -1;
        }
        // Si los score son iguales ordenamos de forma ascendente por el tiempo empleado en responder el examen
        if (a.minutes * 60 + a.seconds < b.minutes * 60 + b.seconds) {
          return -1;
        }
        if (a.minutes * 60 + a.seconds > b.minutes * 60 + b.seconds) {
          return 1;
        }
        return 0;
      });

      result.forEach((element, index) =>
        Object.defineProperty(element, 'position', {
          value: index + 1,
          writable: true,
          enumerable: true,
          configurable: true,
        })
      );

      this.dataSource = new MatTableDataSource(result);
      this.dataTable = this.process.results.map(
        (element) => (element = { ...element, id: this.idProcess })
      );
    }
  }

  orderColumn(tipo: string) {
    if (tipo == 'candidate') {
      if (this.orderCandidate == 'static') {
        this.orderCandidate = 'asc';
      }
      if (this.orderCandidate == 'asc') {
        this.process.results.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.orderCandidate = 'desc';
      } else {
        this.process.results.sort(function (a, b) {
          if (a.name < b.name) {
            return 1;
          }
          if (a.name > b.name) {
            return -1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.orderCandidate = 'asc';
      }

      this.orderPuntuacion = 'static';
      this.examColumnOrders = this.examColumnOrders.fill('static');
      this.orderTime = 'static';
      this.orderStatus = 'static';
      this.orderRanking = 'static';
    } else if (tipo == 'puntuacion') {
      if (this.orderPuntuacion == 'static') {
        this.orderPuntuacion = 'asc';
      }
      if (this.orderPuntuacion == 'asc') {
        this.process.results.sort(function (a, b) {
          if (a.score < b.score) {
            return -1;
          }
          if (a.score > b.score) {
            return 1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.orderPuntuacion = 'desc';
      } else {
        this.process.results.sort(function (a, b) {
          if (a.score < b.score) {
            return 1;
          }
          if (a.score > b.score) {
            return -1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.orderPuntuacion = 'asc';
      }

      this.orderCandidate = 'static';
      this.examColumnOrders = this.examColumnOrders.fill('static');
      this.orderTime = 'static';
      this.orderStatus = 'static';
      this.orderRanking = 'static';
    } else if (tipo.startsWith('exam_')) {
      const examID = parseInt(tipo.split('_').pop());

      if (this.examColumnOrders[examID] == 'static')
        this.examColumnOrders[examID] = 'asc';
      if (this.examColumnOrders[examID] == 'asc') {
        this.process.results.sort((a, b) => {
          if (a.exam[examID].score < b.exam[examID].score) {
            return -1;
          }
          if (a.exam[examID].score > b.exam[examID].score) {
            return 1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.examColumnOrders[examID] = 'desc';
      } else {
        this.process.results.sort(function (a, b) {
          if (a.exam[examID].score < b.exam[examID].score) {
            return 1;
          }
          if (a.exam[examID].score > b.exam[examID].score) {
            return -1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.examColumnOrders[examID] = 'asc';
      }

      this.orderCandidate = 'static';
      this.orderPuntuacion = 'static';
      this.orderTime = 'static';
      this.orderStatus = 'static';
      this.orderRanking = 'static';

      const temp = this.examColumnOrders[examID];
      this.examColumnOrders = this.examColumnOrders.fill('static');
      this.examColumnOrders[examID] = temp;
    } else if (tipo == 'time') {
      if (this.orderTime == 'static') {
        this.orderTime = 'asc';
      }
      if (this.orderTime == 'asc') {
        this.process.results.sort(function (a, b) {
          if (a.totalTime < b.totalTime) {
            return -1;
          }
          if (a.totalTime > b.totalTime) {
            return 1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.orderTime = 'desc';
      } else {
        this.process.results.sort(function (a, b) {
          if (a.totalTime < b.totalTime) {
            return 1;
          }
          if (a.totalTime > b.totalTime) {
            return -1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.orderTime = 'asc';
      }

      this.orderCandidate = 'static';
      this.orderPuntuacion = 'static';
      this.examColumnOrders = this.examColumnOrders.fill('static');
      this.orderStatus = 'static';
      this.orderRanking = 'static';
    } else if (tipo == 'status') {
      if (this.orderStatus == 'static') {
        this.orderStatus = 'asc';
      }
      if (this.orderStatus == 'asc') {
        this.process.results.sort(function (a, b) {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.orderStatus = 'desc';
      } else {
        this.process.results.sort(function (a, b) {
          if (a.status < b.status) {
            return 1;
          }
          if (a.status > b.status) {
            return -1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.orderStatus = 'asc';
      }

      this.orderCandidate = 'static';
      this.orderPuntuacion = 'static';
      this.examColumnOrders = this.examColumnOrders.fill('static');
      this.orderTime = 'static';
      this.orderRanking = 'static';
    } else if (tipo == 'ranking') {
      if (this.orderRanking == 'static') {
        this.orderRanking = 'asc';
      }
      if (this.orderRanking == 'asc') {
        this.process.results.sort(function (a, b) {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position > b.position) {
            return 1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.orderRanking = 'desc';
      } else {
        this.process.results.sort(function (a, b) {
          if (a.position < b.position) {
            return 1;
          }
          if (a.position > b.position) {
            return -1;
          }
          return 0;
        });

        this.dataSource = new MatTableDataSource(this.process.results);
        this.orderRanking = 'asc';
      }

      this.orderCandidate = 'static';
      this.orderPuntuacion = 'static';
      this.examColumnOrders = this.examColumnOrders.fill('static');
      this.orderTime = 'static';
      this.orderStatus = 'static';
    }
  }

  // Crear PDF con todos los códigos
  async downloadCode() {
    PdfMakeWrapper.setFonts(pdfFonts);

    const pdf = new PdfMakeWrapper();

    const allCode = [];

    this.process.codeAccess.forEach((element) => {
      allCode.push(element);
    });
    pdf.header('UST');
    pdf.add('Nombre del proceso: ' + this.process.name + '\n');
    pdf.add('Códigos de acceso: \n');
    pdf.add(allCode);

    pdf.create().open();
  }

  createCode(numCode) {
    const char = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (let i = 0; i < numCode; i++) {
      const codeAccess =
        char.charAt(Math.floor(Math.random() * char.length)) +
        char.charAt(Math.floor(Math.random() * char.length)) +
        char.charAt(Math.floor(Math.random() * char.length)) +
        char.charAt(Math.floor(Math.random() * char.length)) +
        char.charAt(Math.floor(Math.random() * char.length));

      this.generatedCode.push(codeAccess);
    }
  }

  // Abre modal para generar nuevos códigos y colocar el link de bizneo
  openDialog(tipoDialog) {
    if (tipoDialog === 'code') {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: 'createCode',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.loadNewCodes = true;
          this.createCode(result);

          // Update process -> agregar codeAccess
          this.processService
            .updateprocess(this.idProcess, this.generatedCode)
            .then(() => {
              // Se han actualizado los códigos de acceso, y obtenemos de nuevo los datos del proceso
              //  this.getProcess(true);
            });
        }
      });
    } else if (tipoDialog === 'bizneo') {
      const dialogRef = this.dialog.open(DialogComponent, { data: 'bizneo' });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.actionMenu = '';
          this.updateResult('bizneo', this.codeAccesCandidate, result);
          // console.log(this.codeAccesCandidate);
        }
      });
    } else if (tipoDialog === 'closeProcess') {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: 'closeProcess',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.processService.updateprocess(this.idProcess, false).then(() => {
            this.snackBar.open('El proceso ha sido cerrado', null, {
              duration: 3000,
              panelClass: 'snackbar',
            });
            this.openProcess = false;
          });
        }
      });
    }
  }

  saveData(realPosition) {
    //this.resultService.saveData(realPosition, this.idProcess, this.process.examSelected)
    this.router.navigate(['/admin/dashboard/result']);
  }

  // Cuando cambia el status de un candidato
  valueOptionStatus(value, codeAcess) {
    console.log(this.dataSource, value, codeAcess, this.idProcess);

    this.updateResult('status', codeAcess, value);
  }

  valueOptionMenu(value, index, codeAccess) {
    this.codeAccesCandidate = codeAccess;
    if (value === 'edit') {
      this.openDialog('bizneo');
    } else if (value !== '') {
      // this.copy(value);
      window.open(value, '_blank');
    }

    this.actionMenu = '';
    this.openBizneo[index] = false;
  }

  showBizneo(event, index) {
    const previous = this.openBizneo[index];
    this.indexSelected = index;

    event.stopPropagation();
    window.onclick = (event) => {
      this.openBizneo[index] = false;
      window.onclick = null;
    };

    // Todos en false para ocultarlos
    this.openBizneo.forEach((element, pos) => {
      this.openBizneo[pos] = false;
    });

    previous
      ? (this.openBizneo[index] = false)
      : (this.openBizneo[index] = true);
    this.modalBizneo = true;
    this.open = true;
  }

  hiddenMenu(index) {
    this.openBizneo[index] = false;
    this.modalBizneo = false;
  }

  updateResult(tipo, codeAcces, value) {
    this.loadUpdates = true;

    if (tipo === 'status') {
      this.resultService
        .updateStatusResult(this.idProcess, codeAcces, value)
        .then(() => {
          this.snackBar.open('¡Estado actualizado con éxito!', null, {
            duration: 3000,
            panelClass: 'snackbar',
          });
          this.loadUpdates = false;
        });
    } else if (tipo === 'bizneo') {
      this.resultService
        .updateBizneoResult(this.idProcess, codeAcces, value)
        .then(() => {
          // Se han actualizado los códigos de acceso, y obtenemos de nuevo los datos del proceso
          // this.getProcess(true);
          this.loadUpdates = false;
          this.snackBar.open('¡Link guardado con éxito!', null, {
            duration: 3000,
            panelClass: 'snackbar',
          });
        });
    }
  }

  backClicked() {
    this.router.navigate(['/admin/dashboard/process']);
  }

  copy(code) {
    this.clipboard.copy(code);

    this.snackBar.open('¡Texto copiado al portapapeles!', null, {
      duration: 3000,
      panelClass: 'snackbar',
    });
  }

  toggleMenu() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }
}
