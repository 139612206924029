import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// Firebase
import 'firebase/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public super;

  constructor(
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private http: HttpClient
  ) {}

  /**
   * Register users in Cloud Firestore after having been added in the authentication part
   * @param email
   * @param name
   * @param surName
   */
  registerAfs(email: string, name: string, surName: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      environment.api_url('register'),
      { email, name, surName },
      { headers }
    );
  }

  getUser(emailUser): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      environment.api_url('getuser'),
      { emailUser },
      { headers }
    );
    // return this.http.get(environment.api_url('getuser/' + emailUser));
  }

  // ------------- Enviar email -------------
  sendMail(name, code, from, message, to) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      environment.api_url('sendmail'),
      { name, code, from, message, to },
      { headers }
    );
  }

  /*  // ------------- Topic -------------
  createTopic(topic, user){
    const date = new Date();
    return this.afs.collection('topic').doc(topic).get().pipe(map(snap => {
      if(snap.exists) return { msg: '¡Lo Siento! El topic ya existe'};
      this.afs.collection('topic').doc(topic).set({ topic, date, user });
      return {msg: 'OK!'}
    }))
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //return this.http.post(environment.api_url('createtopic'), { topic, user }, { headers });
  }

  getTopic(topic){    
    return this.afs.collection('topic').doc(topic).get().pipe(map(data => data.data()));
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //return this.http.post(environment.api_url('gettopic'), { topic }, { headers });
  }

  getAllTopic(){
    return this.afs.collection('topic').get().pipe(map(snap => snap.docs.map(data => data.data())));
    //return this.http.get(environment.api_url('getalltopic'));
  }

  deleteTopic(name){
    return this.afs.collection('exam', ref => ref.where('topic', '==', name)).get().pipe(take(1), map(snap => {
      if (!snap.empty) return { error: 'Topic in use' };
      this.afs.collection('topic').doc(name).delete();
      return { msg: 'OK!' }
    }));
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //return this.http.post(environment.api_url('deletetopic'), { name }, { headers });
  }*/

  // ------------- Exam -------------
  /*createExam(titleExam, topic, questions, user, createdBy){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('createexam'), { titleExam, topic, questions, user, createdBy}, { headers });
  }*/

  /*getallexam(){
    return this.http.get(environment.api_url('getallexam'));
  }*/

  /*updateExam(id, titleExam, topic, questions, createdBy){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('updateexam'), { id, titleExam, topic, questions,createdBy}, { headers });
  }*/

  /*deleteExam(id,id2,lastVersion){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('deleteexam'), { id,id2,lastVersion }, { headers });
  }*/

  // ------------- Selection Process -------------
  /*createProcess(name, description, examSelected, startDate, endingDate, codeAccess, user, createdBy){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('createprocess'), { name, description, examSelected, startDate, endingDate, codeAccess, user, createdBy}, { headers });
  }*/

  /*getAllProcess(user){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('getallprocess'), {user}, { headers });
  }*/

  /*getProcess(idProcess){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('getprocess'), {idProcess}, {headers});
    
  }*/

  /*updateprocess(idProcess, codeAccess){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('updateprocess'), { idProcess, codeAccess }, { headers });
  }*/

  /*deleteProcess(idProcess){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('deleteprocess'), { idProcess }, { headers });
  }*/

  // ------------- Results Candidate -------------
  /*getAllResults(processId){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('getallresult'), {processId}, { headers });
  }

  getResultCandidate(resultId){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('getcandidate'), {resultId}, { headers });
  }


  getResultByProcess(processId){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('getresultprocess'), {processId}, { headers });
  }

  updateStatusResult(processId, accessCode, status){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('updateresult'), {processId, accessCode, status}, { headers });
  }

  updateBizneoResult(processId, accessCode, link){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('updateresult'), {processId, accessCode, link}, { headers });
  }

  createResultCandidate(name, examId, accessCode, answers, processId, score){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.api_url('createresultcandidate'), {name, examId, accessCode, answers, processId, score}, { headers });
  }
*/

  /*checkCode(processId, accessCode){
    return this.afs.collection<any>('process').doc(processId).get();
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //return this.http.post(environment.api_url('checkcode'), {processId, accessCode}, { headers });
  }*/
}
