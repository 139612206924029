import { StatsComponent } from './dashboard/stats/stats.component';
import { SoportComponent } from './main/soport/soport.component';
import { ResultExamComponent } from './dashboard/process/result-exam/result-exam.component';
import { EditExamComponent } from './dashboard/exam/edit-exam/edit-exam.component';
import { TopicComponent } from './dashboard/topic/topic.component';
import { CreateExamComponent } from './dashboard/exam/create-exam/create-exam.component';
import { ExamComponent } from './dashboard/exam/exam.component';
import { DetailsProcessComponent } from './dashboard/process/details-process/details-process.component';
import { CreateProcessComponent } from './dashboard/process/create-process/create-process.component';
import { ProcessComponent } from './dashboard/process/process.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './dashboard/login/login.component';
import { RegisterComponent } from './dashboard/register/register.component';
import { CandidateHomeComponent } from './main/candidate-home/candidate-home.component';
import { ExamCandidateComponent } from './main/exam-candidate/exam-candidate.component';
import { ThanksComponent } from './main/thanks/thanks.component';
import { DetailsExamComponent } from './dashboard/exam/details-exam/details-exam.component';

import { redirectUnauthorizedTo, canActivate } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['admin/login']);

const routes: Routes = [
  {
    path: 'requests',
    loadChildren: () =>
      import('./hiring-requests/hiring-requests.module').then(
        (m) => m.HiringRequestsModule
      )
  },

  { path: 'admin/register', component: RegisterComponent },
  { path: 'admin/login', component: LoginComponent },
  // Topic
  {
    path: 'admin/dashboard/topic',
    component: TopicComponent,
    ...canActivate(redirectUnauthorizedToLogin)
  },
  // Exam
  {
    path: 'admin/dashboard/exam/create',
    component: CreateExamComponent,
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'admin/dashboard/exam/details/:idExam',
    component: DetailsExamComponent,
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'admin/dashboard/exam/edit/:idExam',
    component: EditExamComponent,
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'admin/dashboard/exam',
    component: ExamComponent,
    ...canActivate(redirectUnauthorizedToLogin)
  },
  // Process
  {
    path: 'admin/dashboard/process/create',
    component: CreateProcessComponent,
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'admin/dashboard/process/details/:idProcess',
    component: DetailsProcessComponent,
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'admin/dashboard/process/result/:idProcess/:examId/:posCandidate',
    component: ResultExamComponent,
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'admin/dashboard/process',
    component: ProcessComponent,
    ...canActivate(redirectUnauthorizedToLogin)
  },
  { path: 'admin/dashboard', redirectTo: 'admin/dashboard/process' },
  // Stats
  {
    path: 'admin/dashboard/stats',
    component: StatsComponent,
    ...canActivate(redirectUnauthorizedToLogin)
  },

  // Candidato
  { path: 'candidate/support/:idProcess', component: SoportComponent },
  { path: 'candidate/support', component: SoportComponent },
  { path: 'candidate/exam/:id', component: ExamCandidateComponent },
  { path: 'candidate/home', component: CandidateHomeComponent },
  { path: 'candidate/thanks', component: ThanksComponent },
  { path: '', redirectTo: 'candidate/home', pathMatch: 'full' },
  { path: '**', redirectTo: 'candidate/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
