import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ExamService } from 'src/app/services/exam.service';
import { RouterService } from 'src/app/services/router.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import { UserSessionService } from 'src/app/services/user-session.service';
import { DataColumn } from 'src/app/shared/components/table/models/table.model';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss'],
})
export class ExamComponent implements OnInit {
  public userEmail: string;
  public allExam;
  public isLogged = false;
  public currentUser;
  public msgLogout: string;
  public totalColumns: string[] = [
    'Nombre del examen',
    'Creado por',
    'Topic',
    'Preguntas',
    ' ',
  ];
  public dataProcess: [] = [];
  public dataSource = null;
  public actionMenu = '';
  public exam;
  public openMenu: boolean[] = [];
  public showSpinner = false;
  public user;
  public createdBy;
  public superUser = false;
  public titleExam: string;
  public allTitleExam = [];
  public totalExam = 0;
  public orderTitleExam = 'static';
  public orderCreatedBy = 'static';
  public orderSkill = 'static';
  public orderNumQuestions = 'static';

  titleTable = 'all_exams';

  public columns: DataColumn[] = this.superUser
    ? [
        {
          name: 'name_of_the_exam',
          column: 'titleExam',
          isSeveral: true,
          navigation: '/admin/dashboard/exam/details/',
          isOrdenable: true,
        },
        { name: 'created_by', column: 'createdBy', isOrdenable: true },
        { name: 'skills', column: 'topic', isOrdenable: true },
        { name: 'questions', column: 'numQuestions', isOrdenable: true },
      ]
    : [
        {
          name: 'name_of_the_exam',
          column: 'titleExam',
          isSeveral: true,
          navigation: '/admin/dashboard/exam/details/',
          isOrdenable: true,
        },
        { name: 'created_by', column: 'createdBy', isOrdenable: true },
        { name: 'skills', column: 'topic', isOrdenable: true },
        { name: 'questions', column: 'numQuestions', isOrdenable: true },
      ];

  constructor(
    public examService: ExamService,
    private router: Router,
    public dialog: MatDialog,
    private routerService: RouterService,
    private snackBar: MatSnackBar,
    private userSession: UserSessionService
  ) {
    this.routerService.getPreviousUrl();
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  async ngOnInit() {
    localStorage.clear();

    try {
      await this.userSession.getUserLogged().then((userLogged) => {
        if (userLogged) {
          this.currentUser = userLogged;
          this.isLogged = true;
          this.userEmail = userLogged.email;

          this.userSession.getUser().then((resp) => {
            this.user = resp;
            this.createdBy = `${this.user.name} ${this.user.surName}`;
            this.user.superuser
              ? (this.superUser = true)
              : (this.superUser = false);
          });
        }
      });
    } catch (error) {
      console.error(error);
    }

    this.examService.getNewExams().subscribe((data) => {
      this.showSpinner = true;
      this.allExam = data;
      this.totalExam = this.allExam.length;

      this.allExam.forEach((element) => {
        this.openMenu.push(false);
        this.allTitleExam.push(element.titleExam.toLowerCase());
      });
      // console.log(this.allTitleExam)
      this.allExam = this.allExam.filter((val) => val.hide == false);
      this.createTable();
    });
  }

  createTable() {
    this.showSpinner = false;

    this.allExam.forEach((element, index) =>
      Object.defineProperty(element, 'numQuestions', {
        value: this.allExam[index].questions
          ? this.allExam[index].questions.length
          : 0,
        writable: true,
        enumerable: true,
        configurable: true,
      })
    );

    const exam = this.allExam;
    // Ordenamos de forma ascendente por la fecha de creacion
    exam.sort(function (a, b) {
      if (a.topic < b.topic) {
        return -1;
      }
      if (a.topic > b.topic) {
        return 1;
      }
      if (a.titleExam < b.titleExam) {
        return -1;
      }
      if (a.titleExam > b.titleExam) {
        return 1;
      }
      return 0;
    });

    this.dataSource = new MatTableDataSource(exam);
  }

  routing(page) {
    this.router.navigate(['/admin/dashboard/' + page]);
  }

  resultLength(array) {
    if (array) {
      return array.length;
    } else {
      return 0;
    }
  }

  hiddenMenu(index) {
    this.openMenu[index] = false;
  }

  toggleMenu() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }
}
