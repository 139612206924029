import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { DataColumn, DataTable } from './models/table.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { ProcessService } from 'src/app/services/process.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TopicService } from 'src/app/services/topic.service';
import { ExamService } from 'src/app/services/exam.service';
import { Router } from '@angular/router';
import { ResultService } from 'src/app/services/result.service';

@Component({
  selector: 'candidates-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnChanges {
  @ViewChild('MatPaginator') MatPaginator: MatPaginator;

  @Input() columns: DataColumn[] = [];
  @Input() dataSource: DataTable[] = [];
  @Input() title: string = '';
  @Input() currentUser: any;
  showSpinner = true;
  public tableDataSource;
  public myProcess;
  displayedColumns = [];
  infoTopic;
  public openMenu: boolean[] = [];
  constructor(
    private readonly translate: TranslateService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private processService: ProcessService,
    private topicService: TopicService,
    private examService: ExamService,
    private resultService: ResultService,
    private router: Router
  ) {
    this.tableDataSource = new MatTableDataSource(this.dataSource ?? []);
    this.tableDataSource.paginator = this.MatPaginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      JSON.stringify(changes.dataSource?.previousValue) !==
      JSON.stringify(changes.dataSource?.currentValue)
    ) {
      this.setup(changes.dataSource.currentValue ?? []);
      this.dataSource = this.dataSource.map((element: any) => {
        return {
          ...element,
          status: element?.open
            ? (element.showStatus = this.translate.instant('open'))
            : (element.showStatus = this.translate.instant('close')),
        };
      });
    }
    if (
      JSON.stringify(changes.columns?.previousValue) !==
      JSON.stringify(changes.columns?.currentValue)
    ) {
      this.displayedColumns = [];
      this.columns.forEach((column) => {
        column.isSeveral = column.isSeveral ? column.isSeveral : false;
        column.isOrdenable = column.isOrdenable ? column.isOrdenable : false;
        column.status = column.status ? column.status : 'static';
        this.displayedColumns.push(column.name);
      });
      this.displayedColumns.push('Menu');
    }
  }

  private setup(dataSource: T[] = []): void {
    if (dataSource) {
      this.showSpinner = false;
      this.tableDataSource = new MatTableDataSource(dataSource);
      this.tableDataSource.paginator = this.MatPaginator;
    }
  }

  order(tipo) {
    if (this.dataSource) {
      this.columns.forEach((column) => {
        if (column.column === tipo) {
          column.status === 'asc'
            ? (column.status = 'desc')
            : (column.status = 'asc');
          if (column.status === 'static') column.status = 'asc';
        } else column.status = 'static';
        if (column.column === tipo) {
          if (column.status === 'asc')
            this.dataSource.sort(function (a, b) {
              if (a[tipo] < b[tipo]) {
                return -1;
              }
              if (a[tipo] > b[tipo]) {
                return 1;
              }
              return 0;
            });
          if (column.status === 'desc')
            this.dataSource.sort(function (a, b) {
              if (a[tipo] < b[tipo]) {
                return 1;
              }
              if (a[tipo] > b[tipo]) {
                return -1;
              }
              return 0;
            });
        }
      });
      this.tableDataSource = new MatTableDataSource(this.dataSource);
      this.tableDataSource.paginator = this.MatPaginator;
    }
  }
  valueOption(value, index, topic) {
    if (value === 'Borrar') {
      this.showSpinner = true;
      this.topicService.deleteTopic(topic).subscribe((data) => {
        this.infoTopic = data;
        this.showSpinner = false;
        if (this.infoTopic.error === 'Topic in use') {
          this.snackBar.open(
            'No se puede eliminar el skill porque está en uso',
            null,
            {
              duration: 3000,
              panelClass: 'snackbar',
            }
          );
        } else {
          this.snackBar.open('¡Skill eliminado con éxito!', null, {
            duration: 3000,
            panelClass: 'snackbar',
          });
        }
      });
    }

    this.openMenu[index] = false;
  }

  edit(element) {
    this.router.navigate(['/admin/dashboard/exam/edit/' + element.id]);
  }

  clone(element) {
    if (this.title === 'all_exams') {
      const dialogRef = this.dialog.open(DialogComponent, { data: 'clonar' });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.showSpinner = true;
          if (
            !this.dataSource.some(
              (element) => result.trim().toLowerCase() === element.titleExam
            )
          ) {
            this.examService.getExam(element.id).subscribe((data: any) => {
              this.examService
                .createExam(
                  result.trim(),
                  data.topic,
                  data.questions,
                  this.currentUser.email,
                  element.createdBy
                )
                .then(() => {
                  this.showSpinner = false;
                  this.snackBar.open('¡Exámen clonado con éxito!', null, {
                    duration: 3000,
                    panelClass: 'snackbar',
                  });
                  this.showSpinner = false;
                });
            });
          } else {
            this.showSpinner = false;
            this.snackBar.open(
              '¡Lo siento! El nombre del exámen ya existe',
              null,
              {
                duration: 4000,
                panelClass: 'snackbar',
              }
            );
          }
        }
      });
    }
  }

  deleteProcess(element) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: 'deleteProcess',
    });
    if (this.title === 'processes')
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.processService.deleteProcess(element.id).then(() => {
            // this.getMyProcess();
            this.snackBar.open(
              '¡El proceso ha sido eliminado con éxito!',
              null,
              {
                duration: 3000,
                panelClass: 'snackbar',
              }
            );
          });
        }
      });
    if (this.title === 'all_skills') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.showSpinner = true;
          this.topicService.deleteTopic(element.topic).subscribe((data) => {
            this.infoTopic = data;
            this.showSpinner = false;
            if (this.infoTopic.error === 'Topic in use') {
              this.snackBar.open(
                'No se puede eliminar el skill porque está en uso',
                null,
                {
                  duration: 3000,
                  panelClass: 'snackbar',
                }
              );
            } else {
              this.snackBar.open('¡Skill eliminado con éxito!', null, {
                duration: 3000,
                panelClass: 'snackbar',
              });
            }
          });
        }
      });
    }
    if (this.title === 'all_exams') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.showSpinner = true;
          this.examService
            .deleteExam(element.id, element.id2, element.lastVersion)
            .subscribe((resp: any) => {
              const msg = resp;
              if (msg.msg === 'Cannot delete lastVersion in process') {
                this.snackBar.open(
                  'Este examen está siendo usado por un proceso, borra primero el proceso para poder borrar el examen.',
                  null,
                  {
                    duration: 4000,
                    panelClass: 'snackbar',
                  }
                );
              } else {
                this.snackBar.open('¡Exámen borrado con éxito!', null, {
                  duration: 3000,
                  panelClass: 'snackbar',
                });
              }
              this.showSpinner = false;
            });
        }
      });
    }
  }
  valueOptionStatus(value, element) {
    this.resultService
      .updateStatusResult(element.id, element.accessCode, value)
      .then(() => {
        this.snackBar.open('¡Estado actualizado con éxito!', null, {
          duration: 3000,
          panelClass: 'snackbar',
        });
      });
  }
  editBizneo(element) {
    const dialogRef = this.dialog.open(DialogComponent, { data: 'bizneo' });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.resultService
          .updateBizneoResult(element.id, element.codeAcces, result)
          .then(() => {
            // Se han actualizado los códigos de acceso, y obtenemos de nuevo los datos del proceso
            // this.getProcess(true);
            this.snackBar.open('¡Link guardado con éxito!', null, {
              duration: 3000,
              panelClass: 'snackbar',
            });
          });
      }
    });
  }

  openBizneo(element) {
    if (element.link !== '') window.open(element.link, '_blank');
  }

  resultLength(array) {
    if (array) {
      return array.length;
    } else {
      return 0;
    }
  }
}
