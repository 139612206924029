<div class="input-wrapper">
  <div class="input-row">
    <div *ngIf="icon" class="search-input-icon">
      <button (click)="onClick()" class="search-input-button">
        <img class="icon" src="{{ icon }}" />
      </button>
    </div>
    <input
      id="{{ searcherId }}"
      class="text-input"
      type="text"
      placeholder="{{ placeholder }}"
      [ngStyle]="style"
      (keyup.enter)="onClick()"
    />
  </div>
</div>
