import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ExamService } from 'src/app/services/exam.service';
import { ProcessService } from 'src/app/services/process.service';
import { ResultService } from 'src/app/services/result.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { TextInputParameters } from 'src/app/shared/components/inputs/text-input/text-input.component';

@Component({
  selector: 'app-candidate-home',
  templateUrl: './candidate-home.component.html',
  styleUrls: ['./candidate-home.component.scss'],
})
export class CandidateHomeComponent implements OnInit {
  form: FormGroup;
  notFound = false;
  public sending = false;
  //public msgCode;
  public processArray;
  //public msgExam;
  public examArray;
  public existsCode = true;
  public existsExam = true;

  nameInputValue: string = '';
  nameInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'text_input.placeholder.full_name',
    isRequired: true,
  });
  nameIsValid: boolean = false;

  codeInputValue: string = '';
  codeInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'text_input.placeholder.access_code',
    isRequired: true,
  });
  codeIsValid: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private resultService: ResultService,
    private analytics: AngularFireAnalytics,
    private processService: ProcessService,
    private examService: ExamService
  ) {
    this.formLoad();
  }

  ngOnInit(): void {
    //this.analytics.logEvent('page_view').then(() => console.log("Evento"));
  }

  onNameValueChange(val: string) {
    this.nameInputValue = val;
    this.nameIsValid = this.nameInputValue?.trim().length > 0;
  }

  onCodeValueChange(val: string) {
    this.clearMsg();
    this.codeInputValue = val;
    this.codeIsValid = this.codeInputValue?.trim().length > 0;
  }

  checkCode() {
    this.sending = true;
    //TODO 2 - !existCode & !sending

    const name = this.nameInputValue;
    this.resultService.setName(name);

    this.goToExamId();
  }

  private goToExamId() {
    this.processService.getProcessByCode(this.codeInputValue).subscribe(
      (resp) => {
        this.processArray = resp;
        // console.log(this.processArray,this.processArray.length)

        if (this.processArray.length > 0) {
          this.existsCode = true;
          this.notFound = false;
          if (
            this.codeInputParams.errorMessage === 'error_code_not_found' ||
            this.codeInputParams.errorMessage === 'error_code_not_exist'
          ) {
            this.codeInputParams.errorMessage = null;
          }
          const process = this.processArray[0];
          this.resultService.setProcessId(this.processArray[0].id);
          let examId = [];
          this.examService.currentExamNumber = 0;
          if (typeof process.examSelected !== 'string') {
            this.examService.totalExamNumber = process.examSelected.length;
            examId = this.processArray[0].examSelected.map(
              (exam: any) => exam.idExam
            );
          } else {
            //Older version is just one exam(string) per process
            examId[0] = this.processArray[0].examSelected;
            this.examService.totalExamNumber = 1;
          }
          //console.log("totalExams: ",this.examService.totalExamNumber);
          // console.log("examId: "+examId, "processId: ",this.processArray[0].id);
          this.examService.allExamIds = examId;
          this.resultService.setCode(this.codeInputValue);

          this.examService
            .getLastVersionExam(examId[this.examService.currentExamNumber])
            .subscribe((resp2: any) => {
              this.examArray = resp2;
              if (this.examArray.length > 0) {
                this.existsExam = true;
                const id = this.examArray[0].examid;
                this.sending = false;
                this.codeInputParams.errorMessage = null;
                this.router.navigateByUrl(`candidate/exam/${id}`);
              } else {
                this.existsExam = false;
                this.sending = false;
                this.codeInputParams.errorMessage = 'error_exam_not_exist';
              }
            });
        } else {
          this.existsCode = false;
          this.sending = false;
          this.codeInputParams.errorMessage = 'error_code_not_exist';
        }
      },
      (error) => {
        this.notFound = true;
        if (this.codeInputValue?.trim().length) {
          this.codeInputParams.errorMessage = 'error_code_not_found';
        }
      }
    );
    //console.log(process);
  }

  private checkForm() {
    //console.log(this.form);
    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach((control) => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach((control) =>
            control.markAsTouched()
          );
        } else {
          control.markAsTouched();
        }
      });
    }
  }

  formLoad() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  clearMsg() {
    this.existsExam = true;
    this.existsCode = true;
    //TODO 2, 3 - !existCode && !sending - !existsExam && !sending
    if (
      this.codeInputParams.errorMessage === 'error_code_not_exist' ||
      this.codeInputParams.errorMessage === 'error_exam_not_exist'
    ) {
      this.codeInputParams.errorMessage = null;
    }
  }
}
