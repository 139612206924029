import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { RouterService } from 'src/app/services/router.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProcessService } from 'src/app/services/process.service';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';
import * as jQuery from 'jquery';
import { UserSessionService } from 'src/app/services/user-session.service';
import { DataColumn } from 'src/app/shared/components/table/models/table.model';
@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss'],
})
export class ProcessComponent implements OnInit {
  public userEmail: string;
  public myProcess;
  public isLogged = false;
  public currentUser;
  public msgLogout: string;
  public totalColumns: string[] = [
    'Nombre del proceso',
    'Id',
    'Fecha inicio',
    'Candidatos',
    'Borrar',
  ];
  public dataProcess: [] = [];
  public dataSource = null;
  public dataSource2 = null;
  public numCandidate: number;
  public responsiveMenu = false;
  public showSpinner = false;
  public allprocess;
  public superuser = false;
  public user;
  public createdBy = [];
  public status;
  public openProcess;
  public closeProcess;
  public totalOpenProcess = 0;
  public totalCloseProcess = 0;
  public heightTable;
  processes: [] = [];
  cols: DataColumn[] = [
    {
      name: 'name_of_the_process',
      column: 'name',
      isSeveral: true,
      navigation: '/admin/dashboard/process/details/',
      isOrdenable: true,
    },
    { name: 'Id', column: 'id', isOrdenable: true },
    { name: 'status', column: 'showStatus', isOrdenable: true },
    { name: 'start_date', column: 'startDate', isOrdenable: true },
    { name: 'candidates', column: 'numCandidates', isOrdenable: true },
  ];
  titleTable = 'processes';

  constructor(
    private apiService: ApiService,
    private router: Router,
    public dialog: MatDialog,
    private routerService: RouterService,
    private snackBar: MatSnackBar,
    private processService: ProcessService,
    private userSession: UserSessionService
  ) {
    this.routerService.getPreviousUrl();
  }

  @ViewChild('MatPaginator1') MatPaginator1: MatPaginator;
  @ViewChild('MatPaginator2') MatPaginator2: MatPaginator;

  async ngOnInit() {
    localStorage.clear();
    this.showSpinner = true;
    this.userSession.getUserLogged().then((userLogged) => {
      if (userLogged) {
        this.isLogged = true;
        this.userEmail = userLogged.email;

        this.userSession.getUser().then((user) => {
          this.user = user;
          if (this.user) {
            if (this.user.superuser) {
              this.apiService.super = this.user.superuser;
              this.superuser = true;
              this.totalColumns = [
                'Nombre del proceso',
                'Id',
                'Creado por',
                'Fecha inicio',
                'Candidatos',
                'Borrar',
              ];
              this.cols = [
                {
                  name: 'name_of_the_process',
                  column: 'name',
                  isSeveral: true,
                  navigation: '/admin/dashboard/process/details/',
                  isOrdenable: true,
                },
                { name: 'Id', column: 'id', isOrdenable: true },
                { name: 'created_by', column: 'createdBy', isOrdenable: true },
                { name: 'status', column: 'showStatus', isOrdenable: true },
                { name: 'start_date', column: 'startDate', isOrdenable: true },
                {
                  name: 'candidates',
                  column: 'numCandidates',
                  isOrdenable: true,
                },
              ];
            } else {
              this.apiService.super = false;
              this.superuser = false;
            }

            this.processService.getProcessChanges().subscribe((data) => {
              this.myProcess = data;
              // this.myProcess.open ? this.status = 'abierto' : this.status = 'cerrado';

              if (!this.superuser) {
                this.myProcess = this.myProcess.filter(
                  (val) => val.user === this.userEmail
                );
              }

              this.showSpinner = false;
              this.createTable();
            });
          }
        });
      }
    });
  }

  createTable() {
    let description;
    this.myProcess.forEach((element) => {
      description = element.description.split(' ');
      const final = description.length <= 15 ? description.length : 15;
      element.description = '';

      for (let i = 0; i < final; i++) {
        if (description.length <= 15) {
          i < final - 1
            ? (element.description += description[i] + ' ')
            : (element.description += description[i]);
        } else {
          i < final - 1
            ? (element.description += description[i] + ' ')
            : (element.description += description[i] + '...');
        }
      }
    });

    const processAux = this.myProcess;

    //Filtramos
    const process = processAux.filter((p) => p.open);
    process.forEach((element, index) =>
      Object.defineProperty(element, 'numCandidates', {
        value: process[index].results ? process[index].results.length : 0,
        writable: true,
        enumerable: true,
        configurable: true,
      })
    );
    this.openProcess = process;
    this.totalOpenProcess = this.openProcess.length;
    // console.log(this.openProcess);

    const process2 = processAux.filter((p) => !p.open);
    process2.forEach((element, index) =>
      Object.defineProperty(element, 'numCandidates', {
        value: process2[index].results ? process2[index].results.length : 0,
        writable: true,
        enumerable: true,
        configurable: true,
      })
    );
    this.closeProcess = process2;
    this.totalCloseProcess = this.closeProcess.length;

    this.orderProcess(process);
    this.orderProcess(process2);

    this.dataSource = new MatTableDataSource(process);

    this.dataSource2 = new MatTableDataSource(process2);

    // setTimeout(() => {
    //   this.calHeightTable();
    // }, 1000);
  }

  pageChange(event) {}

  orderProcess(process) {
    // Ordenamos de forma descendente por fecha de inicio
    process.sort(function (a, b) {
      if (a.startDate < b.startDate) {
        return -1;
      }
      if (a.startDate > b.startDate) {
        return 1;
      }
      return 0;
    });
  }

  routing(page) {
    this.router.navigate(['/admin/dashboard/' + page]);
  }

  resultLength(array) {
    if (array) {
      return array.length;
    } else {
      return 0;
    }
  }

  toggleMenu() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }

  deleteProcess(id) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: 'deleteProcess',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.processService.deleteProcess(id).then(() => {
          // this.getMyProcess();
          this.snackBar.open('¡El proceso ha sido eliminado con éxito!', null, {
            duration: 3000,
            panelClass: 'snackbar',
          });
        });
      }
    });
  }

  async findProcess() {
    const code = document.getElementById('codeinput')['value'];
    document.getElementById('codeinput')['value'] = null;

    const processResult = await this.processService
      .getProcessByCode(code)
      .toPromise();
    let process;
    if (processResult['msg']) {
      this.snackBar.open(processResult['msg'], null, {
        duration: 3000,
        panelClass: 'snackbar',
      });
      return;
    }
    process = (processResult as []).pop();

    if (process) {
      this.routing(`/process/details/${process['id']}`);
    }
  }
}
