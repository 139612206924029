import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'upload-cv',
  templateUrl: './upload-cv.component.html',
  styleUrls: ['./upload-cv.component.scss']
})
export class UploadCVComponent implements OnInit {
  @ViewChild('inputFile') inputFile: ElementRef<any>;

  private readonly whiteTypes = [
    'text/csv',
    'text/xlsx',
    'text/xls',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];
  private readonly maxSize = 800000;
  public cv: boolean;
  public fileData: any;

  @Input() base64: any;
  @Input() dId: string =
    'file-upload-' +
    new Date().getUTCMilliseconds() +
    Math.floor(Math.random() * 1000);
  @Input() editable = true;
  @Output() onConverted = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  convert(e) {
    const [file] = e?.target?.files || e;

    if (!file) return;

    if (this.whiteTypes.some((type) => type === file.type)) {
      // Creamos el objeto de la clase FileReader
      const reader: FileReader = new FileReader();
      // prepare callbacks
      reader.onload = () => {
        this.base64 = reader.result.toString();
      };
      this.onConverted.emit(file);
      this.fileData = file;
    }
  }

  remove() {
    this.base64 = null;
    this.fileData = null;
    this.inputFile.nativeElement.value = null;
    this.onConverted.emit(this.base64);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 0) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
