import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'cuestionario-candidatos';
  constructor(private readonly translate: TranslateService) {
    translate.addLangs(['en', 'es']);
    translate.use('es');
  }
}
