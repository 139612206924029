import { Injectable } from '@angular/core';
import { AuthService } from './auth.service'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  private _userLogged: any;
  private _user: any;

  constructor(public authService: AuthService,
    private apiService: ApiService,) { }

  async getUserLogged(): Promise<any> {
    if (this._userLogged) {
      return new Promise<any>((resolve) => resolve(this._userLogged));
    }
    try {
      return new Promise((resolve, reject) => {
        this.authService.getCurrentUSer().then((userLogged) => {
          this._userLogged = userLogged;
          resolve(userLogged);
        });
      });
    } catch (error) {

    }
  }

  async getUser(): Promise<any> {
    if (this._user) {
      return new Promise<any>((resolve) => resolve(this._user));
    }
    return new Promise((resolve) => {
      this.getUserLogged().then((userLogged) => {
        this.getUserData(userLogged.email).then((user) => {
          this._user = user;
          resolve(user);
        });
      });
    });
  }

  private async getUserData(email): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.getUser(email).subscribe((resp) => {
        this._user = resp;
        resolve(resp);
      });
    });
  }


  public setUserLogged(user: any) {
    this._userLogged = user;
  }

  public clearUserData() {
    this._user = undefined;
    this._userLogged = undefined;
  }
}