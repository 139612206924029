<div class="c-home">
  <app-menu [superUser]="superUser" [isLogged]="isLogged"></app-menu>

  <div class="c-home__main">
    <div class="c-home__content-buttons">
      <app-button
        [placeHolder]="'create_new_exam' | translate"
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        class="c-home__submit-btn"
        (click)="routing('exam/create')"
      ></app-button>
    </div>

    <div
      [ngClass]="
        totalExam >= 10
          ? 'c-home__content-process--big'
          : 'c-home__content-process'
      "
    >
      <candidates-table
        [dataSource]="allExam"
        [title]="titleTable"
        [columns]="columns"
        [currentUser]="currentUser"
      ></candidates-table>
    </div>
    
  </div>
  <app-footer [isDarkFooter]="false"></app-footer>
</div>
