import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  animations: []
})
export class NotFoundComponent implements OnInit {


  constructor() {}

  ngOnInit() {

  }


}
