import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TopicService {
  constructor(private afs: AngularFirestore) {}

  createTopic(topic, user) {
    const date = new Date();
    return this.afs
      .collection('topic')
      .doc(topic)
      .get()
      .pipe(
        map((snap) => {
          if (snap.exists) return { msg: '¡Lo Siento! El topic ya existe' };
          this.afs.collection('topic').doc(topic).set({ topic, date, user });
          return { msg: 'OK!' };
        })
      );
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //return this.http.post(environment.api_url('createtopic'), { topic, user }, { headers });
  }

  getTopic(topic) {
    return this.afs
      .collection('topic')
      .doc(topic)
      .get()
      .pipe(map((data) => data.data()));
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //return this.http.post(environment.api_url('gettopic'), { topic }, { headers });
  }

  getTopics() {
    return this.afs.collection('topic').valueChanges();
  }

  getAllTopic() {
    return this.afs
      .collection('topic')
      .get()
      .pipe(map((snap) => snap.docs.map((data) => data.data())));
    //return this.http.get(environment.api_url('getalltopic'));
  }

  deleteTopic(name) {
    return this.afs
      .collection('exam', (ref) => ref.where('topic', '==', name))
      .get()
      .pipe(
        take(1),
        map((snap) => {
          if (!snap.empty) return { error: 'Topic in use' };
          this.afs.collection('topic').doc(name).delete();
          return { msg: 'OK!' };
        })
      );
    //const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //return this.http.post(environment.api_url('deletetopic'), { name }, { headers });
  }
}
