import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextInputParameters } from '../text-input/text-input.component';
import { ValueChange } from '../value-change';

let REQUIRED_MESSAGE = 'text_input.validation.required_field';
let FORMAT_MESSAGE = 'text_input.validation.invalid_password';
let MATCHING_MESSAGE = 'the_passwords_must_be_equals';
@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
  @Input() validationPattern: RegExp = /^.{6,}$/;

  @Input() params: TextInputParameters = new TextInputParameters();
  @Input() value: string = '';
  @Input() match: boolean = false;
  @Input() matchingPassword: string;
  @Output() valueChange: EventEmitter<ValueChange> =
    new EventEmitter<ValueChange>();

  isClicked: boolean;

  ngOnInit(): void {}
  clickInput() {
    this.isClicked = !this.isClicked;
  }

  onValueChange() {
    this.checkPattern();
    this.checkIsRequired();
    this.checkMatching();
    this.valueChange.emit({
      value: this.value,
      invalid: this.params.errorMessage?.length > 0,
    });
  }

  checkMatching() {
    if (!this.match) return;
    const isValid: boolean = this.matchingPassword === this.value;
    if (isValid) {
      this.params.errorMessage = this.params?.errorMessage
        .replace(MATCHING_MESSAGE, '')
        .replace('|', '')
        .trim();
      return;
    }
    if (!isValid && this.params?.errorMessage.indexOf(MATCHING_MESSAGE) < 0) {
      this.params.errorMessage = MATCHING_MESSAGE;
    }
  }

  checkPattern() {
    const isValid: boolean = this.validationPattern.test(this.value);
    if (isValid) {
      this.params.errorMessage = this.params?.errorMessage
        .replace(FORMAT_MESSAGE, '')
        .replace('|', '')
        .trim();
      return;
    }
    if (!isValid && this.params?.errorMessage.indexOf(FORMAT_MESSAGE) < 0) {
      this.params.errorMessage = FORMAT_MESSAGE;
    }
  }

  private checkIsRequired() {
    if (this.params?.isRequired) {
      if (
        !this.value?.length &&
        this.params?.errorMessage.indexOf(REQUIRED_MESSAGE) < 0
      ) {
        this.params.errorMessage = REQUIRED_MESSAGE;
      } else if (
        this.value?.length &&
        this.params?.errorMessage.indexOf(REQUIRED_MESSAGE) >= 0
      ) {
        this.params.errorMessage = '';
      }
    }
  }
}
