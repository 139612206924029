import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { ExamService } from 'src/app/services/exam.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { UserSessionService } from 'src/app/services/user-session.service';

@Component({
  selector: 'app-details-exam',
  templateUrl: './details-exam.component.html',
  styleUrls: ['./details-exam.component.scss']
})
export class DetailsExamComponent implements OnInit {
  isLogged = false;
  idExam: string;
  formExam: FormGroup;
  dataExam;
  allTopics: Object;
  newTopic = '';
  examCreate = false;
  previosUrl: string;

  // Variables del formulario
  titleExam = '';
  topicSelected = '';
  questionsForm: [] = [];
  time = '';

  public msg;
  public noExists = false;
  public showSpinner = false;

  public superuser = false;
  public userEmail: string;
  public user;

  constructor(
    private fb: FormBuilder,
    private rutaActiva: ActivatedRoute,
    private examService: ExamService,
    public dialog: MatDialog,
    private router: Router,
    private location: Location,
    private userSession: UserSessionService
  ) {}

  async ngOnInit() {
    this.rutaActiva.params.subscribe((params: Params) => {
      this.idExam = params.idExam;
      this.getExam(this.idExam);
    });

    try {
      await this.userSession.getUserLogged().then((userLogged) => {
        if (userLogged) {
          this.isLogged = true;
          this.userEmail = userLogged.email;

          this.userSession.getUser().then((resp) => {
            this.user = resp;

            this.user.superuser
              ? (this.superuser = true)
              : (this.superuser = false);
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  getExam(idExam) {
    this.showSpinner = true;

    this.examService
      .getExam(idExam)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Error', error.error.msg);
          // this.msgTopic = error.error.msg;
          if (error.error instanceof ErrorEvent) {
            return throwError(
              'client/network error ocurred, please try again later'
            ); // client-side or network error
          } else {
            return throwError(error.error); // backend error
          }
        })
      )
      .subscribe((data) => {
        this.showSpinner = false;
        this.msg = data;
        // console.log('data eliminado', this.msg.msg)

        if (this.msg.msg !== 'No exist') {
          this.dataExam = data;
          this.titleExam = this.dataExam.titleExam;
          this.topicSelected = this.dataExam.topic;
          this.questionsForm = this.dataExam.questions;

          this.createFormExam();

          // Añadir preguntas
          this.dataExam.questions.forEach((val, index) => {
            const newQuestion = this.fb.group({
              question: ['', Validators.required],
              // time: ['', Validators.required],
              min: [
                '',
                Validators.compose([
                  Validators.required,
                  Validators.pattern('[0-9]{1,2}')
                ])
              ],
              seg: [
                '',
                Validators.compose([
                  Validators.required,
                  Validators.pattern('[0-9]{1,2}')
                ])
              ],
              correctAnswer: ['', Validators.required],
              options: this.fb.array([])
            });

            this.questions.push(newQuestion);

            // Añade a esta pregunta las opciones
            this.dataExam.questions[index].options.forEach((element) => {
              this.addOption(index);
            });
          });
        } else {
          this.noExists = true;
        }
      });
  }

  createFormExam() {
    this.formExam = this.fb.group({
      titleExam: ['', Validators.required],
      topicSelected: ['', Validators.required],
      questions: this.fb.array([])
    });
  }

  get questions(): FormArray {
    // console.log('questions', this.formExam.get('questions'))
    return this.formExam.get('questions') as FormArray;
  }

  addQuestion() {
    const newQuestion = this.fb.group({
      question: ['', Validators.required],
      // time: ['', Validators.required],
      min: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{1,2}')
        ])
      ],
      seg: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{1,2}')
        ])
      ],
      correctAnswer: ['', Validators.required],
      options: this.fb.array([])
    });

    this.questions.push(newQuestion);

    // console.log('posicion para agregar las opciones', this.questions.length - 1)
    this.addOption(this.questions.length - 1);
    this.addOption(this.questions.length - 1);
  }

  deleteQuestion(index) {
    this.questions.removeAt(index);
  }

  addOption(index) {
    const newOption = this.fb.group({
      texto: ['', Validators.required]
    });

    // this.options.push(newOption);
    const options = this.questions.controls[index].get('options') as FormArray;
    options.push(newOption);
  }

  deleteOption(question, option) {
    // this.options.removeAt(index);
    const options = this.questions.controls[question].get(
      'options'
    ) as FormArray;
    options.removeAt(option);
  }

  backClicked() {
    this.location.back();
  }

  toggleMenu() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }
}
