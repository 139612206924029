import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResultService } from 'src/app/services/result.service';
import { ExamService } from 'src/app/services/exam.service';
import { ProcessService } from 'src/app/services/process.service';
@Component({
  selector: 'app-exam-candidate',
  templateUrl: './exam-candidate.component.html',
  styleUrls: ['./exam-candidate.component.scss'],
})
export class ExamCandidateComponent implements OnInit {
  exam: any;
  loading = false;
  examForm: FormGroup;
  score = 0;
  n = 0;
  letters = [
    'a',
    'b',
    'c',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  answerSelected: number;
  minutes: number;
  seconds: number;
  currentTime: number;
  totalTime: number;
  barProgress = 100;
  interval;

  progressInterval;
  progressTimestamp;

  public msg;
  public codeUsed = true;
  public creating = false;
  timeUsed = 0;
  process;
  public idProcess;
  public begin = false;
  public name: string;
  public numQuestions;
  public minTotal;
  public msgSoport = true;
  public enableButton = false;

  constructor(
    //private examService: ExamService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private resultService: ResultService,
    //private apiService: ApiService,
    private processService: ProcessService,
    private examService: ExamService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.checkCode();
    this.idProcess = this.resultService.getProcessId();
    this.name = this.resultService.getName();

    setTimeout(() => {
      this.msgSoport = false;
    }, 3000);
  }

  checkCode() {
    const accessCode = this.resultService.getCode();
    const processId = this.resultService.getProcessId();

    this.processService.getProcess(processId).subscribe((data) => {
      this.process = data;
      const allCodes = this.process.usedCodes;
      let used = false;
      if (allCodes) {
        if (allCodes.indexOf(accessCode) >= 0) {
          used = true;
        }
      }
      if (!this.process.open) {
        used = true;
      }
      if (!used || this.examService.currentExamNumber >= 1) {
        this.codeUsed = false;
        this.examFormLoad();
        this.examLoad();
        // this.interval = setInterval(() => this.tick(),1000);
      } else {
        this.loading = false;
        this.codeUsed = true;
      }
    });
  }

  examLoad() {
    const id = this.route.snapshot.paramMap.get('id');
    this.examService.getExam(id).subscribe((resp) => {
      this.exam = resp;
      this.loading = false;
      this.codeUsed = false;
      this.numQuestions = this.exam.questions.length;
      this.totalMin(this.exam.questions);

      this.resetTimer();
      this.answerInit();
    });
  }

  totalMin(questions) {
    this.minTotal = 0;
    questions.forEach((val) => {
      this.minTotal += val.min + val.seg / 60;
    });

    this.minTotal = Math.ceil(this.minTotal);
  }

  answerInit() {
    const answerFormArray = <FormArray>this.examForm.controls.examAnswers;

    for (const i of this.exam.questions) {
      answerFormArray.push(this.fb.control(100)); //100 = not answered
    }
  }

  /***** FORM *****/
  examFormLoad() {
    this.examForm = this.fb.group({
      examAnswers: this.fb.array([], [Validators.required]),
    });
  }

  onAnswer(i: number, j: number, isChecked: boolean) {
    //i = question index, j = option index
    const answerFormArray = <FormArray>this.examForm.controls.examAnswers;
    //console.log(answerFormArray.value);
    this.answerSelected = j;
    answerFormArray.removeAt(i);
    answerFormArray.insert(i, this.fb.control(j));
    //console.log(answerFormArray.value);
    this.enableButton = true;
  }

  next() {
    this.answerSelected = -1;
    this.n = this.n + 1;
    this.resetTimer();
    this.enableButton = false;
  }

  sendResults() {
    this.creating = true;
    clearInterval(this.interval);
    clearInterval(this.progressInterval);
    const score = this.generateScore();
    const examId = this.route.snapshot.paramMap.get('id');
    const candidateAnswers = <number[]>this.examForm.controls.examAnswers.value;
    const accessCode = this.resultService.getCode();
    const name = this.resultService.getName();
    const processId = this.resultService.getProcessId();
    const minutes = Math.floor(this.timeUsed / 60);
    const seconds = this.timeUsed % 60;

    localStorage.removeItem('cpr');
    localStorage.removeItem('cpn');
    localStorage.removeItem('cpc');

    const exam = {
      answers: candidateAnswers,
      examId: examId,
      score: score,
      minutes: minutes,
      seconds: seconds,
    };

    this.resultService.allExamResult.push(exam);

    this.examService.currentExamNumber++;

    if (
      this.examService.currentExamNumber == this.examService.totalExamNumber
    ) {
      this.resultService.createResult(processId, accessCode, name);
    }

    this.creating = false;
    this.router.navigateByUrl('/candidate/thanks');
  }

  private generateScore() {
    let score = 0;
    const questions = this.exam.questions;
    const candidateAnswers = <FormArray>(
      this.examForm.controls.examAnswers.value
    );
    for (const i in this.exam.questions) {
      if (
        parseInt(questions[i].correctAnswer) === parseInt(candidateAnswers[i])
      ) {
        score = score + 1;
      }
    }
    return parseFloat(((score / questions.length) * 10).toFixed(2));
  }

  progressBar() {
    return Math.floor(((this.currentTime + 1) * 100) / this.totalTime);
  }

  private tick() {
    ++this.timeUsed;
    if (!(--this.currentTime === 0)) {
      if (--this.seconds < 0) {
        // this.barProgress = this.progressBar();
        this.seconds = 59;
        if (--this.minutes < 0) {
          this.minutes = 0;
          console.log('ZEROTIME');
          if (this.n === this.exam.questions.length - 1) {
            this.sendResults();
          } else {
            this.next();
          }
        }
      }
    } else {
      console.log('ALT-ZEROTIME');
      if (this.n === this.exam.questions.length - 1) {
        this.sendResults();
      } else {
        this.next();
      }
    }
  }

  private resetTimer() {
    this.minutes = this.exam.questions[this.n].min;
    this.seconds = this.exam.questions[this.n].seg;
    this.totalTime = this.minutes * 60 + this.seconds;
    this.currentTime = this.totalTime;
    this.progressTimestamp = window.performance.now();
    this.barProgress = 100;
  }

  comenzar() {
    this.begin = true;
    this.interval = setInterval(() => this.tick(), 1000);

    this.progressInterval = setInterval(() => {
      if (this.barProgress > 0)
        this.barProgress =
          101 -
          ((window.performance.now() - this.progressTimestamp) /
            (this.totalTime * 1000)) *
            100;
    }, 100);

    // var questionArea = document.getElementById("textareaTitle");
    // console.log(questionArea)
  }
}
