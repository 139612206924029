import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  appVersion = 2;
  /*
  1 - Proceso con un único examen
  2 - Stats y varios examenes por proceso
  */
  constructor(private afs: AngularFirestore) {}

  getProcessChanges() {
    return this.afs.collection('process').valueChanges({ idField: 'id' });
  }

  getProcessByCode(code: string) {
    return this.afs
      .collection('process', (ref) =>
        ref.where('codeAccess', 'array-contains', code)
      )
      .get()
      .pipe(
        map((snap) => {
          if (snap.empty)
            return { msg: 'Process with code: ' + code + ' does not exist' };
          return snap.docs.map((doc: any) => ({
            id: doc.id,
            ...(doc.data() as object)
          }));
        })
      );
  }

  getProcess(id) {
    return this.afs
      .collection('process')
      .doc(id)
      .get()
      .pipe(map((data) => data.data()));
  }

  createProcess(
    name,
    description,
    examSelected,
    startDate,
    endingDate,
    codeAccess,
    user,
    createdBy
  ) {
    const creationDate = new Date();
    return this.afs.collection('process').add({
      name,
      description,
      examSelected,
      startDate,
      endingDate,
      codeAccess,
      user,
      createdBy,
      appVersion: this.appVersion,
      open: true,
      creationDate: creationDate.getTime()
    });
  }

  async updateprocess(idProcess, newAccessCode) {
    try {
      const process = await this.afs
        .collection('process')
        .doc(idProcess)
        .get()
        .toPromise();
      const processData: any = process.data();

      if (newAccessCode) {
        const allCode = processData.codeAccess;

        newAccessCode.forEach((element: any) => {
          allCode.push(element);
        });

        const filterCode = [...new Set(allCode)];

        this.afs.firestore
          .collection('process')
          .doc(idProcess)
          .set({ codeAccess: filterCode }, { merge: true });
      } else {
        this.afs.firestore
          .collection('process')
          .doc(idProcess)
          .set({ open: false }, { merge: true });
      }
    } catch (err) {
      console.log('updateProcess ERROR!', err);
    }
  }

  deleteProcess(id) {
    return this.afs
      .collection('process')
      .doc(id)
      .delete()
      .then(() => {
        return { msg: 'OK!' };
      });
  }
}
