<div class="c-home">
  <app-menu [superUser]="true" [isLogged]="isLogged"></app-menu>
  <div class="c-home__main">
    <div class="c-home__first-container">
      <div class="c-home__process c-home__content-left">
        <div>
          <h1 class="c-home__title c-home__title-process">
            {{ "last_processes" | translate }}
          </h1>

          <div *ngIf="showSpinnerProcess" class="c-home__content-spinner">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>

          <table mat-table [dataSource]="dataSource" class="c-home__table" #tabla1>
            <ng-container matColumnDef="Nombre">
              <th class="c-home__th" mat-header-cell *matHeaderCellDef>
                {{ "name" | translate }}
              </th>
              <td class="c-home__column-process" mat-cell *matCellDef="let process">
                <p class="c-home__process-p">{{ process.name }}</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="Fecha de inicio">
              <th class="c-home__th" mat-header-cell *matHeaderCellDef>
                {{ "initial_date" | translate }}
              </th>
              <td mat-cell *matCellDef="let process">
                {{ process.startDate | date: "shortDate" }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="totalColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: totalColumns"></tr>
          </table>
        </div>

        <div class="c-home__content-link">
          <a [routerLink]="['/admin/dashboard/process']" class="c-home__link-read">
            {{ "see_more" | translate }}
          </a>
        </div>
      </div>

      <div class="c-home__content-right">
        <div class="c-home__candidates">
          <h1 class="c-home__title">{{ "candidates" | translate }}</h1>

          <div *ngIf="showSpinnerCandidate" class="c-home__content-spinner">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>

          <div class="c-home__content-candidate">
            <div class="c-home__all-candidate">
              <img class="c-home__icon-stats" src="/assets/img/inactiveCandidate.svg" alt="Activos" />
              <div class="c-home__content-result">
                <p *ngIf="candidates <= 9; else active" class="c-home__p-result">
                  0{{ candidates }}
                </p>
                <ng-template #active>
                  <p class="c-home__p-result">{{ candidates }}</p>
                </ng-template>
                <p class="c-home__status">Activos</p>
              </div>
            </div>
          </div>
        </div>

        <div class="c-home__skills">
          <h1 class="c-home__title">{{ "skills" | translate }}</h1>

          <div *ngIf="showSpinnerSkills" class="c-home__content-spinner">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>

          <div class="c-home__content-data">
            <img class="c-home__icon-stats" src="/assets/img/trending.svg" alt="Skills" />
            <p class="c-home__p-result">{{ numTopic }}</p>
          </div>
          <div class="c-home__content-link">
            <a [routerLink]="['/admin/dashboard/topic']" class="c-home__link-read">
              {{ "see_more" | translate }}
            </a>
          </div>
        </div>

        <div class="c-home__exam">
          <h1 class="c-home__title">{{ "exams" | translate }}</h1>

          <div *ngIf="showSpinnerExam" class="c-home__content-spinner">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>

          <div class="c-home__content-data">
            <img class="c-home__icon-stats" src="/assets/img/exam-stat.svg" alt="Exámenes" />
            <p class="c-home__p-result">{{ numExam }}</p>
          </div>
          <div class="c-home__content-link">
            <a [routerLink]="['/admin/dashboard/exam']" class="c-home__link-read">
              {{ "see_more" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="c-home__second-container">


      <div *ngIf="showSpinnerProcess" class="c-home__content-spinner">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>

      <div class="c-home__content-chart">
        <div class="c-home__chart">
          <div style="display: block">
            <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType"
              [colors]="donutColors">
            </canvas>
          </div>
        </div>
        <div>
          <h1 class="c-home__title">{{ "processes" | translate }}</h1>
          <div class="c-home__content-label">
            <div class="c-home__label">
              <div class="c-home__circle c-home__circle--blue"></div>
              <div class="">
                <p *ngIf="numProcess <= 9; else total" class="c-home__p-process">
                  0{{ numProcess }}
                </p>
                <ng-template #total>
                  <p class="c-home__p-process">{{ numProcess }}</p>
                </ng-template>
                <p class="c-home__status">{{ "totals" | translate }}</p>
              </div>
            </div>

            <div class="c-home__label">
              <div class="c-home__circle c-home__circle--green"></div>
              <div class="">
                <p *ngIf="openProcess <= 9; else open" class="c-home__p-process">
                  0{{ openProcess }}
                </p>
                <ng-template #open>
                  <p class="c-home__p-process">{{ openProcess }}</p>
                </ng-template>
                <p class="c-home__status">{{ "opens" | translate }}</p>
              </div>
            </div>

            <div class="c-home__label">
              <div class="c-home__circle c-home__circle--orange"></div>
              <div class="">
                <p *ngIf="closeProcess <= 9; else open" class="c-home__p-process">
                  0{{ closeProcess }}
                </p>
                <ng-template #open>
                  <p class="c-home__p-process">
                    {{ closeProcess }}
                  </p>
                </ng-template>
                <p class="c-home__status">{{ "closed" | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer [isDarkFooter]="false"></app-footer>
</div>