<div class="c-exam">
  <app-menu [superUser]="superuser" [isLogged]="isLogged"></app-menu>

  <div *ngIf="showSpinner" class="c-exam__content-spinner">
    <mat-spinner [diameter]="30"></mat-spinner>
    <p class="c-exam__p-spinner">{{ "loading_exam" | translate }}</p>
  </div>

  <div *ngIf="dataExam && !examCreate" class="c-exam__container">
    <div class="c-exam__back">
      <img
        class="c-exam__arrow-back"
        src="/assets/icons/left-arrow.svg"
        alt="Volver"
      />
      <a class="c-exam__link" (click)="backClicked()">
        {{ "back" | translate }}
      </a>
    </div>

    <form [formGroup]="formExam" class="c-exam__form">
      <!-- Title Exam -->
      <input
        formControlName="titleExam"
        readonly
        [(ngModel)]="titleExam"
        type="text"
        class="c-exam__input"
      />

      <!-- Select Topic-->
      <input
        formControlName="topicSelected"
        readonly
        [(ngModel)]="topicSelected"
        type="text"
        class="c-exam__input"
      />

      <!-- Array de questions -->
      <div formArrayName="questions">
        <div
          [formGroupName]="indexQuestion"
          *ngFor="let item of questions.controls; let indexQuestion = index"
        >
          <div class="c-exam__content-right">
            <div class="c-exam__content-right">
              <div
                class="c-exam__content-time"
                *ngIf="questionsForm[indexQuestion]; else otherTime"
              >
                <input
                  type="number"
                  readonly
                  class="c-exam__time"
                  [(ngModel)]="questionsForm[indexQuestion].min"
                  formControlName="min"
                  placeholder="mm"
                />
                <span class="c-exam__icon-point">:</span>
                <input
                  type="number"
                  readonly
                  class="c-exam__time"
                  [(ngModel)]="questionsForm[indexQuestion].seg"
                  formControlName="seg"
                  placeholder="ss"
                />
                <img
                  class="c-exam__icon-time"
                  src="/assets/img/time.svg"
                  alt="tiempo"
                />
              </div>
              <ng-template #otherTime>
                <div class="c-exam__content-time">
                  <input
                    type="number"
                    readonly
                    class="c-exam__time"
                    formControlName="min"
                    placeholder="mm"
                  />
                  <span>:</span>
                  <input
                    type="number"
                    readonly
                    class="c-exam__time"
                    formControlName="seg"
                    placeholder="ss"
                  />
                </div>
              </ng-template>
            </div>
          </div>

          <div class="c-exam__separator-question"></div>

          <div class="c-exam__content-question">
            <div *ngIf="indexQuestion < questionsForm.length">
              <textarea
                rows="3"
                readonly
                class="c-exam__text-area"
                formControlName="question"
                [(ngModel)]="questionsForm[indexQuestion].question"
                placeholder="{{ indexQuestion + 1 }}. Pregunta"
              ></textarea>
              <!-- <input readonly type="text" class="c-exam__input" formControlName="question" [(ngModel)]="questionsForm[indexQuestion].question" placeholder="{{indexQuestion + 1}}. Pregunta"> -->
            </div>
            <!-- <ng-template #moreQuestions><input type="text" class="c-exam__input" formControlName="question" placeholder="{{indexQuestion + 1}}. Pregunta"></ng-template> -->
          </div>

          <!-- Options -->
          <div class="c-exam__content-options">
            <div class="c-exam__select-correct">
              <div
                *ngFor="
                  let options of item.controls.options.controls;
                  let index = index
                "
              >
                <div *ngIf="questionsForm[indexQuestion]">
                  <input
                    disabled
                    type="radio"
                    [(ngModel)]="questionsForm[indexQuestion].correctAnswer"
                    class="c-exam__radio"
                    formControlName="correctAnswer"
                    value="{{ index }}"
                  />
                </div>
                <!-- <ng-template #ohterRadio><input type="radio" class="c-exam__radio" formControlName="correctAnswer" value="{{index}}"></ng-template> -->
              </div>
            </div>

            <div class="c-exam__content-answer" formArrayName="options">
              <div
                class="c-exam__answer"
                [formGroupName]="indexOption"
                *ngFor="
                  let options of item.controls.options.controls;
                  let indexOption = index
                "
              >
                <div
                  *ngIf="
                    questionsForm[indexQuestion] &&
                    indexOption < questionsForm[indexQuestion].options.length
                  "
                >
                  <textarea
                    rows="3"
                    readonly
                    class="c-exam__text-area c-exam__input--answer"
                    formControlName="texto"
                    [(ngModel)]="
                      questionsForm[indexQuestion].options[indexOption].texto
                    "
                    type="text"
                    placeholder="Respuesta {{ indexOption + 1 }}"
                  ></textarea>
                  <!-- <input readonly class="c-exam__input c-exam__input--answer" formControlName="texto" [(ngModel)]="questionsForm[indexQuestion].options[indexOption].texto"  type="text" placeholder="Respuesta {{indexOption + 1}}"> -->
                </div>
                <!-- <ng-template #moreOptions><input class="c-exam__input c-exam__input--answer" formControlName="texto"  type="text" placeholder="Respuesta {{indexOption + 1}}"></ng-template> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="!dataExam && noExists" class="c-exam__content-result">
    <p class="c-exam__result-msg">
      {{ "sorry_exam_dont_exist" | translate }}
    </p>
    <a
      class="c-exam__button c-exam__result-button"
      [routerLink]="['/admin/dashboard/exam']"
    >
      {{ "see_exams" | translate }}
    </a>
  </div>

  <app-footer [isDarkFooter]="false"></app-footer>
</div>
