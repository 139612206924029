<div class="c-home">
  <div class="c-home__content-background">
    <img class="c-home__logo" src="assets/img/UST-logo_dark-blue.svg" alt="" />
  </div>

  <div class="c-home__container-welcome">
    <h1 class="c-home__title">
      {{ "welcome" | translate }}
      <br>
      {{ "to_ust" | translate }}
    </h1>
  </div>
  <div class="c-home__content-soport">
    <candidate-language-selector></candidate-language-selector>
  </div>
  <div class="c-home__content-form">
    <div class="c-home__container">
      <div class="c-home__container-subtitle">
        <h3 class="c-home__subtitle">
          {{ "welcome_subtitle_admin" | translate }}
        </h3>
      </div>

      <form *ngIf="!loading" class="c-home__form">
        <div class="block-login">
          <app-email-input [value]="emailInputValue" [params]="emailInputParams"
            (valueChange)="onEmailValueChange($event)" class="email-input"></app-email-input>
        </div>
        <div class="block-login">
          <app-password-input [value]="passwordInputValue" [params]="passwordInputParams"
          (valueChange)="onPasswordValueChange($event)" class="password-input"></app-password-input>
        </div>
        <p class="c-login__error" *ngIf="msgLogin">{{ msgLogin | translate  }}</p>

        <div class="submit-button-wrapper">
          <app-button [placeHolder]="'get_access' | translate" [icon]="'../../../../assets/icons/right-arrow.svg'"
            class="c-home__submit-btn" (click)="loginAdmin()" [disabled]="!emailIsValid || !passwordIsValid">
          </app-button>
        </div>

        <p class="c-home__info">
          {{ "Do_not_own_an_account?" | translate }}
          <a class="c-home__link" [routerLink]="['/admin/register']">
            {{ "sign_in" | translate }}
          </a>
        </p>
      </form>

      <div *ngIf="loading" class="c-home__content-spinner">
        <mat-spinner [diameter]="30"></mat-spinner>
        <p class="c-home__p-spinner">
          {{ "loging_in" | translate }}
        </p>
      </div>
    </div>

  </div>
</div>
<app-footer class="c-home__footer"></app-footer>