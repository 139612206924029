<input
  #inputFile
  hidden
  [id]="dId"
  (change)="convert($event)"
  type="file"
  accept=".xlsx, .xls, .csv"
/>

<div
  dragAndDrop
  (onFileDropped)="convert($event)"
  [ngStyle]="{ padding: '0 1em' }"
  class="d-inline-block"
>
  <div class="container">
    <div class="textContainer">
      <span class="fas fa-upload"></span>
      <span class="align-middle title">
        {{ "drag_and_drop_the_CV_here" | translate }}
      </span>
      <span class="align-middle title">
        {{ "or search in your device with" | translate }}
      </span>
      <div class="d-flex flex-row justify-content-center upload-button">
        <label
          [for]="dId"
          class="btn btn-secondary--active mt-3 d-flex flex-row justify-content-center align-items-center"
        >
          <span class="text-upload"> {{ "upload_CV" | translate }} </span>
        </label>
      </div>
    </div>
  </div>
</div>

<div class="info mt-3" *ngIf="fileData">
  <div class="cvInformation">
    <h6 class="nameCv">
      {{ fileData?.name }}
    </h6>
    <a class="icon" (click)="remove()"
      ><span class="fas fa-times-circle actionDelete"></span
    ></a>
  </div>
</div>
