import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { TextInputParameters } from 'src/app/shared/components/inputs/text-input/text-input.component';
import { ValueChange } from 'src/app/shared/components/inputs/value-change';
import { UserSessionService } from 'src/app/services/user-session.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formLogin: FormGroup;
  msgLogin: string;
  public loading = false;

  emailInputValue: string = '';
  emailInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'text_input.placeholder.email',
    isRequired: true,
    errorMessage: '',
  });
  emailIsValid: boolean = false;

  passwordInputValue: string = '';
  passwordInputParams: TextInputParameters = new TextInputParameters({
    textLabel: 'text_input.placeholder.password',
    isRequired: true,
    errorMessage: '',
  });
  passwordIsValid: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userSession: UserSessionService
  ) {}

  ngOnInit(): void {
  }

  onEmailValueChange(event: ValueChange) {
    this.emailInputValue = event.value;
    this.emailIsValid = !event.invalid;
  }

  onPasswordValueChange(event: ValueChange) {
    this.cleanMsg();
    this.passwordInputValue = event.value;
    this.passwordIsValid = !event.invalid;
  }

  async loginAdmin() {
    if (this.passwordIsValid && this.emailIsValid) {
      this.loading = true;
      const email = this.emailInputValue;
      const password = this.passwordInputValue;

      try {
        await this.authService.login(email, password).then(async (result) => {

          if (result.code) {
            switch (result.code) {
              case 'auth/user-not-found':
                this.msgLogin =
                  'error_happened_try_again';
                this.loading = false;
                break;
              case 'auth/wrong-password':
                this.msgLogin = 'invalid_password';
                this.loading = false;
                break;
              default:
                this.msgLogin = 'error_happened_try_again';
                this.loading = false;
                break;
            }
          } else {
            this.userSession.setUserLogged(result.user);
            this.loading = false;
            this.router.navigate(['admin/dashboard']);
          }
        });
      } catch (error) {
        console.error('error en login', error);
      }
    }
  }

  cleanMsg() {
    this.msgLogin = '';
  }
}
