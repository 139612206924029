<div
  id="close-process"
  [ngClass]="
    dataSource?.length >= 10
      ? 'c-home__content-process--big'
      : 'c-home__content-process'
  "
>
  <h3 class="c-home__table-title">
    {{ title | translate }}
  </h3>
  <div *ngIf="showSpinner" class="c-home__content-spinner">
    <mat-spinner [diameter]="30"></mat-spinner>
    <p class="c-home__p-spinner">
      {{ "loading_processes" | translate }}
    </p>
  </div>
  <div class="c-home__content-table">
    <table
      mat-table
      [dataSource]="tableDataSource"
      class="c-home__table"
      #tabla2
    >
      <div *ngFor="let column of columns">
        <ng-container matColumnDef="{{ column.name }}">
          <th class="c-home__th" mat-header-cell *matHeaderCellDef>
            {{ column.name | translate }}
            <span *ngIf="column?.isOrdenable">
              <img
                *ngIf="column.status == 'asc'"
                (click)="order(column?.column)"
                class="c-home__img-order"
                src="/assets/img/asc.svg"
                alt="ordenar"
              />
              <img
                *ngIf="column.status == 'desc'"
                (click)="order(column.column)"
                class="c-home__img-order"
                src="/assets/img/desc.svg"
                alt="ordenar"
              />
              <img
                *ngIf="column.status == 'static'"
                (click)="order(column.column)"
                class="c-home__img-order"
                src="/assets/img/static.svg"
                alt="ordenar"
              />
            </span>
          </th>
          <div *ngIf="column.isSeveral; else not_several">
            <td
              class="c-home__column-process"
              mat-cell
              *matCellDef="let process"
            >
              <a
                class="c-home__process-title"
                [routerLink]="[column.navigation, process.id]"
              >
                <p class="c-home__name-p">
                  {{ process[column?.column] }}
                </p>
              </a>
              <p *ngIf="title === 'processes'" class="c-home__process-p">
                {{ process.description }}
              </p>
            </td>
          </div>
          <td
            class="c-home__column-data"
            #not_several
            mat-cell
            *matCellDef="let process"
          >
            <div [ngSwitch]="column.column">
              <div *ngSwitchCase="'totalTime'">
                {{
                  "timing"
                    | translate
                      : { minutes: process.minutes, seconds: process.seconds }
                }}
              </div>
              <div *ngSwitchCase="'score'">
                <div *ngIf="process.exam.length === 1; else oneExam">
                  <a
                    class="c-home__process-title"
                    matTooltip="Ver exámen"
                    [routerLink]="[
                      column.navigation,
                      process.id,
                      process.exam[0].examId,
                      process.realPosition
                    ]"
                  >
                    <p class="c-home__name-p">
                      {{ "10_pts" | translate: { score: process.score } }}
                    </p></a
                  >
                </div>
                <ng-template #oneExam
                  ><p class="c-home__name-p">
                    {{ "10_pts" | translate: { score: process.score } }}
                  </p></ng-template
                >
              </div>
              <div *ngSwitchCase="'exam'">
                <a
                  class="c-home__process-title"
                  matTooltip="Ver exámen"
                  [routerLink]="[
                    column.navigation,
                    process.id,
                    process.exam[column.position].examId,
                    column.position
                  ]"
                >
                  <p class="c-home__name-p">
                    {{
                      "10_pts"
                        | translate
                          : { score: process.exam[column.position].score }
                    }}
                  </p>
                </a>
              </div>
              <div *ngSwitchCase="'startDate'">
                {{ process.startDate | date: "shortDate" }}
              </div>
              <div *ngSwitchCase="'status'">
                <select
                  [(ngModel)]="process.status"
                  (change)="valueOptionStatus($event.target.value, process)"
                  [ngClass]="
                    ['En proceso', 'Aceptado'].includes(process.status)
                      ? 'c-details__select c-details__select--process'
                      : process.status == 'Descartado'
                      ? 'c-details__select c-details__select--discarded'
                      : 'c-details__select c-details__select--pending'
                  "
                >
                  <option value="Aceptado">
                    {{ "accepted" | translate }}
                  </option>
                  <option value="En proceso">
                    {{ "in_progress" | translate }}
                  </option>
                  <option value="Descartado">
                    {{ "discarded" | translate }}
                  </option>
                  <option value="Pendiente">
                    {{ "pending" | translate }}
                  </option>
                </select>
              </div>

              <div *ngSwitchDefault>{{ process[column?.column] }}</div>
            </div>
          </td>
        </ng-container>
      </div>
      <ng-container matColumnDef="Menu">
        <th class="c-home__th" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let process">
          <button
            mat-button
            class="c-home__button-menu"
            [matMenuTriggerFor]="menu"
          >
            <img
              class="c-home__icon-delete"
              src="/assets/img/Vertical-points.svg"
              alt=""
            />
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              *ngIf="title === 'all_exams'"
              (click)="clone(process)"
            >
              {{ "clone" | translate }}
            </button>
            <button
              mat-menu-item
              *ngIf="
                (title === 'all_exams' && process.user === currentUser.email) ||
                superUser
              "
              (click)="edit(process)"
            >
              {{ "edit" | translate }}
            </button>
            <mat-divider
              *ngIf="
                title === 'all_exams' && process.user === currentUser.email
              "
            ></mat-divider>
            <button
              *ngIf="
                title === 'all_skills' ||
                title === 'processes' ||
                (title === 'all_exams' && process.user === currentUser.email) ||
                superUser
              "
              mat-menu-item
              (click)="deleteProcess(process)"
            >
              {{ "delete" | translate }}
            </button>
            <div *ngIf="title === 'ranking'">
              <button
                mat-menu-item
                *ngIf="process.link != ''"
                (click)="openBizneo(process)"
              >
                {{ "open_bizneo" | translate }}
              </button>
              <button
                mat-menu-item
                *ngIf="process.link != ''"
                (click)="editBizneo(process)"
              >
                {{ "edit" | translate }}
              </button>
              <button
                mat-menu-item
                *ngIf="process.link == ''"
                (click)="editBizneo(process)"
              >
                {{ "aggregate" | translate }}
              </button>
            </div>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="c-home__paginator">
      <mat-paginator
        #MatPaginator
        [pageSizeOptions]="[10]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
