<div class="c-home">
  <div class="c-home__content-background">
    <img class="c-home__logo" src="assets/img/UST-logo_dark-blue.svg" alt="" />
  </div>

  <div class="c-home__container-welcome">
    <h1 class="c-home__title">
      {{ "welcome" | translate }}
      <br />
      {{ "to_ust" | translate }}
    </h1>
  </div>

  <div class="c-home__content-soport">
    <candidate-language-selector
      class="c-home__lang-selector"
    ></candidate-language-selector>

    <a [routerLink]="['/candidate/support']"
      ><img
        class="c-home__icon-soport"
        src="/assets/img/soport.svg"
        alt="Soporte"
    /></a>
  </div>

  <div class="c-home__content-form">
    <div class="c-home__container">
      <div class="c-home__container-subtitle">
        <h3 class="c-home__subtitle">
          {{ "welcome_subtitle" | translate }}
        </h3>
      </div>

      <form
        *ngIf="!sending"
        [formGroup]="form"
        (ngSubmit)="checkCode()"
        class="c-home__form"
      >
        <app-text-input
          [value]="nameInputValue"
          [params]="nameInputParams"
          (valueChange)="onNameValueChange($event)"
          class="name-input"
        ></app-text-input>

        <app-text-input
          [value]="codeInputValue"
          [params]="codeInputParams"
          (valueChange)="onCodeValueChange($event)"
          class="code-input"
        ></app-text-input>

        <div class="submit-button-wrapper">
          <app-button
            [placeHolder]="'get_access'"
            [icon]="'../../../../assets/icons/right-arrow.svg'"
            class="c-home__submit-btn"
            (click)="checkCode()"
            [disabled]="!(nameIsValid && codeIsValid)"
          ></app-button>
        </div>
      </form>

      <div *ngIf="sending" class="c-home__content-spinner">
        <mat-spinner [diameter]="30"></mat-spinner>
        <p class="c-home__p-spinner">
          {{ "checking_code" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
<app-footer class="c-home__footer"></app-footer>
