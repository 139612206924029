<div class="c-home">
  <app-menu [superUser]="superuser" [isLogged]="isLogged"></app-menu>


  <div class="c-home__main">
    <div class="c-home__content-buttons">
      <app-button
        [placeHolder]="'create_new_skill' | translate"
        [icon]="'../../../../assets/icons/right-arrow.svg'"
        class="c-home__submit-btn"
        (click)="openDialog()"
      ></app-button>
    </div>
    <candidates-table
      [dataSource]="allTopic"
      [title]="titleSkill"
      [columns]="columns"
    ></candidates-table>
  </div>

  <app-footer [isDarkFooter]="false"></app-footer>
</div>
