import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { RouterService } from 'src/app/services/router.service';
import { Location } from '@angular/common';
import { ExamService } from 'src/app/services/exam.service';
import { ProcessService } from 'src/app/services/process.service';
import { UserSessionService } from 'src/app/services/user-session.service';

@Component({
  selector: 'app-create-process',
  templateUrl: './create-process.component.html',
  styleUrls: ['./create-process.component.scss']
})
export class CreateProcessComponent implements OnInit {
  formProcess: FormGroup;
  isLogged = false;
  description = '';
  allExams;
  lastExams: any[] = [];
  examSelected = '';
  startDate: Date;
  endingDate: Date;
  msgProcess = '';
  success: Boolean = false;
  userEmail: string;
  myProcess;
  getExams: any[] = [];
  today: Date = new Date();
  msgDate: string;
  generatedCode: string[] = [];
  numCode: number;
  processCreate = false;
  newIdProcess;
  nameProcess = '';
  descriptionProcess = '';
  startDateProcess;
  endingDateProcess;
  codeProces;
  public showSpinner = false;
  public creating = false;
  public user;
  public createdBy;
  public superuser = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private routerService: RouterService,
    private location: Location,
    private processService: ProcessService,
    private examService: ExamService,
    private userSession: UserSessionService
  ) {
    this.routerService.getPreviousUrl();
  }

  async ngOnInit() {
    this.createFormProcess();

    const arreglo = JSON.parse(localStorage.getItem('formulario'));
    if (arreglo != null) {
      this.nameProcess = arreglo[0].name;
      this.descriptionProcess = arreglo[1].description;
      this.startDateProcess = arreglo[2].startDate;
      // this.endingDateProcess = arreglo[3].endingDate;
      this.codeProces = arreglo[3].totalCode;
    }

    try {
      await this.userSession.getUserLogged().then((userLogged) => {
        if (userLogged) {
          this.isLogged = true;
          this.userEmail = userLogged.email;

          this.userSession.getUser().then((resp) => {
            this.user = resp;

            this.user.superuser
              ? (this.superuser = true)
              : (this.superuser = false);

            this.createdBy = `${this.user.name} ${this.user.surName}`;
          });
        }
      });
    } catch (error) {
      console.error(error);
    }

    this.getAllExams();
  }

  createFormProcess() {
    this.formProcess = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      startDate: ['', Validators.required],
      // endingDate: ['', Validators.required],
      totalCode: ['', Validators.required],
      examSelected: this.fb.array([])
    });

    // Para añadir un examen una vez creado el formulario principal
    this.addExam();
  }

  get arrExam(): FormArray {
    // console.log('arrExam', this.formExam.get('examSelected'))
    return this.formProcess.get('examSelected') as FormArray;
  }

  addExam() {
    const newExam = this.fb.group({
      idExam: ['', Validators.required]
    });

    this.arrExam.push(newExam);
  }

  deleteExam(index) {
    this.arrExam.removeAt(index);
  }

  checkDate(control: FormGroup): { [key: string]: boolean } | null {
    const startDate = control.get('startDate').value;
    const endDate = control.get('endingDate').value;

    return startDate < endDate ? null : { wrongDate: true };
  }

  getAllExams() {
    this.showSpinner = true;
    this.examService.getExams().subscribe((data) => {
      this.showSpinner = false;
      this.allExams = data;
      this.allExams = this.allExams.filter(
        (val) => val.lastVersion == true && val.hide == false
      );
      // Ordenamos los exámenes por titulo de forma ascendente
      this.allExams.sort(function (a, b) {
        if (a.titleExam < b.titleExam) {
          return -1;
        }
        if (a.titleExam > b.titleExam) {
          return 1;
        }
        return 0;
      });
    });
  }

  createCode() {
    const char = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (let i = 0; i < this.formProcess.get('totalCode').value; i++) {
      const codeAccess =
        char.charAt(Math.floor(Math.random() * char.length)) +
        char.charAt(Math.floor(Math.random() * char.length)) +
        char.charAt(Math.floor(Math.random() * char.length)) +
        char.charAt(Math.floor(Math.random() * char.length)) +
        char.charAt(Math.floor(Math.random() * char.length));

      this.generatedCode.push(codeAccess);
    }
  }

  createProcess() {
    this.creating = true;
    const name = this.formProcess.get('name').value;
    const description = this.formProcess.get('description').value;
    const examSelected = this.formProcess.get('examSelected').value;
    const startDate = this.formProcess.get('startDate').value;
    // const endingDate = this.formProcess.get('endingDate').value;
    const endingDate = this.formProcess.get('startDate').value;
    this.createCode();

    // console.log(name, description, examSelected, startDate, endingDate, this.generatedCode, this.userEmail, this.createdBy);

    this.processService
      .createProcess(
        name,
        description,
        examSelected,
        startDate,
        endingDate,
        this.generatedCode,
        this.userEmail,
        this.createdBy
      )
      .then((data) => {
        //console.log('subscribe',data.id );
        this.creating = false;
        this.newIdProcess = data.id;
        this.formProcess.reset();
        this.processCreate = true;
        localStorage.clear();
      });
  }

  valueOption(value) {
    if (value == 'crearExam') {
      localStorage.setItem(
        'formulario',
        JSON.stringify([
          { name: this.formProcess.get('name').value },
          { description: this.formProcess.get('description').value },
          { startDate: this.formProcess.get('startDate').value },
          // { endingDate: this.formProcess.get('endingDate').value},
          { totalCode: this.formProcess.get('totalCode').value }
        ])
      );
      this.router.navigate(['/admin/dashboard/exam/create']);
    }
  }

  backClicked() {
    this.location.back();
  }

  toggleMenu() {
    const x = document.getElementById('myTopnav');
    if (x.className === 'topnav') {
      x.className += ' responsive';
    } else {
      x.className = 'topnav';
    }
  }
}
